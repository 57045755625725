// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../../utils/api'

// assets
import no_image from '../../../assets/images/no_image.png'

// i18n
import t from '../../../stories/i18n'

// libraries
import moment from 'moment'

// stores
import internalStore from '../../../stories/internal'

// styles
import './ProductItem.scss'

// utils
import { formatMoney } from '../../../utils/fuctions'
import ReactGA from 'react-ga4'

class ProductItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parameters: [],
            loading: false
        }
    }

    showQuickView = () => {
        const { item } = this.props

        internalStore.setGlobalLoader(true)
        internalStore.routerData.history.push(`/${item.alias}`)

        window.location.reload()
    }

    goToProduct = (e, item) => {
        const { alias } = item
        e.preventDefault()
        e.stopPropagation()

        ReactGA.event('select_item', {
            items: [
                {
                    item_id: item.code ? item.code : item.name,
                    item_name: item.name,
                    affiliation: 'Bekoshop.sk',
                    price: item.total ? item.total : (item.use_discount_price ? item.discounted_price_with_vat : item.retail_price_with_vat),
                }
            ]
        })

        internalStore.routerData.history.push(`/${alias}`)
    }

    handleAddToShoppingCart = async item => {
        this.setState({ loading: true })

        let params = {}

        if (this.props.fixedSet) {
            params.static_set = item.id
            params.quantity = 1
        } else {
            params.product = item.id
            params.quantity = 1
        }

        let update_shop_cart = await api({
            url: 'shopping-cart',
            method: 'PUT',
            scns_token: localStorage.getItem('scns_token'),
            params: params
        })

        if (update_shop_cart.success === true) {
            ReactGA.event('add_to_cart', {
                currency: 'EUR',
                price: item.total ? item.total : (item.use_discount_price ? item.discounted_price_with_vat : item.retail_price_with_vat),
                items: [
                    {
                        item_id: item.code ? item.code : item.name,
                        item_name: item.name,
                        affiliation: 'Bekoshop.sk',
                        price: item.total ? item.total : (item.use_discount_price ? item.discounted_price_with_vat : item.retail_price_with_vat),
                    }
                ]
            })
            internalStore.setShoppingCartData(update_shop_cart.data.data)

            this.setState({ loading: false })

            internalStore.setSnackbarData(update_shop_cart.data.message, true, 'openBasket')

            internalStore.loginWrapperRef.rerender()
        }
    }

    setWatchDogData = () => {
        internalStore.setWatchDogData({
            isFixedSet: this.props.fixedSet,
            isProduct: !this.props.fixedSet,
            item: this.props.item,
        })
    }

    render() {
        const { fixedSet, item, listOfProducts, parentClasses } = this.props
        let user = JSON.parse(localStorage.getItem('user'))

        let url = ''
        if (
            internalStore &&
            internalStore.footerData &&
            internalStore.footerData.shop &&
            internalStore.footerData.shop.url
        ) {
            url = internalStore.footerData.shop.url + this.props.item.alias
        }

        const PRODUCT_TAGS = this.props.tags || []

        if (
            item.use_discount_price &&
            item.retail_price_with_vat !== item.discounted_price_with_vat
        ) {
            PRODUCT_TAGS.unshift({
                id: `discount-p${item.id}`,
                name: `-${(
                    100 -
                    (Number(item.discounted_price_with_vat) / Number(item.retail_price_with_vat)) *
                        100
                ).toFixed(0)}%`,
                color: '#e71d36'
            })
        }

        return (
            <div
                className={`${parentClasses} item${listOfProducts ? '-1' : ''}`}
                itemScope
                itemType='http://schema.org/Product'
            >
                <div className={'displayHidden'}>
                    <span itemProp='gtin13'>{item.code || item.id}</span>
                    <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                        <meta itemProp="name" content="Beko"/>
                    </div>
                    <span itemProp='sku'>{item.code || item.id}</span>
                    {item.reviews_count > 0 ? (
                      <div
                        itemProp='aggregateRating'
                        itemScope
                        itemType='https://schema.org/AggregateRating'
                     >
                        <span itemProp='ratingValue'>{item.average_score}</span>
                        <span itemProp='reviewCount'>{item.reviews_count}</span>
                        <span itemProp="bestRating" content="5"/>
                        <span itemProp="worstRating" content="1"/>
                    </div>
                      ) : null}
                </div>

                <article className={'productItem '}>
                    {this.state.loading ? null : (
                        <div className='info'>
                            {user !== null ? (
                                <span className={`${item.favorite ? 'add-favorite added' : ''}`}>
                                    {item.favorite ? (
                                        <a
                                            data-title={
                                                t.i18n.COMPONENTS.QUICK_VIEW.ADD_TO_FAVORITE
                                            }
                                            data-title-added={
                                                t.i18n.COMPONENTS.QUICK_VIEW.ADDED_TO_FAVORITE
                                            }
                                            onClick={() => this.props.deleteWatchDog(this.props)}
                                        >
                                            <i className='icon icon-heart' />
                                        </a>
                                    ) : (
                                        <a
                                            className='open-popup'
                                            data-toggle='modal'
                                            data-target='#watchDog'
                                            // onClick={this.props.closeDialog}
                                            data-title={
                                                t.i18n.COMPONENTS.QUICK_VIEW.ADD_TO_FAVORITE
                                            }
                                            data-title-added={
                                                t.i18n.COMPONENTS.QUICK_VIEW.ADDED_TO_FAVORITE
                                            }
                                            onClick={() => this.setWatchDogData()}
                                        >
                                            <i className='icon icon-heart' />
                                        </a>
                                    )}
                                </span>
                            ) : null}
                            <span />
                        </div>
                    )}
                    {this.state.loading ? (
                        <div className='btn btn-add'>
                            <div className='lds-dual-ring addToShoppingCart' />
                        </div>
                    ) : (
                        <div
                            className='btn btn-add'
                            onClick={() => {
                                this.handleAddToShoppingCart(item)
                            }}
                        >
                            <i className='icon icon-cart' />
                        </div>
                    )}
                    <div className='figure-grid'>
                        <a href={`/${item.alias}`} onClick={e => this.goToProduct(e, item)}>
                            <div className='image'>
                                {item.watchDog ? null : (
                                  <img
                                        src={fixedSet === true ? (item.image || no_image) : (item.asset_image_thumb || no_image)}
                                        alt={fixedSet === true ? (item.image ? item.name : 'no_image') : (item.asset_image_thumb ? item.name : 'no_image')}
                                        width='360'
                                        itemProp='image'
                                    />
                                )}
                                {item.watchDog && item.static_set ? (
                                    item.static_set.image === null ? (
                                        <img
                                            src={no_image}
                                            alt='no_image'
                                            width='360'
                                            itemProp='image'
                                        />
                                    ) : (
                                        <img
                                            src={item.static_set.image}
                                            alt={`${item.name}`}
                                            width='360'
                                            itemProp='image'
                                        />
                                    )
                                ) : null}
                                {item.watchDog && item.product ? (
                                    item.product.asset_image_thumb === null ? (
                                        <img
                                            src={no_image}
                                            alt='no_image'
                                            width='360'
                                            itemProp='image'
                                        />
                                    ) : (
                                        <img
                                            src={item.product.asset_image_thumb}
                                            alt={`${item.name}`}
                                            width='360'
                                            itemProp='image'
                                        />
                                    )
                                ) : null}
                            </div>
                        </a>

                        <div className='text'>
                            <h2 className='title h4'>
                                {item.watchDog && item.static_set ? (
                                    <span itemProp='name'>{item.static_set.name}</span>
                                ) : null}
                                {/* <a >{this.props.name}</a> */}
                                <span itemProp='name'>{item.name}</span>
                            </h2>
                            <span className='description clearfix' itemProp='description'>
                                {item.description || item.name}
                            </span>
                        </div>
                        <div className={`energyClasses ${item.first_energy_class && item.second_energy_class ? 'two' : 'one'}`}>
                            {item.first_energy_class ? <img src={item.first_energy_class.asset_image} alt={item.first_energy_class.name} /> : null}
                            {item.second_energy_class ? <img src={item.second_energy_class.asset_image} alt={item.second_energy_class.name} /> : null}
                        </div>
                        <div
                            className={`prices ${
                                item.use_discount_price &&
                                item.retail_price_with_vat !== item.discounted_price_with_vat
                                    ? 'useDiscountPrice'
                                    : ''
                            }`}
                            itemProp='offers'
                            itemScope
                            itemType='http://schema.org/Offer'
                        >
                            {item.use_discount_price &&
                            item.retail_price_with_vat !== item.discounted_price_with_vat ? (
                                <sub itemProp='priceCurrency' content="EUR">
                                    <span
                                        itemProp={`price`}
                                        content={item.discounted_price_with_vat.toFixed(2)}
                                        className={`${
                                            item.use_discount_price &&
                                            item.retail_price_with_vat !==
                                                item.discounted_price_with_vat
                                                ? 'discountedPrice'
                                                : ''
                                        }`}
                                    >
                                        {formatMoney(item.discounted_price_with_vat)}
                                    </span>
                                </sub>
                            ) : null}
                            {item.retail_price || item.retail_price === 0 ? (
                                <sup
                                  itemProp={item.use_discount_price && item.retail_price_with_vat !== item.discounted_price_with_vat ? undefined : 'priceCurrency'}
                                  content={item.use_discount_price && item.retail_price_with_vat !== item.discounted_price_with_vat ? undefined : 'EUR'}
                                >
                                    <span
                                      itemProp={item.use_discount_price && item.retail_price_with_vat !== item.discounted_price_with_vat ? undefined : 'price'}
                                      content={item.use_discount_price && item.retail_price_with_vat !== item.discounted_price_with_vat ? undefined : item.retail_price_with_vat.toFixed(2)}
                                        className={`price-show ${
                                            item.use_discount_price &&
                                            item.retail_price_with_vat !==
                                                item.discounted_price_with_vat
                                                ? 'retailPrice'
                                                : ''
                                        }`}
                                    >
                                        {formatMoney(item.retail_price_with_vat)}
                                    </span>
                                </sup>
                            ) : null}

                            {item.watchDog ? (
                                item.static_set && item.static_set.total ? (
                                    <sup itemProp='priceCurrency'>
                                        <span
                                            className={`price-show ${
                                                item.static_set.total_with_vat ? 'fixedSets' : ''
                                            }`}
                                        >
                                            {formatMoney(item.static_set.total_with_vat)}
                                        </span>
                                        <span
                                            className={'retail-price'}
                                            style={{ visibility: 'hidden' }}
                                            itemProp='price'
                                        >
                                            {item.static_set.total_with_vat.toFixed(2)}
                                        </span>
                                    </sup>
                                ) : null
                            ) : null}
                            {item.total ? (
                                <sup itemProp='priceCurrency'>
                                    <span
                                        className={`price-show ${
                                            item.total_with_vat ? 'fixedSet' : ''
                                        }`}
                                    >
                                        {formatMoney(item.total_with_vat)}
                                    </span>
                                    <span
                                        className={'retail-price'}
                                        style={{ visibility: 'hidden' }}
                                        itemProp='price'
                                    >
                                        {item.total_with_vat.toFixed(2)}
                                    </span>
                                </sup>
                            ) : null}

                            {false && item.tags && item.tags.length ? (
                                <div className={'productTags'}>
                                    {item.tags.map(tag => {
                                        return (
                                            <span
                                                key={`productTag_${tag.id}`}
                                                className={'label label-info'}
                                                style={{ backgroundColor: tag.color }}
                                            >
                                                {tag.name}
                                            </span>
                                        )
                                    })}
                                </div>
                            ) : null}

                            <div className={'displayHidden'}>
                                <a
                                    href={`http://schema.org/${
                                        item.warehouse_info > 0 ? 'InStock' : 'OutOfStock'
                                    }`}
                                    itemProp='availability'
                                />
                                <a href={url} itemProp='url'>
                                    {url}
                                </a>
                                <span itemProp='priceValidUntil'>
                                    {moment()
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD')}
                                </span>
                            </div>
                        </div>
                    </div>
                    {item.watch_availability || item.watch_price ? (
                        <div className='watchDogContainer'>
                            {item.watch_availability === true ? (
                                <p className='watchDogText'>
                                    {t.i18n.PAGES.MY_ACCOUNT.WATCH_AVAILABILITY}
                                </p>
                            ) : null}
                            {item.watch_price === true ? (
                                <p className='watchDogText'>
                                    {t.i18n.PAGES.MY_ACCOUNT.WATCH_PRICE} {item.target_price}
                                    &euro;
                                </p>
                            ) : null}
                        </div>
                    ) : null}
                </article>
            </div>
        )
    }
}

export default observer(ProductItem)
