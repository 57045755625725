//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './DescriptionTab.scss'

//api

//custom components

//material-ui

//other libraries
import ReactHtmlParser from 'react-html-parser'

//icon file
import file from '../../../assets/icons/file.svg'

class DescriptionTab extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // console.log('DescriptionTab', this.props)
    }

    render() {
        const warehouse_status_computed = this.props.internal.productDetail.warehouse_status_computed;
        
        return (
            <div className='row DescriptionTab'>
                <div className='col-sm-12'>
                    {/*
                    @rs
                    show warehouse info (whether the product is available or not)
                    */}
                    <div className='description'>
                        <span className='sectionTitle'>
                            {this.props.translate.i18n.COMPONENTS.QUICK_VIEW.WAREHOUSE_INFO}:
                        </span>
                        <span>
                            {this.props.translate.i18n.PAGES.PRODUCT_DETAIL[warehouse_status_computed.toUpperCase()]}
                        </span>
                    </div>
                    <hr />
                    {/*
                    show product description
                    */}
                    <div className='description'>
                        <span className='sectionTitle'>
                            {this.props.translate.i18n.PAGES.PRODUCT_DETAIL.DESCRIPTION}:
                        </span>
                        {ReactHtmlParser(this.props.data.description)}
                    </div>
                    {/*
                    show product documents/files/manuals (if any)
                    */}
                    {this.props.data.document && this.props.data.document.length > 0 ? (
                        <div>
                            <hr />
                            <span className={'techDocsTitle'}>
                                {this.props.translate.i18n.PAGES.PRODUCT_DETAIL.TECHICAL_DOCS}
                            </span>
                            <div className='docs-row'>
                                {this.props.data.document
                                    ? this.props.data.document.map((doc, index) => {
                                          return (
                                              <a
                                                  href={doc.download}
                                                  key={index}
                                                  target='_blank'
                                                  className={'doc-item'}
                                              >
                                                  <img src={file} alt='file' />
                                                  <span>{doc.filename}</span>
                                              </a>
                                          )
                                      })
                                    : null}
                            </div>
                            {/* <ul className='docs'>
                        {this.props.data.document ?
                            this.props.data.document.map((doc, index) => {
                                return (
                                    <li>
                                        <a href={doc.download} key={index} target="_blank">{doc.filename}</a>
                                    </li>
                                )
                            })
                            :
                            null
                        }
                        </ul> */}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(DescriptionTab))
