//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './NotFound.scss'

//api

//custom components

//material-ui

//other libraries

class NotFound extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.props.internal.setGlobalLoader(false)
        const { internal, translate, routerData, ...other } = this.props
        this.props.internal.setRouterData(routerData)
        // console.log("NotFound props", this.props)
        window.init()
    }

    handleGoHome = () => {
        this.props.internal.routerData.history.push('/')
    }

    render() {
        return (
            <div className={'container not-found'}>
                <div className='row'>
                    <div className='col-12 content'>
                        {/* <h1>404</h1> */}
                        <h1>{this.props.translate.i18n.GLOBAL.ALERTS.PAGE_NOT_FOUND}</h1>
                        <p>
                            {this.props.translate.i18n.GLOBAL.ALERTS.PAGE_NOT_FOUND_CONTINUE}
                            <a onClick={this.handleGoHome}>
                                {this.props.translate.i18n.GLOBAL.ALERTS.PAGE}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(NotFound))
