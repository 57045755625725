//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './RegisterModal.scss'

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { regexes } from '../../../utils/fuctions'

class RegisterModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            consent_gdpr: false,
            consent_newsletter: false,
            passIsEqual: true,
            isPassMinLenght: true,
            isConsentGiven: true,
            isEmailFormat: true,
            loging: false,

            resetEmail: '',
            isEmailFormat2: true,
            requestWasSent: false,

            invitationData: null
        }
    }

    componentDidMount() {
        if (
            this.props.internal.routerData.match.params && // <-- @rs added conditional to prevent errors
            this.props.internal.routerData.match.params.hasOwnProperty('invitationToken')
        ) {
            this.checkInvitationToken(this.props.internal.routerData.match.params.invitationToken)
        }
    }

    showRegisterDialog() {
        document.getElementById('registerModal').style.display = 'block'
        document.getElementById('registerModal').classList.add('in')
        let div = document.createElement('div')
        div.classList.add('modal-backdrop', 'fade', 'in')
        document.body.appendChild(div)
    }

    checkInvitationToken = async invitationToken => {
        let check_token = await api({
            method: 'GET',
            url: `invitation/check/${invitationToken}`
        })

        if (check_token.success === true) {
            let invitationData = check_token.data.data
            invitationData.token = invitationToken

            this.setState({ invitationData: invitationData, regEmail: invitationData.email })

            this.showRegisterDialog()
        } else {
            this.props.internal.setSnackbarErrorData(check_token.data.message)
        }
    }

    componentWillUnmount() {
        this.resetComponent()
    }

    resetComponent = () => {
        this.setState({
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            passIsEqual: true,
            isPassMinLenght: true,
            consent_gdpr: false,
            consent_newsletter: false,
            isEmailFormat: true,
            resetEmail: '',
            isEmailFormat2: true,
            requestWasSent: false
        })

        this.closeDialog()
    }

    handleRegisterUser = async () => {
        let email = this.state.regEmail
        let isEmail = regexes.email.test(email)
        let pass = this.state.regPass
        let passConfrim = this.state.regPassConfirm

        if (!isEmail) {
            this.setState({ isEmailFormat: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL_NOT_VALID);
            return
        }

        if (pass !== passConfrim) {
            this.setState({ passIsEqual: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.PASS_IS_NOT_EQUAL);
            return
        }

        if (pass.length < 8) {
            this.setState({ isPassMinLenght: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.MIN_PASS_LENGTH);
            return
        }

        if (false === this.state.consent_gdpr) {
            this.setState({ isConsentGiven: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_REQUIRED);
            return
        }

        let params = {
            email: this.state.regEmail,
            password: this.state.regPass,
            password_confirmation: this.state.regPassConfirm,
            consent_gdpr: this.state.consent_gdpr,
            consent_newsletter: this.state.consent_newsletter,
        }

        if (this.state.invitationData.token) {
            params.token = this.state.invitationData.token
        }

        if (pass === passConfrim && isEmail) {
            this.setState({ loader: true })
            let create_account = await api({
                method: 'POST',
                url: 'account/register',
                params: params
            })
            if (
                create_account.success === true ||
                (create_account.success === false && create_account.status === 201)
            ) {
                this.setState({
                    loader: false,
                    accountWasCreated: true,
                    accountCreatedMsg: create_account.data.message,
                    invitationData: null
                })
                this.props.internal.setSnackbarData(create_account.data.message, true)
                this.closeDialog()
                this.resetComponent()
            } else {
                this.setState({
                    loader: false
                })
                let errors = create_account.data.data.errors
                let resultError = ''
                for (let i in errors) {
                    resultError += errors[i] + ' '
                }
                this.props.internal.setSnackbarErrorData(resultError)
            }
        }
    }

    closeDialog = () => {
        let elements = document.getElementsByClassName('modal-backdrop fade in')
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0])
        }
        let registerModal = document.getElementById('registerModal')
        registerModal.classList.remove('in')
        registerModal.style.display = 'none'
        document.body.classList.remove('modal-open')
        // this.props.internal.routerData.history.push(`/`)
    }

    handleRequestForReset = () => {
        let email = this.state.resetEmail
        let isEmail = regexes.email.test(email)

        if (!isEmail) {
            this.setState({ isEmailFormat2: false })
            return
        }

        this.requestForResetPassword()
    }

    requestForResetPassword = async () => {
        this.setState({ loader: true })
        let request_data = await api({
            method: 'POST',
            url: 'account/password/request',
            params: {
                email: this.state.resetEmail
            }
        })

        if (request_data.success === true) {
            // this.props.internal.setSnackbarData(request_data.data.message, true);
            // this.setState({loader: false})
            this.setState({ requestWasSent: request_data.data.message, loader: false })
        } else {
            this.setState({ requestWasSent: request_data.data.message, loader: false })
        }
    }

    handleChangeRegisterInput = (inputType, val) => {
        this.setState({
            [inputType]: val.target.value,
            isPassMinLenght: true,
            passIsEqual: true,
            isEmailFormat: true,
            isEmailFormat2: true
        })
    }
    
    handleCheckboxChange = (field, val) => {
        this.setState({[field]: val.target.checked})
    }
    
    
    getTermsAndConditionsAlias = () => {
        const { topMenu, bottomMenu } = toJS(this.props.internal)
        let alias = null
        for (let i in topMenu) {
            if (topMenu[i].frontend_menu_type_id === 5) {
                alias = topMenu[i].alias
            }
        }
        for (let i in bottomMenu) {
            if (bottomMenu[i].frontend_menu_type_id === 5) {
                alias = bottomMenu[i].alias
            }
        }
        
        return alias
    }
    
    
    getGDPRAlias = () => {
        const { topMenu, bottomMenu } = toJS(this.props.internal)
        let alias = null
        for (let i in topMenu) {
            if (topMenu[i].frontend_menu_type_id === 8) {
                alias = topMenu[i].alias
            }
        }
        for (let i in bottomMenu) {
            if (bottomMenu[i].frontend_menu_type_id === 8) {
                alias = bottomMenu[i].alias
            }
        }
        
        return alias
    }

    render() {
        return (
            <div className='modal fade' id='registerModal' role='dialog'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={this.resetComponent}
                            >
                                &times;
                            </button>
                            <h4 className='modal-title'>
                                {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.TITLE}
                            </h4>
                        </div>
                        {this.state.loader ? (
                            <div className={'modal-body'}>
                                <div className='lds-dual-ring registerLoader' />
                            </div>
                        ) : this.state.accountWasCreated ? (
                            <div className={'modal-body'}>
                                <span>{this.state.accountCreatedMsg}</span>
                            </div>
                        ) : (
                            <div className='modal-body'>
                                {/* <span
                                        className={`passHelper ${this.state.isPassMinLenght ? '' : 'wrongMinLength'} ${this.state.passIsEqual ? '' : 'passIsNotEqual'} ${this.state.isEmailFormat ? '' : 'emailNotValid'}`}
                                    >
                                        {this.state.isPassMinLenght
                                            ? null
                                            : this.props.translate.i18n.COMPONENTS.REGISTER_FORM.MIN_PASS_LENGTH}
                                        {this.state.passIsEqual
                                            ? null
                                            : this.props.translate.i18n.COMPONENTS.REGISTER_FORM.PASS_IS_NOT_EQUAL}
                                        {this.state.isEmailFormat
                                            ? null
                                            : this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL_NOT_VALID}
                                    </span> */}
                                <div className='form-group'>
                                    <label htmlFor={'email2'}>
                                        {
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                                .LABEL_EMAIL
                                        }
                                    </label>
                                    <input
                                        type='email'
                                        className={`form-control ${
                                            this.state.isEmailFormat ? '' : 'wrong'
                                        }`}
                                        id='email2'
                                        placeholder={
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL
                                        }
                                        value={
                                            this.state.invitationData
                                                ? this.state.invitationData.email
                                                : this.state.regEmail
                                        }
                                        onChange={
                                            val =>
                                                this.setState({
                                                    invitationData: {
                                                        email: val.target.value
                                                    },
                                                    regEmail: val.target.value
                                                })
                                            // this.handleChangeRegisterInput('regEmail', val)
                                        }
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor={'password'}>
                                        {
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                                .LABEL_PASSWORD
                                        }
                                    </label>
                                    <input
                                        type='password'
                                        className={`form-control ${
                                            this.state.isPassMinLenght ? '' : 'wrong'
                                        } ${this.state.passIsEqual ? '' : 'wrong'}`}
                                        id='password'
                                        placeholder={
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                                .PASSWORD
                                        }
                                        value={this.state.regPass}
                                      autoComplete={'new-password'}
                                        onChange={val =>
                                            this.handleChangeRegisterInput('regPass', val)
                                        }
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor={'password-confirm'}>
                                        {
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                                .LABEL_REPEAT_PASSWORD
                                        }
                                    </label>
                                    <input
                                        type='password'
                                        className={`form-control ${
                                            this.state.isPassMinLenght ? '' : 'wrong'
                                        } ${this.state.passIsEqual ? '' : 'wrong'}`}
                                        id='password-confirm'
                                        placeholder={
                                            this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                                .PASSWORD_CONFIRM
                                        }
                                        value={this.state.regPassConfirm}
                                        onChange={val =>
                                            this.handleChangeRegisterInput('regPassConfirm', val)
                                        }
                                    />
                                </div>
                                <span className="checkbox" >
                                    <input type="checkbox" id='consent_gdpr' checked={this.state.consent_gdpr} onChange={(val) => this.handleCheckboxChange('consent_gdpr',val)}/>
                                    <label htmlFor='consent_gdpr' className={`consent-label ${this.state.consent_gdpr ? '' : 'text-red'}`}>
                                        {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_PART_ONE}
                                        <a href={this.getTermsAndConditionsAlias()} target="_blank">{this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_TOS}</a>
                                        {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_AND}
                                        <a href={this.getGDPRAlias()} target="_blank">{this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_GDPR}</a>
                                    </label>
                                </span>
                                <span className="checkbox" >
                                    <input type="checkbox" id='consent_newsletter' checked={this.state.consent_newsletter} onChange={(val) => this.handleCheckboxChange('consent_newsletter',val)}/>
                                    <label htmlFor='consent_newsletter' className={'consent-label'}>
                                        {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_NEWSLETTER}
                                    </label>
                                </span>
                                <button
                                    type='button'
                                    className='btn btn-default'
                                    onClick={this.handleRegisterUser}
                                >
                                    {
                                        this.props.translate.i18n.COMPONENTS.REGISTER_FORM
                                            .BTN_REGISTER
                                    }
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(RegisterModal))
