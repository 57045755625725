//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import ReactGA from 'react-ga4'
//api
import { api } from '../../utils/api'

//other libraries
import queryString from 'query-string'
import { isEqual } from 'lodash'

//custom components
import Article from '../../pages/Article/Article';
import Contact from '../../pages/Contact/Contact';
import HomePage from '../../pages/HomePage/HomePage';
import NotFound from '../../pages/NotFound/NotFound';
import ProductDetail from '../../pages/ProductDetail/ProductDetail';
import ProductsGrid from '../../pages/ProductsGrid/ProductsGrid';
import FixedSetDetail from '../../pages/FixedSetDetail/FixedSetDetail';
import ThankYouPage from '../../pages/ThankYouPage/ThankYouPage';
import ServiceProtocol from '../../pages/serviceProtocol/ServiceProtocol';
import ContractWithdrawal from '../../pages/contractWithdrawal/ContractWithdrawal';

/**
 * Prepina medzi typmi menu: article, contact, hompage
 * do modulu Article, Contant sa z tohoto modulu posielaju data ako props
 */
class CustomSwitcher extends Component {
    constructor(props) {
        super(props)
        this.state = {
            frontend_menu_type: null,
            data: null,
            loading: true,
            page_type: null,
            category_path: null,
            category_path_alt: []
        }
    }

    componentDidMount() {
        const { internal, translate, ...other } = this.props
        this.props.internal.setRouterData(other)
        this.loadData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.loadData()
        }

        const routerData = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }

        if (!isEqual(routerData, this.routerData)) {
            this.routerData = routerData
        }
    }

    loadData = async () => {
        this.props.internal.setGlobalLoader(true)
        let load_data = {success: false};
        if (document.body.dataset.hasOwnProperty('page')) {
            load_data = {
                success: true,
                data: JSON.parse(document.body.dataset.page)
            }
            document.body.removeAttribute('data-page')
        } else {
            this.setState({ loading: true })

            let slug = ''
            let params = this.props.match.params
            for (let i in params) {
                if (params[i] !== undefined) {
                    slug += params[i] + '/'
                }
            }

            slug = slug.slice(0, -1)

            const URLqueryString = this.props.internal.routerData.history.location.search
            let queries = queryString.parse(URLqueryString)

            let params2 = {}
            let filters = []
            let order = [{}]
            let orderIsUsed = false
            let tmpSort = ''
            if (queries) {
                let full_path = this.props.history.location.search
                let queries2 = queryString.parse(full_path)
                for (let i in queries2) {
                    if (i === 'price_from' || i === 'price_to') {
                        filters.push({ retail_price: queries2[i] })
                        this.setState({ filtersIsUsed: true })
                    } else if (i === 'orderBy') {
                        order[0].by = queries2[i]
                        orderIsUsed = true
                        tmpSort += queries2[i] + '-'
                    } else if (i === 'orderDir') {
                        order[0].dir = queries2[i]
                        orderIsUsed = true
                        tmpSort += queries2[i]
                        this.setState({ sort: tmpSort })
                    } else {
                        if (Array.isArray(queries2[i])) {
                            for (let index = 0; index < queries2[i].length; index++) {
                                filters.push({ parameter_item_id: queries2[i][index] })
                            }
                        } else {
                            filters.push({ parameter_item_id: queries2[i] })
                        }
                        this.setState({ filtersIsUsed: true })
                    }
                }

                params2.filter = filters
            }
            params2.page = 12

            if (orderIsUsed) {
                params2.order = order
            }

            // slug = 'about'; // article
            // slug = 'category-slug/sub-category-slug/1234567890-product-name' // product detail
            // slug = 'soluta-qui-at/nosqquietor-show' // category
            // slug = 'nazov-fixneho-setu' // fixny set
            load_data = await api({
                url: `resolver/${slug}`,
                method: 'GET',
                params: params2
            })

        }

        if (load_data.success === true) {
            if (load_data.data.type) {
                if (load_data.data.type === 'product') {
                    this.setState({
                        page_type: load_data.data.type,
                        data: load_data.data.data,
                        loading: false
                    })
                } else if (load_data.data.type === 'products') {
                    this.setState({
                        page_type: load_data.data.type,
                        data: load_data.data,
                        loading: false
                    })

                    this.props.internal.setAllAboutListProducts(load_data.data)
                } else if (load_data.data.type === 'static_set') {
                    this.setState({
                        page_type: load_data.data.type,
                        data: load_data.data.data,
                        loading: false
                    })
                } else if (load_data.data.type === 'frontend_menu') {
                    this.setState({
                        page_type: load_data.data.type,
                        data: load_data.data.data,
                        loading: false
                    })
                } else if (load_data.data.type === 'static_page') {
                    this.setState({
                        page_type: load_data.data.type,
                        data: load_data.data.data,
                        loading: false
                    })
                }
            } else {
                if (load_data.data.data.frontend_menu_type) {
                    this.setState({
                        page_type: load_data.data.data.frontend_menu_type.name,
                        data: load_data.data.data,
                        loading: false
                    })
                }
            }
        } else {
            this.setState({
                page_type: 'not-found',
                loading: false
            })
        }
        this.props.internal.setGlobalLoader(false)
        ReactGA.send({ hitType: 'pageview', path: this.props.location.pathname })
    }

    routerData = {
        history: this.props.history,
        location: this.props.location,
        match: this.props.match
    }

    render() {
        const { frontend_menu_type, loading, page_type } = this.state

        if (
            page_type === 'frontend_menu' &&
            [4, 5, 8].indexOf(this.state.data.frontend_menu_type_id) !== -1 &&
            loading === false
        ) {
            return <Article data={this.state.data} routerData={this.routerData} />
        } else if (
            page_type === 'frontend_menu' &&
            this.state.data.frontend_menu_type_id === 3 &&
            loading === false
        ) {
            return <Contact data={this.state.data} routerData={this.routerData} />
        } else if (
          page_type === 'frontend_menu' &&
          this.state.data.frontend_menu_type_id === 6 &&
          loading === false
        ) {
            return <ServiceProtocol data={this.state.data} routerData={this.routerData} />
        } else if (
          page_type === 'frontend_menu' &&
          this.state.data.frontend_menu_type_id == 7 &&
          loading === false
        ) {
            return <ContractWithdrawal data={this.state.data} routerData={this.routerData} />
        } else if (frontend_menu_type === 'home' && loading === false) {
            return <HomePage routerData={this.routerData} />
        } else if (page_type === 'product' && loading === false) {
            return <ProductDetail
                    routerData={this.routerData}
                    data={this.state.data}
                    ref={ref => this.props.internal.setProductDetailRef(ref)}
                />
        } else if (page_type === 'products' && loading === false) {
            ReactGA.event('view_item_list', {
                items: this.state.data.data.map(item => ({
                    item_id: item.code ? item.code : item.name,
                    item_name: item.name,
                    affiliation: 'Bekoshop.sk',
                    coupon: item.code && item.coupons && item.coupons.length > 0 ? item.coupons[0].code : null,
                    item_category: item.code ? item.category_path_alt[0] : null,
                    item_category2: item.code && item.category_path_alt[1] ? item.category_path_alt[1] : null,
                    item_category3: item.code && item.category_path_alt[2] ? item.category_path_alt[2] : null,
                    price: item.total ? item.total : (item.use_discount_price ? item.discounted_price_with_vat : item.retail_price_with_vat),
                }))
            })
            return <ProductsGrid routerData={this.routerData} data={this.state.data} />
        } else if (page_type === 'static_set' && loading === false) {
            return <FixedSetDetail routerData={this.routerData} data={this.state.data} />
        } else if (page_type === 'static_page' && loading === false) {
            return <ThankYouPage routerData={this.routerData} data={this.state.data} />
        } else if (loading === true) {
            // else {
            return null
        } else {
            if (page_type === 'not-found' && loading === false) {
                return <NotFound routerData={this.routerData} />
            }
        }
    }
}

export default inject('internal', 'translate')(observer(CustomSwitcher))
