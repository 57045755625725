//basic
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// stores
import internalStore from '../../../stories/internal'

// styles
import './Banner.scss'

class Banner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      hasMiniBanner: false,
      hasMultipleMniBanners: false,
    }
  }

  componentDidMount() {
    this.loadSettings()
  }

  loadSettings = async () => {
    // let load_data = await api({
    //     method: 'GET',
    //     url: "settings",
    // })
    //
    // if(load_data.success === true) {
    //     // console.log("load_data",load_data)
    //     this.setState({logo: load_data.data.data.shop.email_asset_email_logo})
    // }
    await internalStore.loadFooterData()

    this.setState({
      hasMiniBanner: (internalStore.footerData.shop.asset_mini_banner_image && internalStore.footerData.shop.mini_banner_url) || (internalStore.footerData.shop.asset_mini_banner2_image && internalStore.footerData.shop.mini_banner_2_url),
      hasMultipleMniBanners: [
        internalStore.footerData.shop.mini_banner_url && internalStore.footerData.shop.mini_banner_url,
        internalStore.footerData.shop.mini_banner_2_url && internalStore.footerData.shop.mini_banner_2_url
      ].filter(i => i).length > 1,
    })
  }

  render() {
    return (
      <section
        className={`banner-section ${internalStore.banners.length < 2 ? 'oneBanner' : ''} ${this.state.hasMiniBanner ? 'mini-banner' : ''} ${this.state.hasMultipleMniBanners ? 'multiple' : ''}`}
      >
        <div className="header-content">
          <div className="owl-slider ">
            {internalStore.banners.map((banner, index) => {
              return (
                <div key={index} className="item">
                  <img
                    src={index === 0 ? banner.asset_image : undefined}
                    data-src={index > 0 ? banner.asset_image : undefined}
                    className={`img lazyOwl`}
                  />
                  <div className={`overlay jc${banner.banner_text_position_id || 5}`}>
                    <div className={`container`}>
                      <div className={`container alignContent ac${banner.banner_text_position_id || 5}`}>
                        <h2 className="title animated h1" data-animation="fadeInDown">
                          {banner.title}
                        </h2>
                        <div
                          className="animated bannerSubtitle"
                          data-animation="fadeInUp"
                        >
                          {banner.subtitle}
                        </div>

                        <div className="animated" data-animation="fadeInUp">
                          <a
                            href={banner.button_link}
                            target={banner.is_target_blank ? '_blank' : undefined}
                            className="btn btn-md btnBlueBackground"
                          >
                            <i className={`icon ${banner.icon}`}/>{' '}
                            {banner.button_title}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default observer(Banner)
