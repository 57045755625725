//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './ResetPasswordModal.scss';

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { regexes } from '../../../utils/fuctions'

class ResetPasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regEmail: "",
            regPass: "",
            regPassConfirm: "",
            passIsEqual: true,
            isPassMinLenght: true,
            isEmailFormat: true,
            loging: false,

            resetEmail: "",
            isEmailFormat2: true,
            requestWasSent: false,
        }
    }

    componentWillUnmount() {
        this.resetComponent()
    }

    resetComponent = () => {
        this.setState({
            regEmail: "",
            regPass: "",
            regPassConfirm: "",
            passIsEqual: true,
            isPassMinLenght: true,
            isEmailFormat: true,
            resetEmail: "",
            isEmailFormat2: true,
            requestWasSent: false,
        })
    }

    handleRegisterUser = async () => {
        let email = this.state.regEmail
        let isEmail = regexes.email.test(email)
        let pass = this.state.regPass
        let passConfrim = this.state.regPassConfirm

        if (!isEmail) {
            this.setState({ isEmailFormat: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL_NOT_VALID)
            return
        }

        if (pass !== passConfrim) {
            this.setState({ passIsEqual: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.PASS_IS_NOT_EQUAL)
            return
        }

        if (pass.length < 8) {
            this.setState({ isPassMinLenght: false })
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.MIN_PASS_LENGTH)
            return
        }

        if (pass === passConfrim && isEmail) {
            this.setState({ loader: true })
            let create_account = await api({
                method: 'POST',
                url: 'account/register',
                params: {
                    email: this.state.regEmail,
                    password: this.state.regPass,
                    password_confirmation: this.state.regPassConfirm
                }
            })
            if (create_account.success === true || (create_account.success === false && create_account.status === 201)) {
                this.setState({
                    loader: false,
                    accountWasCreated: true,
                    accountCreatedMsg: create_account.data.message
                })
                this.props.internal.setSnackbarData(create_account.data.message, true)
                let elements = document.getElementsByClassName('modal-backdrop fade in');
                while(elements.length > 0){
                    elements[0].parentNode.removeChild(elements[0]);
                }
                let registerModal = document.getElementById("registerModal")
                registerModal.classList.remove("in")
                registerModal.style.display = "none"
                document.body.classList.remove("modal-open")
                this.resetComponent()
            }
        }
    }

    handleRequestForReset = () => {
        let email = this.state.resetEmail
        let isEmail = regexes.email.test(email)

        if (!isEmail) {
            this.setState({ isEmailFormat2: false })
            return
        }

        this.requestForResetPassword()
    }

    requestForResetPassword = async() => {
        this.setState({loader: true})
        let request_data = await api({
            method: 'POST',
            url: 'account/password/request',
            params: {
                email: this.state.resetEmail
            }
        })

        if(request_data.success === true) {
            // this.props.internal.setSnackbarData(request_data.data.message, true);
            // this.setState({loader: false})
            this.setState({requestWasSent: request_data.data.message, loader: false})
        }
        else {
            this.setState({requestWasSent: request_data.data.message, loader: false})
        }
    }

    handleChangeRegisterInput = (inputType, val) => {
        this.setState({ [inputType]: val.target.value, isPassMinLenght: true, passIsEqual: true, isEmailFormat: true, isEmailFormat2: true })
    }

    render () {
        return (
            <div className='modal fade requestForResetPassword' id='resetPasswordModal' role='dialog'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <button type='button' className='close' data-dismiss='modal' onClick={this.resetComponent}>
                            &times;
                        </button>
                        <h4 className='modal-title'>
                            {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.FORGOT_PASSWORD}
                        </h4>
                    </div>
                    
                    {this.state.loader ?
                        <div className={'modal-body'}>
                            <div className='lds-dual-ring requestForResetPasswordLoader' />
                        </div>
                        :
                        this.state.requestWasSent !== false ?
                            <div className='modal-body'>
                                <p>{this.state.requestWasSent}</p>
                            </div>
                            :
                        <div className='modal-body'>
                            
                            <div className='form-group'>
                                <label htmlFor={'email1'}>
                                    {
                                        this.props.translate.i18n.COMPONENTS.REGISTER_FORM.LABEL_EMAIL
                                    }
                                </label>
                                <input
                                    type='email'
                                    className={`form-control ${
                                        this.state.isEmailFormat2 ? '' : 'wrong'
                                    }`}
                                    id='email1'
                                    placeholder={
                                        this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL
                                    }
                                    value={this.state.resetEmail}
                                    onChange={val =>
                                        this.handleChangeRegisterInput('resetEmail', val)
                                    }
                                />
                            </div>
                            
                            <button
                                type='button'
                                className='btn btn-default'
                                onClick={this.handleRequestForReset}
                            >
                                {
                                    this.props.translate.i18n.PAGES.MY_ACCOUNT.RESET_BTN
                                }
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    
        )
    }
}

export default inject('internal', 'translate')(observer(ResetPasswordModal))
