import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import './ContractWithdrawal.scss'
import AuthInput from '../../components/basic/AuthInput'
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core'
import CustomDatePicker from '../../components/basic/DatePicker/DatePicker'
import { api } from '../../utils/api'

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 9,
    useNextVariants: true,
  },
})

class ContractWithdrawal extends Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()
  }

  componentDidMount() {
    const { routerData, ...other } = this.props
    this.props.internal.setRouterData(routerData)
    this.props.internal.setGlobalLoader(false)
    // this.props.internal.setHeaderTitle(this.props.translate.i18n.GLOBAL.MENU.SERVICE_PROTOCOL)
  }

  handleChange = (field, e) => this.setState({
    errors: this.state.errors,
    form: { ...this.state.form, [field]: e.target.value },
  })

  handleCheckboxChange = (field, e) => this.setState({ form: { ...this.state.form, [field]: e.target.checked } })

  handleDateChange = (field, date) => this.setState({ form: { ...this.state.form, [field]: date } })

  handleFileChange = (field, e) => this.setState({ form: { ...this.state.form, [field]: (e.target.files[0] || null) } })

  submitForm = () => {
    this.setState({ errors: {}, loading: true })
    api({
      method: 'POST',
      url: 'contract-withdrawal',
      authorization: this.props.internal.user.access_token,
      formData: true,
      params: this.state.form,
    }).then(response => {
      this.setState({loading: false})
      if (false === response.success) {
        if (response.status === 422) {
          this.setState({ errors: { ...response.data.data.errors } })
          this.props.internal.setSnackbarErrorData(response.data.message)
        } else {
          this.props.internal.setSnackbarErrorData(response.data.message)
        }
      } else {
        this.setState(this.getInitialState())
        this.props.internal.setSnackbarData(response.data.message, true)
      }
    })
  }

  getInitialState() {
    return {
      form: {
        name: '',
        street_name: '',
        street_no: '',
        city: '',
        email: '',
        phone: '',
        ordered_number: '',
        invoice_number: '',
        ordered_at: null,
        delivered_at: null,
        returned_products: '',
        model: '',
        serial_number_fnr: '',
        return_reason: '',
        refund_policy: '',
      },
      errors: {},
      loading: false
    }
  }

  hasErrors = field => this.state.errors.hasOwnProperty(field)

  getError = field => this.hasErrors(field) ? this.state.errors[field][0] : ''

  render() {
    return <MuiThemeProvider theme={theme}>
      <div className="article">
        <section className="main-header productDetail ">
          <header>
            <div className="container">
              <h1 className={'title'}>{this.props.data.name}</h1>
            </div>
          </header>
        </section>

        <section className={'section-content'}>
          <div className={'container bgGrayLight'}>
            <div className={'content'}>
              <div className="p-4">
                <fieldset className="fieldset">
                  <legend>Vaše údaje / zákazník</legend>
                  <div className="w-full">
                    <div className="p-4">
                      <AuthInput
                        label="Meno Priezvisko:"
                        required
                        value={this.state.form.name}
                        className="w-100"
                        name='name'
                        hasError={this.hasErrors('name')}
                        errorMessage={this.getError('name')}
                        onChange={e => this.handleChange('name', e)}
                      />
                      <div className={'flex'}>
                        <div className="w-4/5">
                          <AuthInput
                            label="Adresa:"
                            required
                            value={this.state.form.street_name}
                            className="w-100"
                            name="street_name"
                            hasError={this.hasErrors('street_name')}
                            errorMessage={this.getError('street_name')}
                            onChange={e => this.handleChange('street_name', e)}
                          />
                        </div>
                        <div className="w-1/5">
                          <AuthInput
                            label="Číslo:"
                            required
                            value={this.state.form.street_no}
                            className="w-100"
                            name="street_no"
                            hasError={this.hasErrors('street_no')}
                            errorMessage={this.getError('street_no')}
                            onChange={e => this.handleChange('street_no', e)}
                          />
                        </div>
                      </div>
                      <AuthInput
                        label="Mesto:"
                        required
                        value={this.state.form.city}
                        className="w-100"
                        name="city"
                        hasError={this.hasErrors('city')}
                        errorMessage={this.getError('city')}
                        onChange={e => this.handleChange('city', e)}
                      />
                      <AuthInput
                        label="E-mail:"
                        required
                        value={this.state.form.email}
                        className="w-100"
                        name="email"
                        hasError={this.hasErrors('email')}
                        errorMessage={this.getError('email')}
                        onChange={e => this.handleChange('email', e)}
                      />
                      <AuthInput
                        label="Telefón:"
                        required
                        value={this.state.form.phone}
                        className="w-100"
                        name="phone"
                        hasError={this.hasErrors('phone')}
                        errorMessage={this.getError('phone')}
                        onChange={e => this.handleChange('phone', e)}
                      />
                    </div>
                  </div>
                </fieldset>
                  <div className="w-full">
                    <p>
                      (ďalej len ako „spotrebiteľ“) týmto odstupujem v súlade so zákonom č. 102/2014 Z.z. v znení neskorších predpisov od kúpnej zmluvy

                      uzavretej so spoločnosťou RAVAL TRADE, s.r.o. so sídlom Mojmírova 1/4935, 921 01 Piešťany. (ďalej len ako „predávajúci“).

                      Ako spotrebiteľ týmto vraciam predávajúcemu v lehote 14 dní zakúpené výrobky podľa nižšie uvedených údajov:
                    </p>
                  </div>
                <fieldset>
                  <legend>Informácie o objednávke</legend>
                  <div className="w-full">
                    <div className="p-4">
                      <AuthInput
                        label="Číslo objednávky:"
                        required
                        value={this.state.form.ordered_number}
                        className="w-100"
                        name='ordered_number'
                        hasError={this.hasErrors('ordered_number')}
                        errorMessage={this.getError('ordered_number')}
                        onChange={e => this.handleChange('ordered_number', e)}
                      />
                      <AuthInput
                        label="Číslo faktúry:"
                        required
                        value={this.state.form.invoice_number}
                        className="w-100"
                        name="invoice_number"
                        hasError={this.hasErrors('invoice_number')}
                        errorMessage={this.getError('invoice_number')}
                        onChange={e => this.handleChange('invoice_number', e)}
                      />

                      <CustomDatePicker
                        label="Dátum objednania"
                        value={this.state.form.ordered_at}
                        name="ordered_at"
                        translate={this.props.translate}
                        hasError={this.hasErrors('ordered_at')}
                        errorMessage={this.getError('ordered_at')}
                        onChange={e => this.handleDateChange('ordered_at', e)}
                        required
                        autoOk
                        disableFuture
                      />
                      <CustomDatePicker
                        label="Dátum prevzatia tovaru"
                        value={this.state.form.delivered_at}
                        name="delivered_at"
                        translate={this.props.translate}
                        hasError={this.hasErrors('delivered_at')}
                        errorMessage={this.getError('delivered_at')}
                        onChange={e => this.handleDateChange('delivered_at', e)}
                        required
                        autoOk
                        disableFuture
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="fieldset">
                  <legend>Detaily odstúpenia od zmluvy</legend>
                  <div className="w-full">
                    <div className="p-4">
                      <AuthInput
                        label="Výrobky, ktoré ako spotrebiteľ vraciam:"
                        multiline
                        value={this.state.form.returned_products}
                        className="w-100"
                        name="returned_products"
                        hasError={this.hasErrors('returned_products')}
                        errorMessage={this.getError('returned_products')}
                        onChange={e => this.handleChange('returned_products', e)}
                      />
                      <AuthInput
                        label="Model:"
                        multiline
                        value={this.state.form.model}
                        className="w-100"
                        name="model"
                        hasError={this.hasErrors('model')}
                        errorMessage={this.getError('model')}
                        onChange={e => this.handleChange('model', e)}
                      />
                      <AuthInput
                        label="Sériové číslo (F.Nr.):"
                        multiline
                        value={this.state.form.serial_number_fnr}
                        className="w-100"
                        name="serial_number_fnr"
                        hasError={this.hasErrors('serial_number_fnr')}
                        errorMessage={this.getError('serial_number_fnr')}
                        onChange={e => this.handleChange('serial_number_fnr', e)}
                      />
                      <AuthInput
                        label="Dôvod vrátenia výrobku:"
                        multiline
                        value={this.state.form.return_reason}
                        className="w-100"
                        name="return_reason"
                        hasError={this.hasErrors('return_reason')}
                        errorMessage={this.getError('return_reason')}
                        onChange={e => this.handleChange('return_reason', e)}
                      />
                      <AuthInput
                        label="Spôsob vrátenie kúpnej ceny:"
                        multiline
                        value={this.state.form.refund_policy}
                        className="w-100"
                        name="refund_policy"
                        hasError={this.hasErrors('refund_policy')}
                        errorMessage={this.getError('refund_policy')}
                        onChange={e => this.handleChange('refund_policy', e)}
                      />
                    </div>
                  </div>
                </fieldset>
                <div>
                  <p>
                    Predávajúci je podľa §10 ods. 4 zákona č. 102/2014 Z.z. v znení neskorších predpisov oprávnený požadovať od spotrebiteľa preplatenie zníženia hodnoty tovaru, ktoré vzniklo v dôsledku takého zaobchádzania s tovarom, ktoré je nad rámec zaobchádzania potrebného na zistenie vlastností a funkčnosti tovaru.
                  </p>
                  <p>
                    Podrobné informácie o možnostiach vrátenia tovaru sú uvedené v časti <a href='/obchodne-podmienky'>Obchodné podmienky</a>.
                  </p>
                </div>
                <div>
                  <button type='button' disabled={this.state.loading} className='btn btn-block btnLogin btnWhiteBackground' onClick={this.submitForm}>
                    Pokračovať
                  </button>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </MuiThemeProvider>
  }
}

export default inject('internal', 'translate')(observer(ContractWithdrawal))
