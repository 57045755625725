//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './LoginWrapper.scss'

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import FacebookLogin from 'react-facebook-login'
import { regexes } from '../../../utils/fuctions'

const STEPS = ['osobne-udaje', 'objednavky', 'strazny-pes', 'recenzie']

class LoginWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            passIsEqual: true,
            isPassMinLenght: true,
            isEmailFormat: true,
            loging: false,

            resetEmail: '',
            isEmailFormat2: true,
            requestWasSent: false
        }
    }

    componentDidMount() {
        let userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
        // console.log("user", userFromLocalStorage)
        if (userFromLocalStorage && userFromLocalStorage.logged === true) {
            this.props.internal.setAllUserData(userFromLocalStorage)
            // console.log("user from store", toJS(this.props.internal.user))
        }
        window.addEventListener('click', this.handleClickOutsideOfLoginWrapper)

        // @ts-ignore
        window.handleCredentialResponse = response => {
            return this.loginWithFacebookGoogle('google-one-tap', {
                accessToken: response.credential
            })
        }
    }

    cnt = 0
    handleClickOutsideOfLoginWrapper = e => {
        if (this.props.isOpen === true) {
            this.cnt++
            if (!document.getElementById('loginWrapper').contains(e.target)) {
                // console.log("inside")
                if (this.cnt % 2 === 0) {
                    this.props.closeDialog()
                    // console.log("outisde")
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutsideOfLoginWrapper)

        this.setState({
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            passIsEqual: true,
            isPassMinLenght: true,
            isEmailFormat: true,
            resetEmail: '',
            isEmailFormat2: true,
            requestWasSent: false
        })
    }

    handleChangeRegisterInput = (inputType, val) => {
        this.setState({ [inputType]: val.target.value })

        this.setState({
            isPassMinLenght: true,
            passIsEqual: true,
            isEmailFormat: true,
            isEmailFormat2: true
        })
    }

    handleRegisterUser = async () => {
        // console.log("register data", this.state.regEmail, (this.state.regPass).length, this.state.regPass, this.state.regPassConfirm)
        let email = this.state.regEmail
        let isEmail = regexes.email.test(email)
        let pass = this.state.regPass
        let passConfrim = this.state.regPassConfirm

        if (!isEmail) {
            this.setState({ isEmailFormat: false })
            this.props.internal.setSnackbarErrorData(
                this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL_NOT_VALID
            )
            return
        }

        if (pass !== passConfrim) {
            this.setState({ passIsEqual: false })
            this.props.internal.setSnackbarErrorData(
                this.props.translate.i18n.COMPONENTS.REGISTER_FORM.PASS_IS_NOT_EQUAL
            )
            return
        }

        if (pass.length < 8) {
            this.setState({ isPassMinLenght: false })
            this.props.internal.setSnackbarErrorData(
                this.props.translate.i18n.COMPONENTS.REGISTER_FORM.MIN_PASS_LENGTH
            )
            return
        }

        if (pass === passConfrim && isEmail) {
            this.setState({ loader: true })
            let create_account = await api({
                method: 'POST',
                url: 'account/register',

                //TODO: vymazat
                // postman: true,
                params: {
                    email: this.state.regEmail,
                    password: this.state.regPass,
                    password_confirmation: this.state.regPassConfirm
                }
            })
            // console.log("create_account", create_account)
            if (
                create_account.success === true ||
                (create_account.success === false && create_account.status === 201)
            ) {
                this.setState({
                    loader: false,
                    accountWasCreated: true,
                    accountCreatedMsg: create_account.data.message
                })
                this.props.internal.setSnackbarData(create_account.data.message, true)
                let elements = document.getElementsByClassName('modal-backdrop fade in')
                while (elements.length > 0) {
                    elements[0].parentNode.removeChild(elements[0])
                }
                let registerModal = document.getElementById('registerModal')
                registerModal.classList.remove('in')
                registerModal.style.display = 'none'
                document.body.classList.remove('modal-open')
                this.resetComponent()
            }
        }
    }

    responseFacebook = response => {
        this.setState({ loging: true })
        // console.log('FACEBOOK login', response)

        this.loginWithFacebookGoogle('facebook', response)
    }

    loginWithFacebookGoogle = async (service, response) => {
        // console.log("response",response)
        let load_data = await api({
            url: `account/social/${service}`,
            method: 'POST',
            params: {
                access_token: response.accessToken
            }
        })

        if (load_data.success === true) {
            // localStorage.setItem('user_logged', JSON.stringify(response))

            // console.log("load_data", load_data)

            if (service === 'google') {
                this.setState({ googleLogin: true })
                this.props.internal.setUserToken(load_data.data.data.access_token)
            } else if (service === 'facebook') {
                this.setState({ facebookLogin: true })
                this.props.internal.setUserToken(load_data.data.data.access_token)
            } else if (service === 'google-one-tap') {
                this.setState({ googleLogin: true })
                this.props.internal.setUserToken(load_data.data.data.access_token)
            }

            this.getUser()
        } else {
            this.props.internal.setSnackbarErrorData(load_data.data.message)
            this.setState({ loging: false })
        }
    }

    handleLogin = async () => {
        // console.log("LOGIN")
        // console.log("email", this.email.value, '\npass', this.password.value)

        this.setState({ loging: true })

        let email = this.email.value
        let passowrd = this.password.value

        //TODO: vymazat
        // email = "email@example.com"
        // passowrd = "8-characters-minimum"

        let login_data = await api({
            method: 'POST',
            url: 'account/login',
            // postman: true,
            params: {
                email: email,
                password: passowrd
            }
        })

        if (login_data.success === true) {
            this.props.internal.setUserToken(login_data.data.data.access_token)
            this.getUser()
        } else {
            this.props.internal.setSnackbarErrorData(
                this.props.translate.i18n.GLOBAL.ALERTS.INCORECT_LOGIN_INPUTS
            )
            this.setState({ loging: false })
        }
    }

    getUser = async () => {
        let user_data = await api({
            method: 'GET',
            url: 'account/user',
            authorization: this.props.internal.user.acces_token
        })

        if (user_data.success === true) {
            this.props.internal.setUserData({ detail: user_data.data.data, logged: true })
            // console.log("user data from store", toJS(this.props.internal.user))
            let user = this.props.internal.user
            localStorage.setItem('user', JSON.stringify(user))
            this.setState({ loging: false })
        } else {
            this.props.internal.setSnackbarErrorData(user_data.data.message)
            this.setState({ loging: false })
        }
    }

    handleLogout = async () => {
        // console.log("LOGOUT")
        this.setState({ loging: true })

        let logout_user = await api({
            url: 'account/logout',
            method: 'GET',
            authorization: this.props.internal.user.acces_token

            //TODO: vymazat
            // postman: true,
        })

        // console.log("logout", this.props)
        if (logout_user.success === true) {
            localStorage.removeItem('user')
            this.props.internal.setAllUserData({
                logged: false,
                acces_token: null,
                detail: []
            })

            // console.log("LOGOUT", this.props)
            if (this.props.internal.routerData.match.path !== '/') {
                this.props.internal.routerData.history.push('/')
            }
            this.props.closeDialog()
            this.setState({ loging: false })
        }
    }

    handleGoToAccount = section => {
        this.props.internal.setGlobalLoader(true)
        // console.log("section", section)
        // console.log("this.props", this.props)
        this.props.internal.routerData.history.push(`/moj-ucet/${section}`)
        this.props.closeDialog()
        this.props.internal.setGlobalLoader(false)
    }

    handleRequestForReset = () => {
        let email = this.state.resetEmail
        let isEmail = regexes.email.test(email)

        if (!isEmail) {
            this.setState({ isEmailFormat2: false })
            return
        }

        this.requestForResetPassword()
    }

    requestForResetPassword = async () => {
        this.setState({ loader: true })
        let request_data = await api({
            method: 'POST',
            url: 'account/password/request',
            params: {
                email: this.state.resetEmail
            }
        })

        // console.log("requestForResetPassword", request_data.data.message)

        if (request_data.success === true) {
            // this.props.internal.setSnackbarData(request_data.data.message, true);
            // this.setState({loader: false})
            this.setState({ requestWasSent: request_data.data.message, loader: false })
        } else {
            this.setState({ requestWasSent: request_data.data.message, loader: false })
        }
    }

    resetComponent = () => {
        this.setState({
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            passIsEqual: true,
            isPassMinLenght: true,
            isEmailFormat: true,
            resetEmail: '',
            isEmailFormat2: true,
            requestWasSent: false
        })
    }

    render() {
        const { user } = this.props.internal
        // console.log("user", user)
        return user.logged ? (
            <div
                className={`login-wrapper ${this.props.isOpen ? 'open' : ''} myAccount`}
                id={'loginWrapper'}
            >
                {this.state.loging ? <div className='lds-dual-ring loging' /> : null}
                <div style={{ opacity: this.state.loging ? 0 : 1 }}>
                    <p className={'username'}>{user.detail.username}</p>
                    <a onClick={() => this.handleGoToAccount(STEPS[0])}>
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.MY_ACCOUNT}
                    </a>
                    <a onClick={() => this.handleGoToAccount(STEPS[1])}>
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.MY_ORDERS}
                    </a>
                    <a onClick={() => this.handleGoToAccount(STEPS[2])}>
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.MY_FOLLOWED_PRODUCTS}
                    </a>
                    <a onClick={() => this.handleGoToAccount(STEPS[3])}>
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.MY_REVIEWS}
                    </a>
                    <button
                        type='submit'
                        className='btn btn-block btnLogin btnWhiteBackground'
                        data-toggle='modal'
                        data-target='#inviteFriendDialog'
                    >
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.INVITE_FRIEND}
                    </button>
                    <button
                        type='submit'
                        className='btn btn-block btnLogin btnBlueBackground'
                        onClick={() => this.handleLogout()}
                    >
                        {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.LOGOUT}
                    </button>
                </div>
            </div>
        ) : (
            <div>
                <div
                    className={`login-wrapper ${this.props.isOpen ? 'open' : ''}`}
                    id={'loginWrapper'}
                >
                    {this.state.loging ? <div className='lds-dual-ring loging' /> : null}
                    <div style={{ opacity: this.state.loging ? 0 : 1 }}>
                        <div>
                            <div className='h4'>
                                {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.SIGN_IN}
                            </div>
                            <div className='form-group'>
                                <input
                                    type='email'
                                    className='form-control'
                                    id='loginEmail'
                                    placeholder={
                                        this.props.translate.i18n.COMPONENTS.LOGIN_FORM.EMAIL
                                    }
                                    ref={ref => (this.email = ref)}
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='password'
                                    className='form-control'
                                    id='loginPassword'
                                    placeholder={
                                        this.props.translate.i18n.COMPONENTS.LOGIN_FORM.PASSWORD
                                    }
                                    ref={ref => (this.password = ref)}
                                />
                            </div>
                            <div className='form-group'>
                                <a
                                    className='open-popup'
                                    data-toggle='modal'
                                    data-target='#resetPasswordModal'
                                    onClick={this.props.closeDialog}
                                >
                                    {
                                        this.props.translate.i18n.COMPONENTS.LOGIN_FORM
                                            .FORGOT_PASSWORD
                                    }
                                </a>
                                <a
                                    className='open-popup'
                                    data-toggle='modal'
                                    data-target='#registerModal'
                                    onClick={this.props.closeDialog}
                                >
                                    {
                                        this.props.translate.i18n.COMPONENTS.LOGIN_FORM
                                            .NEW_REGISTRATION
                                    }
                                </a>
                            </div>
                            <button
                                type='submit'
                                className='btn btn-block  btnLogin btnBlueBackground'
                                onClick={() => this.handleLogin()}
                            >
                                {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.LOGIN}
                            </button>
                        </div>
                        <div className={'socialBtns'}>
                            <div className='socialLogin'>
                                {this.props.translate.i18n.COMPONENTS.LOGIN_FORM.LOGIN_VIA_SOCIAL}
                            </div>

                            <div className='googleBtn'>
                                <div
                                    id='g_id_onload'
                                    data-client_id='492173452192-3fvjjof5ie08nl2m2ll3rp9hr0otq9e1.apps.googleusercontent.com'
                                    data-callback='handleCredentialResponse'
                                />
                                <div className='g_id_signin' data-type='icon' />
                            </div>

                            <FacebookLogin
                                appId='2375296566068962'
                                // appId='2926178417399322'
                                version={'4.0'}
                                autoLoad={false}
                                fields='name,email,picture'
                                // onClick={this.responseFacebook2}
                                callback={this.responseFacebook}
                                icon='fa-facebook'
                                size='medium'
                                cssClass={'facebookBtn'}
                                textButton=''
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(LoginWrapper))
