import { observable, action, decorate } from 'mobx'

var defaultLang = 'sk'
var availableLanguages = ['sk']
var languages = {}
var userLang = navigator.language || navigator.language
userLang = userLang.substring(0, 2)

class I18nStore {
    constructor() {
        var i
        for (i = 0; i < availableLanguages.length; i++) {
            languages[availableLanguages[i]] = require(`../assets/i18n/${availableLanguages[i]}`)

            if (availableLanguages[i] === userLang) {
                this.i18n = languages[availableLanguages[i]]
            }
        }
        let zero = false
        for (let prop in this.i18n) {
            // console.log("i18n", prop)
            zero = true
            break
        }
        if (!zero) {
            this.i18n = languages[defaultLang]
        }
    }
    // @observable
    i18n = {}

    /**
     *
     * @param {String} to  //change lang to new lang
     */
    // @action
    changeTo(to) {
        if (languages[to]) {
            this.i18n = languages[to]
        }
    }
}

decorate(I18nStore, {
    i18n: observable,
    changeTo: action
})

const i18n = new I18nStore()
export default i18n
