//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './RatingStars.scss';

//api

//custom components

//material-ui

//other libraries
import uuid from 'uuid';

/**
 * RatingStars
 * @param {Number} numStars number of active stars
 * @param {Boolean} showNumber show number of active stars on the end
 */
class RatingStars extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {

        if(this.props.average) {
            let result = []
            let average = Math.round(this.props.num * 2)/2
            for(let i = 0; i < 5; i++) {
                if( i < average-0.5) {
                    result.push(<i key={i} className={`fa fa-star active ${this.props.yellow ? 'yellowColor':""}`} />)
                }
                else {
                    if(i === Math.round(average) || i > Math.round(average)) {

                        result.push(<i key={i} className='fa fa-star-o' />)
                    }
                    else{
                        result.push(<i key={i} className={`fa fa-star-half-o active ${this.props.yellow ? 'yellowColor':""}`} />)
                    }
                }
                
            }
            return <span>{result}</span>
        }
        else if(this.props.userStars) {
            let result = []
            for(let i = 0; i < 5; i++) {
                if(i < this.props.num) {
                    result.push(<i key={i} className='fa fa-star active' />)
                }
                else{
                    result.push(<i key={i} className='fa fa-star' />)
                }
            }
            return (
                <span>{result}</span>
            )
        }
        else {
            let result = []
            result.push(<i key={uuid()} className='fa fa-star active' />)
            // for(let i = 0; i < 5; i++) {
            //     if(i < this.props.forValue) {
            //         result.push(<i key={i} className='fa fa-star active' />)
            //     }
            //     else{
            //         result.push(<i key={i} className='fa fa-star' />)
            //     }
            // }
            return (
                // <span>{result} x{this.props.num}</span>
                <span>{result}</span>
            )
        }
    }
}

export default inject('internal', 'translate')(observer(RatingStars))
