//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api

//style
import './CartBlockHeader.scss';

//custom components

//material-ui

//other libraries

class CartBlockHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            // clearfix
            <div className='cart-block cart-block-header '>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.PRODUCTS}</span>
                </div>
                <div>
                    <span>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.QUANTITY}</span>
                </div>
                <div className='text-right'>
                    <span>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.PRICE}</span>
                </div>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(CartBlockHeader))
