import React, { Component } from 'react'
import { observer } from 'mobx-react'

// components
import ProductItem from '../../complex/ProductItem/ProductItem'

// stores
import internalStore from '../../../stories/internal'

// styles
import './TopProducts.scss'

class TopProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quickViewDialog: false
        }
    }

    render() {
        return (
            <section className='products'>
                <div className='container'>
                    <header>
                        <div className='row'>
                            <div className='col-md-offset-2 col-md-8 text-center'>
                                <h2 className='title' id={this.props.id} style={this.props.style}>{this.props.title}</h2>
                                {/* <div className="text">
                                <p>Check out our latest collections</p>
                            </div> */}
                            </div>
                        </div>
                    </header>

                    {this.props.topProducts &&
                        internalStore.topProducts.map((item, index) => {
                            return (
                                <div key={index}>
                                    <ProductItem
                                        item={item}
                                        parentClasses={'col-lg-3 col-md-4 col-sm-6 col-xs-12'}
                                        topProduct
                                    />
                                </div>
                            )
                        })}

                    {this.props.fixedSets &&
                        internalStore.fixedSetsData.data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <ProductItem
                                        item={item}
                                        parentClasses={'col-lg-3 col-md-4 col-sm-6 col-xs-12'}
                                        fixedSet
                                    />
                                </div>
                            )
                        })}
                </div>
            </section>
        )
    }
}

export default observer(TopProducts)
