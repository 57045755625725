//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './GPWebPay.scss'
import { api } from '../../utils/api'

//api

//custom components

//material-ui

//other libraries

class GPWebPay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            verifying: true,
            failed: false,
            message: null
        }
    }

    componentDidMount() {
        this.props.internal.setGlobalLoader(false)
        const { internal, ...other } = this.props
        this.props.internal.setRouterData(other)
        // console.log("GPWebPay props", this.props)
        
        this.processPayment();
        window.init()
    }
    
    processPayment = async () => {
        this.setState({
            verifying: true,
            message: this.props.translate.i18n.PAGES.GPWEBPAY.VERIFYING_PAYMENT
        });
        let response = await api({
            url: `gp-web-pay/process` + window.location.search,
            method: 'GET'
        });
        
        if (response.success === true) {
            this.setState({
                verifying: false,
                message: response.data.message
            })
            let slug = 'order-success'
            localStorage.setItem('order_detail', JSON.stringify(response.data.data.order))
            this.props.internal.routerData.history.push(`/${slug}`)
        } else {
            this.setState({
                verifying: false,
                failed: true,
                message: response.data.message
            });
        }
    }
    
    tryAgain = async () => {
        const token = this.props.match.params.token
    
        let response = await api({
            method: 'POST',
            url: `shopping-cart/to-order/${token}`,
            scns_token: localStorage.getItem('scns_token'),
        })
    
        if (response.success === true) {
            window.location.href=response.data.data.gp_wp_url;
        }
    }

    handleGoHome = () => {
        this.props.internal.routerData.history.push('/')
    }

    render() {
        return (
            <div className={'container not-found'}>
                <div className='row'>
                    <div className='col-12 content'>
                        {/* <h1>404</h1> */}
                        <h1>{this.props.translate.i18n.PAGES.GPWEBPAY.PROCESSING}</h1>
                        <p>
                            {this.state.message}
                        </p>
                        {this.state.failed ? (<p>
                            <button
                              onClick={() => this.tryAgain()}
                              className='btn btn-border btnWhiteBackground'
                            >{this.props.translate.i18n.PAGES.GPWEBPAY.TRY_AGAIN}
                            </button>
                        </p>): null}
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(GPWebPay))
