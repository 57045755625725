//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './Delivery.scss'

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions'
import $ from 'jquery'
import scrollTo from 'jquery.scrollto'
import ReactGA from 'react-ga4'

// @rs
// the list of the propNames [keys of state] which should be validated..
const VALIDATEDPROPNAMES = [
  'firstName',
  'lastName',
  'zip',
  'city',
  'email',
  'phone',
  'street',
  'country',
  'note',

  'deliveryFirstName',
  'deliveryLastName',
  'deliveryZip',
  'deliveryCity',
  'deliveryEmail',
  'deliveryPhone',
  'deliveryStreet',
  'deliveryCountry',
]

class Delivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      id_no: '',
      id_tax: '',
      id_vat: '',
      zip: '',
      city: '',
      email: '',
      phone: '',
      streetName: '',
      streetNo: '',
      country: 205,
      street: '',
      terms: false,
      subscribe: false,
      deliveryType: null,
      billing_equals_delivery: true, // fakturacna adresa je rovnaka ako adresa dodania

      deliveryFirstName: '',
      deliveryLastName: '',
      deliveryCompanyName: '',
      deliveryZip: '',
      deliveryCity: '',
      deliveryEmail: '',
      deliveryPhone: '',
      deliveryStreetName: '',
      deliveryStreetNo: '',
      deliveryStreet: '',
      deliveryCountry: 205,
      note: '',
      step: null,

      errors: {
        hasAny: false,

        firstName: false,
        lastName: false,
        companyName: false,
        street: false,
        streetName: false,
        streetNo: false,
        zip: false,
        city: false,
        country: false,
        email: false,
        phone: false,

        id_no: false,
        id_tax: false,
        id_vat: false,

        deliveryFirstName: false,
        deliveryLastName: false,
        deliveryCompanyName: false,
        deliveryZip: false,
        deliveryCity: false,
        deliveryEmail: false,
        deliveryPhone: false,
        deliveryStreetName: false,
        deliveryStreetNo: false,
        deliveryStreet: false,
        deliveryCountry: false,
        note: false,
      },

      showDialogForDeliveryAddress: false,

      btnForNextStep: true, //tlacidlo ktore je disabled pokym sa nevyplnia vsetky polia

      userIsLoged: false,
      loading: false,
    }
  }

  componentDidMount() {
    this.props.internal.setGlobalLoader(false)
    // window.init();
    let userDataFromLocalStorage = JSON.parse(localStorage.getItem('user'))
    if (userDataFromLocalStorage && userDataFromLocalStorage.logged === true) {
      this.setState({
        userIsLoged: true,
        firstName: userDataFromLocalStorage.detail.first_name,
        lastName: userDataFromLocalStorage.detail.last_name,
      })
    }
    const { shoppingCartData } = toJS(this.props.internal)


    this.setState({
      firstName: shoppingCartData.billing_first_name,
      lastName: shoppingCartData.billing_last_name,
      companyName: shoppingCartData.billing_company_name,
      zip: shoppingCartData.billing_zip,
      city: shoppingCartData.billing_city,
      country:
        shoppingCartData.billing_country_id !== null
          ? shoppingCartData.billing_country_id
          : 205,
      email: shoppingCartData.billing_email,
      phone: shoppingCartData.billing_phone,
      street: shoppingCartData.billing_street,
      deliveryFirstName: shoppingCartData.delivery_first_name,
      deliveryLastName: shoppingCartData.delivery_last_name,
      deliveryStreet: shoppingCartData.delivery_street,
      deliveryCity: shoppingCartData.delivery_city,
      deliveryCountry: shoppingCartData.delivery_country_id || 205,
      deliveryZip: shoppingCartData.delivery_zip,
      deliveryEmail: shoppingCartData.delivery_email,
      deliveryPhone: shoppingCartData.delivery_phone,
      deliveryType: shoppingCartData.delivery_type_id,
      // @rs
      id_no: shoppingCartData.billing_id_no,
      id_tax: shoppingCartData.billing_id_tax,
      id_vat: shoppingCartData.billing_id_vat,
      note: shoppingCartData.note,

      billing_equals_delivery: shoppingCartData.billing_equals_delivery,
      // === 1 ? false : true
    })


    setTimeout(() => {
      $(window).scrollTo('.step-wrapper', 500)
    }, 250)
  }

  handleBack = step => {
    this.props.changeStep(step)
  }

  handleNext = step => {
    if (this.state.deliveryType === null) {
      this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.DELIVERY_TYPE_IS_NOT_CHOOSEN)
      return
    }

    let disableNext = false

    for (const NAME of VALIDATEDPROPNAMES) {
      if (this.state.billing_equals_delivery) {
        if (
          !(NAME.includes('delivery') || NAME.includes('delviery')) &&
          !(this.state[NAME] !== null && this.state[NAME] !== '') && 'note' !== NAME
        ) {
          disableNext = true
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [NAME]: true,
            },
          }))
        }
      } else {
        if (!(this.state[NAME] !== null && this.state[NAME] !== '') && 'note' !== NAME) {
          disableNext = true
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [NAME]: true,
            },
          }))
        }
      }
    }

    if (
      this.state.id_no !== null // ICO je
      && this.state.id_no !== '' // vyplnene
      && (this.state.id_tax === null || this.state.id_tax === '')
    ) {
      disableNext = true
      this.setState(prevState => ({
        errors: { ...prevState.errors, id_tax: true },
      }))
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        hasAny: disableNext,
      },
    }))

    if (!disableNext) {
      this.setState({ loading: true })
      Promise.all([this.updateShoppingCartData()]).then(() => {
        this.setState({ loading: false })
        if (this.updatedSuccess) {
          this.props.changeStep(step)
          ReactGA.event('add_shipping_info', {
            currency: 'EUR',
            value: this.props.internal.shoppingCartData.total,
            coupon: this.props.internal.shoppingCartData.coupon ? this.props.internal.shoppingCartData.coupon.code : null,
            items: toJS(this.props.internal.shoppingCartData.items).map(item => ({
              item_id: item.product ? item.product.code : item.static_set.name,
              item_name: item.product ? item.product.name : item.static_set.name,
              affiliation: 'Bekoshop.sk',
              coupon: item.product && item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
              item_category: item.product ? item.product.category_path_alt[0] : null,
              item_category2: item.product && item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
              item_category3: item.product && item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
              price: item.total_price,
            }))
          })
        }
      })
    } else {
      this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.REQUIRED_FIELDS_IS_EMPTY)
      return
    }
  }

  updateShoppingCartData = async () => {

    let params = {
      action: 'attributes',
      billing_first_name: this.state.firstName,
      billing_last_name: this.state.lastName,
      billing_zip: this.state.zip,
      billing_city: this.state.city,
      billing_country_id: this.state.country,
      billing_email: this.state.email,
      billing_phone: this.state.phone,
      billing_street: this.state.street,
      billing_company_name: this.state.companyName,
      delivery_type_id: this.state.deliveryType,
      // @rs
      billing_id_no: this.state.id_no,
      billing_id_tax: this.state.id_tax,
      billing_id_vat: this.state.id_vat,
      note: this.state.note,
    }

    if (!this.state.billing_equals_delivery) {
      params.billing_equals_delivery = this.state.billing_equals_delivery
      params.delivery_first_name = this.state.deliveryFirstName
      params.delivery_last_name = this.state.deliveryLastName
      params.delivery_street = this.state.deliveryStreet
      params.delivery_city = this.state.deliveryCity
      params.delivery_country = this.state.deliveryCountry
      params.delivery_country_id = this.state.deliveryCountry
      params.delivery_zip = this.state.deliveryZip
      params.delivery_email = this.state.deliveryEmail
      params.delivery_phone = this.state.deliveryPhone
    } else {
      params.billing_equals_delivery = this.state.billing_equals_delivery
    }

    let update_data = await api({
      url: 'shopping-cart',
      method: 'PATCH',
      scns_token: localStorage.getItem('scns_token'),
      params: params,
    })

    if (update_data.success === true) {
      // this.setState({loading: false})
      this.props.internal.setShoppingCartData(update_data.data.data)
      this.props.internal.setSnackbarData(update_data.data.message, true)
      this.updatedSuccess = true
    } else {
      this.props.internal.setSnackbarErrorData(update_data.data.message)
      if (update_data && update_data.data && update_data.data.data.errors) {
        if (update_data.data.data.errors.billing_email) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              email: true,
            },
          }))
        }
        if (update_data.data.data.errors.delivery_email) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              deliveryEmail: true,
            },
          }))
        }
        if (update_data.data.data.errors.note) {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              note: update_data.data.data.errors.note,
            },
          }))
        }
      }
    }
  }

  updatedSuccess = false

  handleOpenLoginDialog = () => {
    this.props.internal.loginWrapperRef.handleShowLoginDialog()
  }

  handleIsDeliveryBilling = (field, val) => {
    this.setState({ [field]: !val.target.checked })

    let disableNext = false

    for (const NAME of VALIDATEDPROPNAMES) {
      if (!this.state.billing_equals_delivery) {
        if (
          !(NAME.includes('delivery') || NAME.includes('delviery')) &&
          !(this.state[NAME] !== null && this.state[NAME] !== '') && 'note' !== NAME
        ) {
          disableNext = true
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [NAME]: true,
            },
          }))
        }
      } else {
        if (!(this.state[NAME] !== null && this.state[NAME] !== '') && 'note' !== NAME) {
          disableNext = true
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              [NAME]: true,
            },
          }))
        }
      }
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        hasAny: disableNext,
      },
    }))

    //     , () => {
    //     this.updateShoppingCartData()
    // })
  }

  timeout = null
  handleChangeData = (field, val) => {
    val = val.target.value
    let error = true

    if (field === 'phone' || field === 'deliveryPhone') {
      error = !/^(?=.*[0-9])[+ 0-9]+$/.test(val)
    } else if (field === 'zip' || field === 'deliveryZip') {
      error = !/^(?=.*[0-9])[ 0-9]+$/.test(val)
    }
    // else if (field === 'email' || field === 'deliveryEmail') {
    //     error = !.test(val)
    // }
    else {
      // @rs added error checking, errors are only set to the fields which are not in IGNOREDPROPNAMES array
      error =
        !(val !== null && val !== '' && val.trim().length > 0) && field !== 'note' &&
        VALIDATEDPROPNAMES.includes(field)
    }

    this.setState(prevState => ({
      [field]: val,
      errors: { ...prevState.errors, [field]: error, id_tax: false },
    }))


    let hasAny = false
    if (
      (
        'id_tax' === field // ak riesim DIC
        && this.state.id_no !== null // a ICO je
        && this.state.id_no !== '' // vyplnene
        && ( // a hodnota nie je prazdna
          val === null
          || val === ''
        )
      )
        || // alebo
      (
        'id_no' === field // riesim ICO
        && val !== null // ktore nie je
        && val !== '' // prazdne
        && ( // a id_tax nie je vyplnene
          this.state.id_tax === null || this.state.id_tax === ''
        )
      )
      || (
        ! ['id_tax', 'id_no'].includes(field)
        && this.state.id_no !== null // ICO je
        && this.state.id_no !== '' // vyplnene
        && (this.state.id_tax === null || this.state.id_tax === '')
      )
    ) {
      hasAny = true
      this.setState(prevState => ({
        errors: { ...prevState.errors, id_tax: true },
      }))
    }


    for (const NAME of VALIDATEDPROPNAMES) {
      if (this.state.billing_equals_delivery) {
        // exclude delivery values when checking for errors
        if (
          (NAME !== field && !(NAME.includes('delivery') || NAME.includes('delviery')) && this.state.errors[NAME]) ||
          (NAME === field && !(NAME.includes('delivery') || NAME.includes('delviery')) && error) && 'note' !== NAME
        ) {
          hasAny = true
        }
      } else {
        // check all values for errors
        if ((this.state.errors[NAME] && field !== NAME) || (error && field === NAME) && 'note' !== NAME) {
          hasAny = true
        }
      }
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        hasAny: hasAny,
      },
    }))

    // clearTimeout(this.timeout)
    // this.timeout = setTimeout(()=>{
    //     this.updateShoppingCartData()
    // }, 1000)
  }

  handleChangeDeliveryType = deliveryType => {
    this.setState({ deliveryType: deliveryType })
    // clearTimeout(this.timeout)
    // this.timeout = setTimeout(()=>{
    //     this.updateShoppingCartData()
    // }, 500)
  }

  render() {
    const { shoppingCartData } = toJS(this.props.internal)
    const { countries } = toJS(this.props.internal)

    const { country } = this.state
    return (
      <div className='checkout' id={'cart-delivery-box'}>
        <div className='container'>
          <div className='clearfix'>
            <div className='row upperControls'>
              <div className='col-xs-6 text-right'>
                <a
                  onClick={() => this.handleNext(2)}
                  className={`btn btnBlueBackground ${
                    this.state.errors.hasAny ? 'disabled' : ''
                  }`}
                >
                  {this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.PAYMENTS}{' '}
                  <span className='icon icon-chevron-right'/>
                </a>
              </div>
            </div>
          </div>

          <div className='cart-wrapper'>
            <div className='note-block deliveryNoteBlock'>
              {this.state.loading ? <div className='lds-dual-ring loader'/> : null}
              <div className='row' style={{ opacity: this.state.loading ? 0 : 1 }}>
                <div className='col-md-6'>
                  <div className='login-wrapper'>
                    <div className='white-block'>
                      <div className='login-block'>
                        {this.state.userIsLoged ? (
                          <div className='h4'>
                            {
                              this.props.translate.i18n.PAGES
                                .MY_ACCOUNT.BILLING_ADDRESS
                            }
                          </div>
                        ) : (
                          <div className='h4'>
                            <a
                              className='btn btn-main btn-xs btn-login'
                              data-toggle='modal'
                              data-target='#registerModal'
                            >
                              {
                                this.props.translate.i18n.COMPONENTS
                                  .LOGIN_FORM.NEW_REGISTRATION
                              }
                            </a>
                            <a
                              onClick={this.handleOpenLoginDialog}
                              className='btn btn-main btn-xs btn-login pull-right'
                            >
                              {
                                this.props.translate.i18n.COMPONENTS
                                  .LOGIN_FORM.LOGIN
                              }
                            </a>
                          </div>
                        )}

                        <hr/>

                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.firstName || ''}
                                className={`form-control ${
                                  this.state.errors.firstName
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.FIRST_NAME + ' *'
                                }
                                required
                                onChange={val =>
                                  this.handleChangeData(
                                    'firstName',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.lastName || ''}
                                className={`form-control ${
                                  this.state.errors.lastName
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.LAST_NAME + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'lastName',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.companyName || ''}
                                className={`form-control ${
                                  this.state.errors.companyName
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.COMPANY_NAME
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'companyName',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          {/* @rs added ICO, DIC, DPH */}
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.id_no || ''}
                                className={`form-control ${
                                  this.state.errors.id_no
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n
                                    .COMPONENTS.DELIVERY_FORM
                                    .ICO
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'id_no',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.id_tax || ''}
                                className={`form-control ${
                                  this.state.errors.id_tax
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n
                                    .COMPONENTS.DELIVERY_FORM
                                    .DIC
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'id_tax',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.id_vat || ''}
                                className={`form-control ${
                                  this.state.errors.id_vat
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n
                                    .COMPONENTS.DELIVERY_FORM
                                    .DPH
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'id_vat',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.street || ''}
                                className={`form-control ${
                                  this.state.errors.street
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.STREET_NAME + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'street',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-4'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.zip || ''}
                                className={`form-control ${
                                  this.state.errors.zip
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.ZIP + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'zip',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-8'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.city || ''}
                                className={`form-control ${
                                  this.state.errors.city
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.CITY + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'city',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className='col-md-12 countries'>
                            <div className='form-group'>
                              <select
                                className={`classic2 ${
                                  this.state.errors.country
                                    ? 'invalid'
                                    : ''
                                }`}
                                onChange={val =>
                                  this.handleChangeData(
                                    'country',
                                    val,
                                  )
                                }
                                defaultValue={country || 205}
                              >
                                {countries.map((country, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={country.id || ''}
                                      // @rs
                                      // setting selected attribute is not react proprietary
                                      // selected={
                                      //     this.state
                                      //         .country ===
                                      //     country.id
                                      //         ? true
                                      //         : false
                                      // }
                                    >
                                      {country.name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.email || ''}
                                className={`form-control ${
                                  this.state.errors.email
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.EMAIL + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'email',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <div className='form-group'>
                              <input
                                type='text'
                                value={this.state.phone || ''}
                                className={`form-control ${
                                  this.state.errors.phone
                                    ? 'invalid'
                                    : ''
                                }`}
                                placeholder={
                                  this.props.translate.i18n.GLOBAL
                                    .CONTACT.PHONE + ' *'
                                }
                                onChange={val =>
                                  this.handleChangeData(
                                    'phone',
                                    val,
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <hr/>
                            <span className='checkbox'>
                                                            <input
                                                              type='checkbox'
                                                              id='billing_equals_delivery'
                                                              defaultChecked={
                                                                !this.state
                                                                  .billing_equals_delivery ||
                                                                !shoppingCartData.billing_equals_delivery
                                                              }
                                                              onChange={val =>
                                                                this.handleIsDeliveryBilling(
                                                                  'billing_equals_delivery',
                                                                  val,
                                                                )
                                                              }
                                                            />
                                                            <label htmlFor='billing_equals_delivery'>
                                                                {
                                                                  this.props.translate.i18n
                                                                    .COMPONENTS.DELIVERY_FORM
                                                                    .BILLING_IS_DELIVERY
                                                                }
                                                            </label>
                                                        </span>
                          </div>
                        </div>
                        {!this.state.billing_equals_delivery ? (
                          //${this.state.billing_equals_delivery ? 'hidden': 'show'}
                          <div
                            className={`row deliveryAddress ${
                              this.state.billing_equals_delivery
                                ? 'showAddress'
                                : 'hiddeAddress'
                            }`}
                          >
                            <div className={'col-md-12'}>
                              <div className='h4 deliveryAddressTitle'>
                                {
                                  this.props.translate.i18n.PAGES
                                    .MY_ACCOUNT.DELIVERY_ADDRESS
                                }
                              </div>
                              <hr/>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state
                                      .deliveryFirstName || ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryFirstName
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT
                                      .FIRST_NAME + ' *'
                                  }
                                  required
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryFirstName',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state
                                      .deliveryLastName || ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryLastName
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT
                                      .LAST_NAME + ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryLastName',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state.deliveryStreet ||
                                    ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryStreet
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT
                                      .STREET_NAME + ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryStreet',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className='col-md-4'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state.deliveryZip || ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryZip
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT.ZIP +
                                    ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryZip',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className='col-md-8'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state.deliveryCity ||
                                    ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryCity
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT.CITY +
                                    ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryCity',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-12 countries'>
                              <div className='form-group'>
                                <select
                                  className={`classic2 ${
                                    this.state.errors
                                      .deliveryCountry
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryCountry',
                                      val,
                                    )
                                  }
                                  defaultValue={this.state.deliveryCountry || '205'}
                                >
                                  {countries.map(
                                    (country, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={country.id}
                                          // @rs
                                          // setting selected attribute is not react proprietary
                                          // selected={
                                          //     this.state
                                          //         .deliveryCountry ===
                                          //     country.id
                                          //         ? true
                                          //         : false
                                          // }
                                        >
                                          {country.name}
                                        </option>
                                      )
                                    },
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state.deliveryEmail ||
                                    ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryEmail
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT.EMAIL +
                                    ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryEmail',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='text'
                                  value={
                                    this.state.deliveryPhone ||
                                    ''
                                  }
                                  className={`form-control ${
                                    this.state.errors
                                      .deliveryPhone
                                      ? 'invalid'
                                      : ''
                                  }`}
                                  placeholder={
                                    this.props.translate.i18n
                                      .GLOBAL.CONTACT.PHONE +
                                    ' *'
                                  }
                                  onChange={val =>
                                    this.handleChangeData(
                                      'deliveryPhone',
                                      val,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='white-block'>
                    <div className='h4'>
                      {
                        this.props.translate.i18n.COMPONENTS.DELIVERY_FORM
                          .CHOOSE_DELIVERY
                      }
                    </div>

                    <hr/>

                    {shoppingCartData.available &&
                    shoppingCartData.available.delivery_types &&
                    shoppingCartData.available.delivery_types.map((item, index) => {
                        return (
                          <span className='checkbox' key={index}>
                            <input
                              type='radio'
                              id={`deliveryTypeOption-${item.id}`}
                              name={'deliveryType'}
                              onChange={() => this.handleChangeDeliveryType(item.id)}
                              checked={this.state.deliveryType === item.id}
                            />
                            <label
                              htmlFor={`deliveryTypeOption-${item.id}`}
                              style={{ width: '100%' }}
                            >
                                {item.name}{' '}
                              <strong className={'pull-right'}>
                                {shoppingCartData.products_total_with_vat > item.free_from ? formatMoney(0) : formatMoney(item.price)}
                              </strong>
                              <p
                                className={
                                  'deliveryTypeDescription'
                                }
                              >
                                {item.description}
                              </p>
                              {/* <ul className={'weightCategories'}>
                                    {item.weight_categories && item.weight_categories.map((weightCategoty, indexWeight)=> {
                                    return (
                                        <li key={indexWeight}>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.WEIGHT_FROM}{weightCategoty.weight_from} kg - {formatMoney(weightCategoty.price)}</li>
                                    );
                                })}
                                </ul> */}
                            </label>
                          </span>
                        )
                      },
                    )}

                    <hr />

                    <div className='form-group'>
                      <textarea
                        rows={3}
                        value={this.state.note || ''}
                        className={`form-control${
                          this.state.errors.note
                            ? ' invalid'
                            : ''
                        }`}
                        placeholder={
                          this.props.translate.i18n.GLOBAL
                            .CONTACT.NOTE
                        }
                        onChange={val =>
                          this.handleChangeData(
                            'note',
                            val,
                          )
                        }
                      />
                      {this.state.errors.note && (<div>
                        <small style={{color: 'red'}}>{this.state.errors.note}</small>
                      </div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='clearfix'>
            <div className='row'>
              <div className='col-xs-6'>
                <a
                  onClick={() => this.handleBack(0)}
                  className='btn btnWhiteBackground'
                >
                  <span className='icon icon-chevron-left'/>{' '}
                  {
                    this.props.translate.i18n.COMPONENTS.DELIVERY_FORM
                      .BACK_TO_CART
                  }
                </a>
              </div>
              <div className='col-xs-6 text-right'>
                {/* {this.state.billing_equals_delivery ? ( */}
                <a
                  onClick={() => this.handleNext(2)}
                  className={`btn btnBlueBackground ${
                    this.state.errors.hasAny ? 'disabled' : ''
                  }`}
                >
                  {this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.PAYMENTS}{' '}
                  <span className='icon icon-chevron-right'/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('internal', 'translate')(observer(Delivery))
