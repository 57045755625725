import configurations from './configurations';

export var api = (obj) => {
    let _response = {
        success: false,
        status: 0,
        data: null,
        error: null,
    }

    return new Promise((resolve) => {
        let finalUrl = ""
        if(obj.postman) {
            finalUrl = `${configurations.base_url_postman}${obj.url}`;
        }
        else {
            finalUrl = `${configurations.base_url}${obj.url}`;
        }

        // console.log("final url", finalUrl)

        let headers = {
            method: obj.method,
            headers: {
              "Accept": 'application/json',
            },
        }
        
        if (!obj.formData) {
            headers.headers['Content-Type'] = 'application/json'
        }

        if(obj.scns_token) {
            headers.headers["scns-token"] = obj.scns_token //pre neprihlaseneho pouzivatela
        }

        // [HEADER] Authorization token
        let user = JSON.parse(localStorage.getItem('user'))
        if(user && user.logged === true) {
            headers.headers.Authorization = "Bearer " + user.acces_token
        }
        else if(obj.authorization) {
            headers.headers.Authorization = "Bearer " + obj.authorization
        }

        if (obj.params) {
            // console.log("obj params", obj.params)
            if(obj.method === 'GET') {
                let queryString = "?";
                let haveParam = false;
                let params = obj.params;

                for(let param in params) {
                    haveParam = true;

                    // console.log("param", param)

                    if(param === 'filter') {
                        if(Array.isArray(params.filter)) {
                           
                            for(let f in params.filter) {
                                queryString += 'filters' + '[' + Object.keys(params.filter[f])[0] + '][]=' + Object.values(params.filter[f])[0] + "&"
                            }
                        }
                    }
                    else if(param === 'order') {
                        for(let f in params.order) {
                            queryString += 'order' + '[0][' + Object.keys(params.order[f])[0] + ']=' + Object.values(params.order[f])[0] + "&"
                            queryString += 'order' + '[0][' + Object.keys(params.order[f])[1] + ']=' + Object.values(params.order[f])[1] + "&"
                        }
                    }
                    else if(param === 'page') {
                        queryString += 'page[size]=' + params[param] + "&"
                    }
                    

                    // if(params.filter) {
                    //     if(Array.isArray(params.filter)) {
                    
                    //         for(let f in params.filter) {
                    //             queryString += 'filters' + '[' + Object.keys(params.filter[f])[0] + '][]=' + Object.values(params.filter[f])[0] + "&"
                    //         }
                    //     }
                    // }
                    else {
                        queryString += param + "=" + params[param] + "&"
                    }
                }

                if (haveParam) {
                    queryString = queryString.slice(0,-1)
                    finalUrl = `${finalUrl}${queryString}`
                }
            }
            
            if (obj.method === 'POST' || obj.method === 'DELETE' || obj.method === 'PATCH' || obj.method === 'PUT') {
                headers.body = JSON.stringify(obj.params)
            }
        }
    
        if (obj.formData) {
            let formData = new FormData()
            for (let name in obj.params) {
                formData.append(name, obj.params[name])
            }
        
            headers.body = formData
        }

        if(obj.useNextPage) {
            finalUrl = `${obj.nextPageUrl}`
            // console.log("FINAL URL", finalUrl)
        }

        fetch(finalUrl, headers)
        .then( (response) =>  {
            // handle success
            response.json().then(responseJson => {
                _response.status = response.status;
                _response.data = responseJson;

                if (response.status === 200)  {
                    _response.success = true;
                }
                else if(response.status === 401 && localStorage.getItem('user')) {
                    localStorage.removeItem('user')
                    if (!obj.hasOwnProperty('redirectOn401') || true === obj.redirectOn401) {
                        window.location.replace("/")
                    }
                }
                else {
                    _response.error = {
                        message: responseJson.message,
                    }
                }
    
                resolve(_response)
            });
        })
        .catch( (error) => {
            _response.status = error.status;
            _response.error = error.error;
            resolve(_response)
            // handle error
        })
    });
}

// import { API_get } from './globals';

// class API {
//     getBanner() {
//         return new Promise ((resolve, reject) => {
//             API_get('/lists/banners').then(res => {
//                 console.log("res", res)
//                 resolve(res);
//             }).catch(err => {
//                 reject(err.message);
//             });
//         });
//     }
// }

// export default new API();
