//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//style
import './InfoBox.scss';

//api

//custom components

//material-ui

//other libraries

class InfoBox extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={`info-box parameters ${this.props.className ? this.props.className : ''}`}>
                <span className={'parameterName'}><strong>{this.props.title}</strong></span>
                <div><span>{this.props.value}</span></div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(InfoBox));
