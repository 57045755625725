import { observable, action, decorate, toJS } from 'mobx'
import { api } from '../utils/api'
import { isEqual } from 'lodash'

const TIMEOUT_MSG = 5000
const TIMEOUT_CLEAR_MSG = 6000
const TIMEOUT_LOADER = 50

class InternalStore {
    timeoutLoader = null

    globalLoader = false
    setGlobalLoader(value) {
       if (value === false) {
            clearTimeout(this.timeoutLoader)
            this.timeoutLoader = setTimeout(() => {
                this.globalLoader = value
                if (value === false) {
                    window.init()
                }
            }, TIMEOUT_LOADER)
        } else {
            this.globalLoader = value
            if (value === false) {
                window.init()
            }
        }
    }

    showHideQucikView = false
    setShowHideQuickViewProduct() {
        // this.showHideQucikView = !this.showHideQucikView
        this.showHideQucikView = false
    }

    routerData = {
        history: {},
        location: {},
        match: {}
    }
    setRouterData(data) {
        if (!isEqual(this.routerData, data)) {
            this.routerData = data
        }
    }

    compareProducts = []
    setCompareProducts(idProduct) {
        let chkeckCompareProducts = this.compareProducts.includes(idProduct)
        if (!chkeckCompareProducts) {
            this.compareProducts.push(idProduct)
        } else {
        }
    }

    clearCompareProducts() {
        this.compareProducts = []
    }

    banners = []
    setBannersData(data) {
        this.banners = data
    }

    topProducts = []
    setTopProductsData(data) {
        this.topProducts = data
    }

    topMenu = []
    setTopMenu(data) {
        this.topMenu = data
    }

    bottomMenu = []
    setBottomMenu(data) {
        this.bottomMenu = data
    }

    menuItems = null
    loadMenuItems = async () => {
        // let menuItems = localStorage.getItem('menuItems')
        if (this.menuItems) {
            // try {
            // this.menuItems = JSON.parse(menuItems)
            return await Promise.resolve()
            // } catch (e) {}
        }

        let load_data = await api({
            method: 'GET',
            url: 'menu'
        })

        if (load_data.success === true) {
            this.topMenu = load_data.data.data.filter(menuItem => menuItem.menu_position === 'top')
            this.bottomMenu = load_data.data.data.filter(
                menuItem => menuItem.menu_position === 'bot'
            )
            this.menuItems = load_data.data.data
        }
    }

    footerData = null
    loadFooterData = async () => {
        let footerData = localStorage.getItem('footerData')
        let footerDataSavedAt = localStorage.getItem('footerDataSavedAt')
        if (footerData) {
            try {
                let lastSave = new Date(JSON.parse(footerDataSavedAt))
                if ((new Date() - lastSave) / 60000 < 5) {
                    this.footerData = JSON.parse(footerData)
                    return await Promise.resolve()
                }
            } catch (e) {}
        }
        
        if (document.body.dataset.hasOwnProperty('settings')) {
            const settings = JSON.parse(document.body.dataset.settings);
            localStorage.setItem('footerData', JSON.stringify(settings.data))
            localStorage.setItem('footerDataSavedAt', JSON.stringify(new Date()))
            this.footerData = settings.data
            return Promise.resolve()
        }

        let load_data = await api({
            method: 'GET',
            url: 'settings'
        })

        if (load_data.success === true) {
            localStorage.setItem('footerData', JSON.stringify(load_data.data.data))
            localStorage.setItem('footerDataSavedAt', JSON.stringify(new Date()))
            this.footerData = load_data.data.data
        }
    }

    setFooterData(data) {
        this.footerData = data
    }

    getFooterData(data) {
        this.footerData = data
    }

    productDetail = null
    setProductDetail(data) {
        this.productDetail = data
    }

    productsForCategory = null
    setProductsForCategory(data) {
        this.productsForCategory = data
    }

    articleData = null
    setArticleData(data) {
        this.articleData = data
    }

    fixedSetsData = null
    setFixedSets(data) {
        this.fixedSetsData = data
    }

    shoppingCartData = null
    setShoppingCartData(data) {
        this.shoppingCartData = data
    }

    isLoginOpen = false
    setIsLoginOpen(state) {
        this.isLoginOpen = state
    }

    loginWrapperRef = null
    setLoginWrapperRef(state) {
        this.loginWrapperRef = state
    }

    productDetailRef = null
    setProductDetailRef(state) {
        this.productDetailRef = state
    }

    customSwitcherRef = null
    setCustomSwitcherRef(state) {
        this.customSwitcherRef = state
    }

    sliderRef = null
    setSliderRef(value) {
        this.sliderRef = value
    }

    user = {
        logged: false,
        acces_token: null,
        detail: []
    }
    setUserToken(token) {
        this.user.acces_token = token
    }

    setUserData(data) {
        this.user.logged = data.logged
        this.user.detail = data.detail
    }

    setAllUserData(data) {
        this.user = data
    }

    tabAboutMe = null
    setTabAboutMe(data) {
        this.tabAboutMe = data
    }

    tabMyReviews = null
    setTabMyReviews(data) {
        this.tabMyReviews = data
    }

    tabWatchDog = null
    setTabWatchDog(data) {
        this.tabWatchDog = data
    }

    filterIsActivated = false
    setFilterIsActivated(state) {
        this.filterIsActivated = state
    }

    snackbarData = {
        message: '',
        open: false,
        error: false
    }
    setSnackbarData(message, isOpen, action, error = false) {
        this.snackbarData = {
            message: message,
            open: isOpen,
            action: action,
            error: error
        }
        this.resetSnackbarData()
        this.resetSnackbarText()
    }
    setSnackbarErrorData(message) {
        this.setSnackbarData(message, true, null, true)
    }

    timeout = null
    resetSnackbarData() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.setSnackbarData(this.snackbarData.message, false)
        }, TIMEOUT_MSG)
    }

    timeout2 = null
    resetSnackbarText() {
        clearTimeout(this.timeout2)
        this.timeout2 = setTimeout(() => {
            this.snackbarData.message = ''
        }, TIMEOUT_CLEAR_MSG)
    }

    reviewData = null
    setReviewData(data) {
        this.reviewData = data
    }

    countries = null
    setCountries(data) {
        this.countries = data
    }

    allAboutListProducts = null
    setAllAboutListProducts(data) {
        this.allAboutListProducts = data
    }

    updateAllAboutListProducts(data) {
        let tmpListProducts = this.allAboutListProducts
        for (let i in data) {
            if (i === 'data') {
                let oldData = this.allAboutListProducts.data
                let newData = oldData.concat(data[i])
                tmpListProducts.data = newData
            } else {
                tmpListProducts[i] = data[i]
            }
        }
        this.allAboutListProducts = tmpListProducts
        this.productsForCategory.push(...data.data)
        // this.setProductsForCategory(toJS(this.allAboutListProducts.data))
    }

    watchDogData = null
    setWatchDogData(data) {
        this.watchDogData = data
    }

    tabMyOrders = null
    setTabMyOrders(data) {
        this.tabMyOrders = data
    }

    orderDetailId = null
    setOrderDetailId(id) {
        this.orderDetailId = id
    }
}

decorate(InternalStore, {
    globalLoader: observable,
    setGlobalLoader: action,
    // showHideQucikView: observable,
    // setShowHideQuickViewProduct: action,
    // porovnanie produktov
    compareProducts: observable,
    setCompareProducts: action,
    clearCompareProducts: action,
    // bannere
    banners: observable,
    setBannersData: action,
    // menus
    topMenu: observable,
    bottomMenu: observable,
    menuItems: observable,
    setTopMenu: action,
    setBottomMenu: action,
    // top products
    topProducts: observable,
    setTopProductsData: action,
    // footer data
    footerData: observable,
    setFooterData: action,
    // product detail
    productDetail: observable,
    setProductDetail: action,
    // zoznam produktov, ktory patria do niejakej kategorii
    productsForCategory: observable,
    setProductsForCategory: action,
    // article data
    articleData: observable,
    setArticleData: action,
    // fixne sety data
    fixedSetsData: observable,
    setFixedSets: action,
    // nakupny kosik
    shoppingCartData: observable,
    setShoppingCartData: action,
    // login dialog
    isLoginOpen: observable,
    setIsLoginOpen: action,
    // login wrapper ref
    loginWrapperRef: observable,
    setLoginWrapperRef: action,
    // user login
    user: observable,
    setUserToken: action,
    setUserData: action,
    setAllUserData: action,
    // ucet prihlaseneho uzivatela
    tabAboutMe: observable,
    setTabAboutMe: action,
    tabMyReviews: observable,
    setTabMyReviews: action,
    // aby sa nezacyklilo
    filterIsActivated: observable,
    setFilterIsActivated: action,
    // snackbar
    snackbarData: observable,
    setSnackbarData: action,
    setSnackbarErrorData: action,
    // recenzie
    reviewData: observable,
    setReviewData: action,
    // countries
    countries: observable,
    setCountries: action,
    // vsetky data o liste produktoch
    allAboutListProducts: observable,
    setAllAboutListProducts: action,
    // referencia na custom switcher
    customSwitcherRef: observable,
    setCustomSwitcherRef: action,
    // strazny pes
    setWatchDogData: action,
    watchDogData: observable,
    // moj-ucet/strazny pes
    setTabWatchDog: action,
    tabWatchDog: observable,
    // moj-ucet/objednavky
    setTabMyOrders: action,
    tabMyOrders: observable,
    // order detail id
    setOrderDetailId: action,
    orderDetailId: observable
})

const internalStore = new InternalStore()
export default internalStore
