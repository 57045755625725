//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//api

//custom components
import ProductItem from '../../complex/ProductItem/ProductItem';

//material-ui

//other libraries

class ProductsAlternativeRelated extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        const { products } = this.props.data
        const { wrapperClasses } = this.props
        return (
            <div className={`${wrapperClasses} products`}>
                <div className='row'>
                {products ?
                    products.map((product, index) => {
                        return (
                            <div key={index} >
                                <ProductItem
                                  item={product}
                                  parentClasses={'col-lg-3 col-md-4 col-sm-6 col-xs-12'}
                                  fixedSet={false === product.hasOwnProperty('code')}
                                />
                                {/*<ProductQuickViewDialog {...product} routerData={this.props.routerData} />*/}
                            </div>
                        )
                    })
                    :
                    null
                }
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(ProductsAlternativeRelated));
