// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../../utils/api'

// components
import CartBlockItem from '../../complex/CartBlockItem/CartBlockItem'
import CartBlockFooter from '../../complex/CartBlockFooter/CartBlockFooter'

// i18n
import t from '../../../stories/i18n'

// stores
import internalStore from '../../../stories/internal'

// styles
import './CartWrapper.scss'

class CartWrapper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            updating: false
        }
    }

    componentDidMount() {
        // setTimeout(() => this.getShoppingCartItems(), 3000);
        // this.timer = setInterval(()=> this.getShoppingCartItems(), 60000) // minuta
        window.addEventListener('click', this.handleClickOutsideOfCartWrapper)
    }

    cnt = 0
    handleClickOutsideOfCartWrapper = e => {
        if (this.props.isOpen === true) {
            this.cnt++
            if (!document.getElementById('cartWrapper').contains(e.target)) {
                if (this.cnt % 2 === 0) {
                    this.props.closeDialog()
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutsideOfCartWrapper)
    }

    getShoppingCartItems = async () => {
        let load_shopping_cart = await api({
            scns_token: localStorage.getItem('scns_token'),
            url: 'shopping-cart',
            method: 'GET'
        })

        if (load_shopping_cart.success === true) {
            internalStore.setShoppingCartData(load_shopping_cart.data.data)
            this.setState({ loadingShoppingCart: false })
            localStorage.setItem('scns_token', load_shopping_cart.data.data.scns_token)
            internalStore.loginWrapperRef &&
                internalStore.loginWrapperRef.rerender()
        }
    }

    handleGoToShop = () => {
        internalStore.setGlobalLoader(true)
        internalStore.routerData.history.push(`/nakupny-kosik/produkty`)
        internalStore.routerData.match.params.step = 'produkty'

        this.props.closeDialog()
    }

    updateCart = () => {
        this.setState({ updating: !this.state.updating })
    }

    render() {
        return this.props.loadingShoppingCart ? null : (
            <div
                className={`cart-wrapper ${this.props.isOpen ? 'open' : ''} ${
                    internalStore.shoppingCartData.items.length === 0 ? 'empty' : ''
                }`}
                id={'cartWrapper'}
            >
                {internalStore.shoppingCartData.items.length === 0 ? (
                    <div className='checkout'>
                        <div className={'clearfix'}>
                            <div className='row'>
                                <span>
                                    {
                                        t.i18n.PAGES.SHOPPING_CART
                                            .EMPTY_SHOPPING_CART
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='checkout'>
                        {/* {this.state.updating ?  <div className='lds-dual-ring updateShoppingCart' /> : null} */}
                        {/* style={{opacity: `${this.state.updating ? 0 : 1}`}} */}
                        <div className='clearfix'>
                            <div className={'row'}>
                                <CartBlockItem
                                    items={internalStore.shoppingCartData.items}
                                    updateCart={this.updateCart}
                                />
                            </div>

                            <hr />

                            <CartBlockFooter
                                total_with_vat={
                                    internalStore.shoppingCartData.products_total_with_vat
                                }
                                total_without_vat={internalStore.shoppingCartData.products_total}
                                shipping={false}
                                titleTotal={true}
                                gifts={false}
                            />

                            <div className='cart-block-buttons clearfix'>
                                <div className='row'>
                                    {this.props.hideBackToProducts ? null : (
                                        <div className='col-xs-6'>
                                            <a className='btn btn-clean-dark'>
                                                {
                                                    t.i18n.PAGES.SHOPPING_CART
                                                        .BACK_TO_SHOPPING
                                                }
                                            </a>
                                        </div>
                                    )}
                                    <div
                                        className={`${
                                            this.props.hideBackToProducts ? 'col-xs-12' : 'col-xs-6'
                                        } text-right`}
                                    >
                                        <a
                                            onClick={() => this.handleGoToShop()}
                                            className='btn btnBlueBackground'
                                        >
                                            <span className='icon icon-cart' />{' '}
                                            {t.i18n.PAGES.SHOPPING_CART.CHECKOUT}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
export default observer(CartWrapper)
