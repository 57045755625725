//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './MyAccount.scss'

//api
import { api } from '../../utils/api'

//custom components
import Tabs from '../../components/complex/Tabs/Tabs'

//material-ui

//other libraries

const STEPS = ['osobne-udaje', 'objednavky', 'strazny-pes', 'recenzie']

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            activeTab: null
        }
    }

    componentDidMount() {
        let userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
        if (userFromLocalStorage && userFromLocalStorage.logged === true) {
            const { internal, ...other } = this.props
            this.props.internal.setRouterData(other)

            // this.loadUserData()

            // console.log("MY ACCOUNT", this.props)
            let paramFromUrl = this.props.match.params.step
            let parameterNotExist = true
            for (let i in STEPS) {
                if (STEPS[i] === paramFromUrl) {
                    this.setState({ activeTab: STEPS[i] })
                    parameterNotExist = false
                }
            }

            if (parameterNotExist) {
                this.setState({ activeTab: STEPS[0] })
            }
        } else {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.activeTab !== this.props.match.params.step &&
            STEPS.indexOf(this.props.match.params.step) > -1
        ) {
            this.setState({ activeTab: this.props.match.params.step })

            const { internal, ...other } = this.props
            this.props.internal.setRouterData(other)
        }
    }

    componentWillReceiveProps() {
        // console.log("MY ACCOUNT: componentWillReceiveProps")
        let userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
        if (userFromLocalStorage && userFromLocalStorage.logged === true) {
            const { internal, ...other } = this.props
            this.props.internal.setRouterData(other)

            // this.loadUserData()
        } else {
            this.props.history.push('/')
        }
    }

    loadUserData = async () => {
        let load_user_data = await api({
            method: 'GET',
            url: 'user'
        })

        // console.log("load_user_data", load_user_data)
        if (load_user_data.success === true) {
            this.setState({ loading: false })
            this.props.internal.setGlobalLoader(false)
        }
    }

    render() {
        let routerData = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }
        return (
            // this.state.loading ? null :
            <div className='myAccount'>
                <section className='main-header'>
                    <header>
                        <div className='container'>
                            <h1 className='h2 title'>
                                {this.props.translate.i18n.PAGES.MY_ACCOUNT.MY_ACCOUNT_TITLE}
                            </h1>
                        </div>
                    </header>
                </section>
                <section className='tabs'>
                    <div className='main'>
                        <div className='container'>
                            <div className={'row'}>
                                <Tabs
                                    tabs={[
                                        {
                                            id: STEPS[0],
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .PERSON_DETAILS,
                                            active: this.state.activeTab === STEPS[0]
                                        },
                                        {
                                            id: STEPS[1],
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_ORDERS,
                                            active: this.state.activeTab === STEPS[1]
                                        },
                                        {
                                            id: STEPS[2],
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_FOLLOWED_PRODUCTS,
                                            active: this.state.activeTab === STEPS[2]
                                        },
                                        {
                                            id: STEPS[3],
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_REVIEWS,
                                            active: this.state.activeTab === STEPS[3]
                                        }
                                    ]}
                                    content={[
                                        {
                                            id: STEPS[0],
                                            editPersonData: true,
                                            title: null,
                                            active: this.state.activeTab === STEPS[0]
                                        },
                                        {
                                            id: STEPS[1],
                                            myOrders: true,
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_ORDERS,
                                            active: this.state.activeTab === STEPS[1]
                                        },
                                        {
                                            id: STEPS[2],
                                            watchDog: true,
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_FOLLOWED_PRODUCTS,
                                            active: this.state.activeTab === STEPS[2]
                                        },
                                        {
                                            id: STEPS[3],
                                            title: this.props.translate.i18n.PAGES.MY_ACCOUNT
                                                .MY_REVIEWS,
                                            reviews: true,
                                            active: this.state.activeTab === STEPS[3]
                                        }
                                    ]}
                                    routerData={routerData}
                                    updateUrl
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(MyAccount))
