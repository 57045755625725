// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// stores
import internalStore from '../../../stories/internal'

// style
import './Navigation.scss'

class NavigationMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            logo: '',
            mini_banner_image: null,
            mini_banner_url: null,
            mini_banner_2_image: null,
            mini_banner_2_url: null,
        }
    }

    ref = null

    handleSubMenuDropdown = e => {
        e.preventDefault()

        this.ref.parentElement.parentElement.classList.toggle('expanded')
    }

    componentDidMount() {
        // console.group('NavigationMain')
        // console.log('componentDidMount props:', this.props)
        // console.groupEnd()
        this.loadMenu()
        this.loadSettings()
    }

    loadSettings = async () => {
        // let load_data = await api({
        //     method: 'GET',
        //     url: "settings",
        // })
        //
        // if(load_data.success === true) {
        //     // console.log("load_data",load_data)
        //     this.setState({logo: load_data.data.data.shop.email_asset_email_logo})
        // }
        await internalStore.loadFooterData()

        this.setState({
            logo: internalStore.footerData.shop.email_asset_email_logo,
            mini_banner_image: internalStore.footerData.shop.asset_mini_banner_image,
            mini_banner_url: internalStore.footerData.shop.mini_banner_url,
            mini_banner_2_image: internalStore.footerData.shop.asset_mini_banner2_image,
            mini_banner_2_url: internalStore.footerData.shop.mini_banner_2_url,
        })
    }

    loadMenu = async () => {
        // let load_data = await api({
        //     method: 'GET',
        //     url: "menu",
        // })
        //
        // // console.log("menu", load_data)
        // let topMenu = []
        // let bottomMenu = []
        // if(load_data.success === true) {

        // await this.props.internal.loadMenuItems();

        // topMenu = this.props.menuItems.filter(menuItem => menuItem.menu_position === 'top');
        // bottomMenu = this.props.menuItems.filter(menuItem => menuItem.menu_position === 'bot');
        // this.props.internal.setBottomMenu(bottomMenu);
        // this.props.internal.setTopMenu(topMenu);
        this.setState({ loading: false })
        // console.log("top menu: ",require('mobx').toJS(this.props.internal.topMenu))
        // window.init()
        // }
    }

    onClickHome = () => {
        // console.log("NavigationMain", this.props);
        if (internalStore.routerData.match.path !== '/') {
            internalStore.setGlobalLoader(true)
            internalStore.routerData.history.push('/')
            document.getElementById('floatingMenu').classList.remove('expanded')
        }
        // this.props.internal.routerData.history.goBack();
    }

    goToCategory = slug => {
        // this.props.internal.routerData.history.push(`/kategoria/${slug}`)
        // this.props.internal.routerData.match.params.slug = slug;
    }

    goTo = slug => {
        document.getElementById('floatingMenu').classList.remove('expanded')

        if (internalStore.routerData.location.pathname === `/${slug}`) {
            return false
        }

        internalStore.routerData.history.push(`/${slug}`)
        // this.props.internal.routerData.match.params = ``;
        // slug.split('/')

        // this.props.internal.setGlobalLoader(true);
        // console.log("NavigationMain SLUG", slug)

        // let paramsArray = slug.split("/")
        // console.log("paramsArray", paramsArray, this.props)
        // let matchPrams = this.props.internal.routerData.match.params
        // if (this.props.internal.routerData.location.pathname === `/${slug}`)
        // let indexParamsArray = 0
        // for(let i in matchPrams) {
        //     matchPrams[i] = undefined
        //     if(indexParamsArray < paramsArray.length) {
        //         matchPrams[i] = paramsArray[indexParamsArray]
        //         indexParamsArray++;
        //     }
        // }
        // console.log("matchParams", matchPrams)
        // this.props.internal.routerData.match.params = matchPrams
        // this.props.internal.routerData.match.url = slug;
        // this.props.internal.routerData.location.pathname = slug;
        // this.props.internal.routerData.history.push(`/${slug}`)
        // window.location.href = `/${slug}`;
        // document.getElementById('floatingMenu').classList.remove('expanded')
        // window.location.reload()
    }

    goToContact = alias => {
        internalStore.setGlobalLoader(true)
        let matchPrams = internalStore.routerData.match.params
        for (let i in matchPrams) {
            matchPrams[i] = undefined
        }
        matchPrams.alias = alias
        internalStore.routerData.match.params = matchPrams
        internalStore.routerData.history.push(`/${alias}`)
        document.getElementById('floatingMenu').classList.remove('expanded')
        // window.location.reload()
    }

    rennderTopMenu = () => {
        // console.log("topMenu", topMenu)

        let arrayForRender = []

        for (let menuItem in internalStore.topMenu) {
            // console.log("topMenu[menuItem]",topMenu[menuItem])
            if (internalStore.topMenu[menuItem].frontend_menu_type_id === 1) {
                arrayForRender.push(
                    <li key={internalStore.topMenu[menuItem].id} onClick={this.onClickHome}>
                        <a href={'/'} onClick={e => e.preventDefault()}>
                            {internalStore.topMenu[menuItem].name}
                        </a>
                    </li>
                )
            } else if (internalStore.topMenu[menuItem].frontend_menu_type_id === 2) {
                arrayForRender.push(
                    <li key={internalStore.topMenu[menuItem].id}>
                        {/* onClick={() => this.goTo(topMenu[menuItem].category.alias)} - onClick for goToPrimaryCategory */}
                        {/* hidden-xs hidden-sm */}
                        <a
                            data-toggle='collapse'
                            aria-expanded='false'
                            aria-controls='toggleSubmenu'
                            href='#toggleSubmenu'
                            className={'productsOpenDropDown'}
                        >
                            {internalStore.topMenu[menuItem].name}{' '}
                            <span className='open-dropdown'>
                                <i className='fa fa-angle-down' />
                            </span>
                        </a>
                        <div className='navbar-dropdown' id='toggleSubmenu' style={{ height: 0 }}>
                            <div className='navbar-box'>
                                <div className='box-2'>
                                    <div className='box clearfix'>
                                        <div className='row'>
                                            {internalStore.topMenu[menuItem].category &&
                                                internalStore.topMenu[menuItem].category.children &&
                                                internalStore.topMenu[
                                                    menuItem
                                                ].category.children.map((category, index) => {
                                                    // console.log("CATEGORY", category)
                                                    if (index % 4 === 0) {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className='clearfix' />
                                                                <div className='col-md-3 '>
                                                                    <ul>
                                                                        <li
                                                                            className='label category'
                                                                            onClick={() => {
                                                                                this.goTo(
                                                                                    category.alias
                                                                                )
                                                                            }}
                                                                        >
                                                                            <a
                                                                                href={`/${
                                                                                    category.alias
                                                                                }`}
                                                                                onClick={e =>
                                                                                    e.preventDefault()
                                                                                }
                                                                            >
                                                                                {category.name}
                                                                            </a>
                                                                        </li>
                                                                        {category.children &&
                                                                            category.children.map(
                                                                                (
                                                                                    subCategory,
                                                                                    indexSubCategory
                                                                                ) => {
                                                                                    // console.log("SUB-CATEGORY", subCategory)
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                indexSubCategory
                                                                                            }
                                                                                            className='subCateogry'
                                                                                            onClick={() => {
                                                                                                this.goTo(
                                                                                                    subCategory.alias
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <a
                                                                                                href={`/${
                                                                                                    subCategory.alias
                                                                                                }`}
                                                                                                onClick={e =>
                                                                                                    e.preventDefault()
                                                                                                }
                                                                                            >
                                                                                                {/* <Icon path={mdiSubdirectoryArrowRight} size={1} className={'productSubcategory'}/> */}
                                                                                                {
                                                                                                    subCategory.name
                                                                                                }
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={index} className='col-md-3 '>
                                                                <ul>
                                                                    <li
                                                                        className='label category'
                                                                        onClick={() =>
                                                                            this.goTo(
                                                                                category.alias
                                                                            )
                                                                        }
                                                                    >
                                                                        <a
                                                                            href={`/${
                                                                                category.alias
                                                                            }`}
                                                                            onClick={e =>
                                                                                e.preventDefault()
                                                                            }
                                                                        >
                                                                            {category.name}
                                                                        </a>
                                                                    </li>
                                                                    {category.children &&
                                                                        category.children.map(
                                                                            (
                                                                                subCategory,
                                                                                indexSubCategory
                                                                            ) => {
                                                                                // console.log("SUB-CATEGORY", subCategory)
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            indexSubCategory
                                                                                        }
                                                                                        className='subCateogry'
                                                                                        onClick={() =>
                                                                                            this.goTo(
                                                                                                subCategory.alias
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <a
                                                                                            href={`/${
                                                                                                subCategory.alias
                                                                                            }`}
                                                                                            onClick={e =>
                                                                                                e.preventDefault()
                                                                                            }
                                                                                        >
                                                                                            {/* <Icon path={mdiSubdirectoryArrowRight} size={1} className={'productSubcategory'}/> */}
                                                                                            {
                                                                                                subCategory.name
                                                                                            }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            } else if (internalStore.topMenu[menuItem].frontend_menu_type_id === 4) {
                const attrs = {}
                if (internalStore.topMenu[menuItem].is_external) {
                    attrs.target = '_blank'
                } else {
                    attrs.onClick = e => {
                        e.preventDefault()
                        this.goTo(internalStore.topMenu[menuItem].alias)
                    }
                }
                // article
                arrayForRender.push(
                    <li key={internalStore.topMenu[menuItem].id}>
                        <a
                            href={internalStore.topMenu[menuItem].is_external ? internalStore.topMenu[menuItem].external_url : `/${internalStore.topMenu[menuItem].alias}`}
                            {...attrs}
                        >
                            {internalStore.topMenu[menuItem].name}
                        </a>
                    </li>
                )
            } else {
                arrayForRender.push(
                    <li key={internalStore.topMenu[menuItem].id}>
                        <a onClick={() => this.goToContact(internalStore.topMenu[menuItem].alias)}>
                            {internalStore.topMenu[menuItem].name}
                        </a>
                    </li>
                )
            }
        }

        return arrayForRender
    }

    render() {
        const miniBanners = [
            this.state.mini_banner_url && this.state.mini_banner_url,
            this.state.mini_banner_2_url && this.state.mini_banner_2_url
        ].filter(i => i)
        return this.state.loading ? null : (
            <>
                <div className='navigation navigation-main'>
                    {/* <!-- Setup your logo here--> */}
                    {/* <a href='/' className='logo'> */}
                    <a onClick={this.onClickHome} className='logo'>
                        <img src={this.state.logo} alt='' />
                    </a>

                    {miniBanners.length > 0 ? (
                      <div className={`mini-banners ${miniBanners.length > 1 ? 'multiple' : ''}`}>
                        {this.state.mini_banner_url && this.state.mini_banner_url ? (
                          <a href={this.state.mini_banner_url} className="mini-banner">
                              <img src={this.state.mini_banner_image} alt="mini-banner" />
                          </a>
                        ) : null}
                        {this.state.mini_banner_2_url && this.state.mini_banner_2_url ? (
                          <a href={this.state.mini_banner_2_url} className="mini-banner">
                              <img src={this.state.mini_banner_2_image} alt="mini-banner" />
                          </a>
                        ) : null}
                      </div>
                    ) : null}

                    {/* <!-- Mobile toggle menu --> */}
                    <a href='javascript:void(0)' className='open-menu'>
                        <i className='icon icon-menu' />
                    </a>

                    {/* <!-- Convertible menu (mobile/desktop)--> */}
                    <div className='floating-menu' id='floatingMenu'>
                        {/* <!-- Mobile toggle menu trigger--> */}
                        <div className='close-menu-wrapper'>
                            <span className='close-menu'>
                                <i className='icon icon-cross' />
                            </span>
                        </div>

                        <ul>{this.rennderTopMenu()}</ul>
                    </div>
                    {/* <!--/floating-menu--> */}
                </div>

                {miniBanners.length > 0 ? (
                  <div className={`mini-banner-mobile-wrapper ${miniBanners.length > 1 ? 'multiple' : ''}`}>
                      {this.state.mini_banner_url && this.state.mini_banner_url ? (
                        <a href={this.state.mini_banner_url} className="mini-banner-mobile">
                            <img src={this.state.mini_banner_image} alt="mini-banner" />
                        </a>
                      ) : null}
                      {this.state.mini_banner_2_url && this.state.mini_banner_2_url ? (
                        <a href={this.state.mini_banner_2_url} className="mini-banner-mobile">
                            <img src={this.state.mini_banner_2_image} alt="mini-banner" />
                        </a>
                      ) : null}
                  </div>
                ) : null}
            </>
        )
    }
}
export default observer(NavigationMain)
