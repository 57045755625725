//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api

//style
import './Tabs.scss'

//custom components
import Tab from '../../basic/Tab/Tab'
import TabContent from '../../basic/TabContent/TabContent'

//material-ui

//other libraries

/**
 * Tabs
 * @param {Object} tabs - tabs
 * @param {Number} tabs.id
 * @param {String} tabs.title
 * @param {Boolean} tabs.active
 * @param {Object} content - content of tabs
 * @param {Number} content.id
 * @param {String} content.titile
 * @param {Boolean} content.active
 * @param {Array} content.products
 */
class Tabs extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className='col-md-12'>
                <ul className='nav nav-tabs' role='tablist'>
                    {this.props.tabs.map((tab, index) => {
                        if (tab !== null) {
                            return <Tab key={index} data={tab} updateUrl={this.props.updateUrl} />
                        }
                    })}
                </ul>
                <div className='tab-content'>
                    {this.props.content.map((tabContent, index) => {
                        if (tabContent !== null) {
                            return (
                                <TabContent
                                    key={index}
                                    data={tabContent}
                                    routerData={this.props.routerData}
                                />
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(Tabs))
