// core
import React from 'react'
import { observer } from 'mobx-react'

// i18n
import t from '../../stories/i18n'

const ProductsGridSortBy = observer(props => {
    const { listingStaticSets, handleActiveFilterPhone, handleSort } = props
    const { defaultValue, defaultDescription } = props

    return (
        <div className='container'>
            <div className='sort-bar '>
                {!listingStaticSets && (
                    <div className='sort-options pull-right'>
                        <span className=''>{t.i18n.PAGES.CATEGORIES.SORT_BY}</span>

                        <select id='sort-price' onChange={handleSort} defaultValue={'name-asc'}>
                            <option value={defaultValue || 'name-asc'}>{defaultDescription || t.i18n.PAGES.CATEGORIES.SORT_BY_NAME}</option>

                            <option value='retail_price-asc'>
                                {t.i18n.PAGES.CATEGORIES.PRICE_LOWEST}
                            </option>

                            <option value='retail_price-desc'>
                                {t.i18n.PAGES.CATEGORIES.PRICE_HIGHEST}
                            </option>
                        </select>
                        <span className='grid-list'>
                            <a
                                href='javascript:void(0);'
                                className='toggle-filters-mobile'
                                onClick={handleActiveFilterPhone}
                            >
                                <i className='fa fa-search' />
                            </a>
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
})

export default ProductsGridSortBy
