//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//api
import { api } from '../../../utils/api'

//custom components
import CommentUser from '../../../components/complex/CommentUser/CommentUser'

//material-ui

//other libraries

class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.loadReviews()
    }

    // componentWillReceiveProps() {
    //     this.loadReviews()
    // }

    loadReviews = async() => {
        let load_reviews = await api({
            url: 'account/reviews',
            method: 'GET',
            authorization: this.props.internal.user.acces_token,
        })

        if(load_reviews.success === true) {
            this.props.internal.setTabMyReviews(load_reviews.data.data)
            this.setState({loading: false})
        }
    }

    render () {
        const { tabMyReviews } = toJS(this.props.internal)
        return (
            this.state.loading ? null :
            <div className='row'>
                <div className='col-md-12'>
                    <div className='comments' style={{backgroundColor: `${tabMyReviews.length === 0 ? 'transparent':'' }`, marginBottom: 0}}>

                        {tabMyReviews.length > 0 ?
                            tabMyReviews.map((review, index) => {
                                return (<CommentUser key={index} reviewData={review} editable/>);
                            })
                            :
                            <p className={'text-center'} style={{marginBottom: 0}} >{this.props.translate.i18n.GLOBAL.ALERTS.NO_REVIEWS}</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(Reviews))
