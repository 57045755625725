//basic
import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

//components

//pages
import HomePage from '../../pages/HomePage/HomePage';
import ProductDetail from '../../pages/ProductDetail/ProductDetail';
import ProductsGrid from '../../pages/ProductsGrid/ProductsGrid';
import CompareProducts from '../../pages/CompareProducts/CompareProducts';
import CustomSwitcher from '../../routes/Login/CustomSwitcher';
import ShoppingCart from '../../pages/ShoppingCart/ShoppingCart';
import MyAccount from '../../pages/MyAccount/MyAccount';
import GPWebPay from '../../pages/GpWebPay/GPWebPay';
import SearchPage from '../../pages/SearchPage/SearchPage';

class RouteLogin extends React.Component {
    componentDidMount() {
        // console.group('RouteLogin')
        // console.groupEnd()
        this.props.internal.loadMenuItems();
    }

    render() {
        return (
            <React.Suspense fallback={<div style={{display: "none"}}>Loading.....</div>}>
            <Router>
                <Switch>
                    <Route sensitive={false}  exact path='/' component={HomePage} />
                    <Route sensitive={false} path='/produkt/:id' component={ProductDetail} />
                    <Route
                        sensitive={false}
                        path='/kategoria/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?'
                        component={ProductsGrid}
                    />
                    <Route sensitive={false} exact path='/porovnanie' component={CompareProducts} />
                    
                    
                    {/* <Route sensitive={false} exact path='/:alias' component={CustomSwitcher}/> */}
                    {/* <Route sensitive={false} exact path='/:idProduct' component={ProductDetail}/> */}

                    <Route sensitive={false} exact path='/nakupny-kosik/:step' component={ShoppingCart}/>
                    {/* <Route sensitive={false} exact path='/payment/:status?/:hash?' component={ThankYouPage}/> */}
                    <Redirect from='/payment/:status?/:hash?' to={`/payment-:status`}/>

                    <Route sensitive={false} exact path='/gp-web-pay/process/:token' component={GPWebPay} />
                    
                    <Route sensitive={false} exact path='/account/register/verify/:token' component={HomePage} />
                    <Route sensitive={false} exact path='/account/password/reset/:resetPasswordToken' component={HomePage} />
                    <Route sensitive={false} exact path='/invitation/:invitationToken' component={HomePage} />

                    <Route sensitive={false} path='/moj-ucet/:step' component={MyAccount}/>
                    {/* <Redirect from='/account/register/verify/:token' to="/"/> */}
                    <Route sensitive={false} path='/search' component={SearchPage}/>
                    
                    <Route sensitive={false} exact path='/:alias/:alias2?/:alias3?/:alias4?/:alias5?' component={(props) => <CustomSwitcher {...props} ref={ref => this.props.internal.setCustomSwitcherRef(ref)}/>} />
                    {/* <Route path='*' component={NotFound} /> */}
                </Switch>
            </Router>
            </React.Suspense>
        )
    }
}

export default inject('internal')(observer(RouteLogin))
