import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
//style
import './Snackbar.scss'

//api

//custom components

//material-ui

//other libraries

class Snackbar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const customStyles = {
            cursor: !!this.props.internal.snackbarData.action ? 'pointer' : 'auto'
        }
  
      const { snackbarData } = this.props.internal
      const { action, message, error } = snackbarData;
      const { open } = this.props;
      
      return (
            <div className={'container'} style={customStyles}>
                <div
                    onClick={() => {
                        // @rs
                        // handling onClick actions based on the action required
                      if (action === 'openBasket') {
                            // TODO: might need to be changed when implementing new languages
                            this.props.internal.setGlobalLoader(true)
                            this.props.internal.routerData.history.push(`/nakupny-kosik/produkty`)
                            this.props.internal.routerData.match.params.step = 'produkty'
                        }
                    }}
                    className={`customSnackbar ${open ? 'show' : 'hidde'} ${error ? 'error' : ''}`}
                    style={{ display: `${open ? 'block' : 'none'}` }}
                    id='#snackbar'
                >
                    {toJS(message)}
                    {!!action ? (
                        <span className={`snackbarAction`}>
                            {this.props.translate.i18n.ACTIONS.SHOW}
                            &nbsp;
                            <span className='icon icon-cart' />
                        </span>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(Snackbar))
