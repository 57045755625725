import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api
import { api } from '../../../utils/api';

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions';
import Icon from '@mdi/react';
import { mdiScaleBalance, mdiEyeOutline, mdiCartOutline } from '@mdi/js'
import uuid from 'uuid'
import ReactHtmlParser from 'react-html-parser';

//style
import './ProductQuickViewDialog.scss'

//icons
import compare from '../../../assets/icons/compare.svg';

//no image
import no_image from '../../../assets/images/no_image.png'
class ProductQuickViewDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showFull: false
        }
    }
    componentDidMount() {
        // console.log("ProductQuickViewDialog", this.props);
        // console.log("ProductQuickViewDialog",require('mobx').toJS(this.props.internal))
    }


    addElementForCompare = idProduct => {
        this.props.internal.setCompareProducts(idProduct)
        // console.log("idProduct", idProduct)
        let compare = localStorage.getItem("compare")
        if(compare === null) {
            localStorage.setItem('compare', idProduct.alias)
            this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_ADDES_TO_COMPARE_LIST, true)
        }
        else {
            if(compare.includes(idProduct.alias)) {
                this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_READY_FOR_COMPARE, true)
            }
            else {
                let compareArray = compare.split(",");
                if(compareArray.length > 9) {
                    this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.MAX_PRODUCT_FOR_COMPARE, true)
                }
                else {
                    compare += "," + idProduct.alias
                    localStorage.setItem('compare', compare)
                    this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_ADDES_TO_COMPARE_LIST, true)
                }

            }
        }
        this.props.internal.loginWrapperRef.rerender()
    }

    /**
     * @param {string} url - field: category_slug
     */
    goToProductDetail = (url) => {

        this.props.internal.setGlobalLoader(true);

        let paramsArray = url.split("/")
        // console.log("paramsArray", paramsArray)
        let matchPrams = this.props.internal.routerData.match.params
        let indexParamsArray = 0
        for(let i in matchPrams) {
            matchPrams[i] = undefined
            if(indexParamsArray < paramsArray.length) {
                matchPrams[i] = paramsArray[indexParamsArray]
                indexParamsArray++;
            }
        }
        // console.log("matchParams", matchPrams, "\n\n", this.props)
        this.props.internal.routerData.match.params = matchPrams
        this.props.internal.routerData.history.push(`/${url}`)
        window.closeMagnificPopup()
        window.location.reload()
    }

    renderGallery = () => {
        let images = []
        if(this.props.products) {
            const { products } = this.props
            for(let product in products) {
                const { gallery } = products[product]
                if(gallery && gallery !== null) {
                    for(let image in gallery) {
                        images.push(<img key={uuid()} src={gallery[image].asset_image} alt={gallery[image].name} width='640' />)
                    }
                }
            }
        }
        return images
    }

    goToFixedSetDetail = (alias) => {
        // let nameWithoutSpaces = name.replace(/\s/g,'-')
        // console.log("gixed set", name.replace(/\s/g,'-'))
        this.props.internal.setGlobalLoader(true);
        // let urlFromName = nameWithoutSpaces.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

        //TODO: vymazat
        // urlFromName = "nazov-fixneho-setu"

        let matchPrams = this.props.internal.routerData.match.params
        let indexParamsArray = 0
        for(let i in matchPrams) {
            matchPrams[i] = undefined
            if(indexParamsArray < matchPrams.length) {
                matchPrams[i] = matchPrams[indexParamsArray]
                indexParamsArray++;
            }
        }

        this.props.internal.routerData.match.params.alias = alias;
        this.props.internal.routerData.history.push(`/${alias}`)
        window.closeMagnificPopup()
    }

    handleAddProductToShoppingCart = async(item) => {

        this.setState({loading: true})
        // console.log("ITEM", item)

        let params = {}

        if(item.fixedSet) {
            params.static_set = item.id
            params.quantity = 1
        }
        else {
            params.product = item.id
            params.quantity = 1
        }

        let update_shop_cart = await api ({
            url: 'shopping-cart',
            method: 'PUT',
            scns_token: localStorage.getItem('scns_token'),
            //TODO: vymazat
            // postman: true,
            params: params
        })

        // console.log("update_shop_cart", update_shop_cart)
        if(update_shop_cart.success === true) {
            this.props.internal.setShoppingCartData(update_shop_cart.data.data)
            // console.log("new state shopping cart", toJS(this.props.internal.shoppingCartData))
            this.setState({loading: false})
            window.closeMagnificPopup()
            this.props.internal.setSnackbarData(update_shop_cart.data.message, true, 'openBasket')
            this.props.internal.loginWrapperRef.rerender()
        }
    }

    setWatchDogData = () => {
        this.props.internal.setWatchDogData(this.props);
    }

    render() {
        // console.log("product quick view", this.props)
        let user = JSON.parse(localStorage.getItem("user"))
        return (
            <div className='popup-main mfp-hide' id={`productid${this.props.id}`}>
                <div className='product'>
                    <div className='popup-title'>
                        <div className='h1 title'>
                            {this.props.name}
                            <small>
                                {this.props.category_path
                                    ?
                                    this.props.category_path.next
                                        ?
                                        this.props.category_path.next.self
                                            ?
                                            this.props.category_path.next.id
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                                }
                            </small>
                        </div>
                    </div>
                    {user !== null &&
                    <div className={'watchDogQuickView'}>
                        <a
                            data-title={this.props.translate.i18n.COMPONENTS.QUICK_VIEW.ADD_TO_FAVORITE}
                            data-toggle='modal'
                            data-target='#watchDog'
                            onClick={() => this.setWatchDogData()} >
                        >
                            <i className='icon icon-heart' />
                        </a>
                    </div>
                    }
                    <div className='owl-product-gallery'>

                        {/* fixny set  */}
                        {this.props.fixedSet && this.props.image && this.props.image !== null ? <img src={this.props.image} alt='' width='640' /> : null }
                        {/* {this.props.fixedSet && this.props.products &&
                            this.props.products.map((product, indexProduct) => {
                                product.gallery && product.gallery.map((productImage, productImageIndex) => {
                                    return <img key={indexProduct+"-"+productImageIndex} src={productImage.asset_image} alt={productImage.name} width='640' />
                                })

                            })} */}
                        {/* {this.props.fixedSet && this.props.image && this.props.image !== null ? this.renderGallery() : null} */}

                        {this.props.hasOwnProperty("asset_image") && this.props.asset_image !== null ? <img src={this.props.asset_image} alt='' width='640' />  : null}
                        {/* {this.props.gallery &&
                            this.props.gallery.map((image, index) => {
                                return <img key={index} src={image.asset_image} alt={image.name} width='640' />
                            })} */}

                        {!this.props.hasOwnProperty("asset_image") && this.props.asset_image === null && this.props.fixedSet && this.props.image && this.props.image === null ? <img src={no_image} alt='' width='640' />  : null}
                    </div>

                    {false && this.props.tags && this.props.tags.length > 0 ?
                        <div className={'popup-content quick-view-tags'}>
                            {this.props.tags && this.props.tags.map((tag, index) => {
                                return (
                                    <span key={index} className={'oneTag'} style={{backgroundColor: tag.color}}>{tag.name}</span>
                                );
                            })}
                        </div>
                        :
                        null
                    }
                    <div className='popup-content'>
                        <div className='product-info-wrapper'>
                            <div className='row'>
                                <div className={`${this.props.fixedSet ? '':'col-sm-4'} col-xs-6 `}>
                                    <div className='info-box'>
                                        <strong>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.WAREHOUSE_INFO}</strong>
                                        <span>{this.props.warehouse_info > 0 ? this.props.translate.i18n.COMPONENTS.QUICK_VIEW.AVAILABLE : this.props.translate.i18n.COMPONENTS.QUICK_VIEW.NOT_AVAILABLE}</span>
                                    </div>
                                </div>
                                {!this.props.fixedSet ?
                                <div className={`col-sm-12`}>
                                    <div className={`info-box description ${this.state.showFull ? 'full' : ''}`}>
                                        <strong>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.DESCRIPTION_AND_DOCS}</strong>
                                        <span>{this.props.hasOwnProperty('description') ? ReactHtmlParser(this.props.description) : this.props.translate.i18n.PAGES.PRODUCT_DETAIL.NO_DESCRIPTION_YET}</span>
                                        {/*
                                        @rs
                                        the showFull button which allows the visibility of the whole description
                                        */}
                                        <p role='button' className={`showFullTrigger`} onClick={() => {
                                            this.setState({showFull: true})
                                        }}>{this.props.translate.i18n.ACTIONS.SHOW_MORE}</p>
                                    </div>
                                </div>
                                    : null}
                                {/* <div className='col-sm-12'>
                                    <div className='info-box productDescription'>
                                        <i>
                                            {ReactHtmlParser(this.props.description)}
                                        </i>
                                    </div>
                                </div> */}
                                    {false && this.props.hasOwnProperty('parameters') && (this.props.parameters).length > 0?


                                        this.props.parameters.map((parameter, index) => {
                                            // console.log("this.props.hasOwnProperty('parameters') && (this.props.parameters).length > 0", this.props.hasOwnProperty('parameters'),this.props.parameters, (this.props.parameters).length > 0)
                                        return (
                                            <div key={index}  className='col-xs-6 col-sm-4'>
                                            <div className='info-box'>
                                                <strong>{parameter.external_name}</strong>
                                                {parameter.parameter_type_id === 3 ? //textovy parameter
                                                    <span>{parameter.value}</span>
                                                    :
                                                    parameter.parameter_type_id === 1 ? //single
                                                        parameter.value && parameter.value.name ?
                                                            <span>{parameter.value.name}</span>
                                                            :
                                                            null
                                                        :
                                                        parameter.hasOwnProperty('parameter_type_id') && parameter.parameter_type_id === 2 ? //multi
                                                            // null
                                                            parameter.hasOwnProperty('value') ?
                                                                parameter.value.map((val, indexMulti) => {
                                                                    return (<span key={indexMulti}>{val.name}{indexMulti < parameter.value.length-1 ? ",":""}&nbsp;</span>)
                                                                })
                                                                :
                                                                null
                                                            :
                                                            null
                                                }
                                            </div>
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                    }

                                    {/* zoznam produktov pre fixne sety */}
                                    {this.props.fixedSet && this.props.products && this.props.products.map((product, indexProduct) => {
                                        return (
                                            <div key={indexProduct} className='col-xs-6'>
                                            <div className='info-box'>
                                                <strong>Produkt {indexProduct+1}:</strong>
                                                {/* <strong>{product.category && product.category.name ? product.category.name+":" : <strong>Produkt {indexProduct+1}:</strong>}</strong> */}
                                                <span>{product.name}</span>
                                            </div>
                                           </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className='popup-table'>
                        <div className='popup-cell'>
                            <div className='price'>
                                <span className='h3'>
                                    {(!this.props.total) ?
                                        this.props.discounted_price > 0 ? formatMoney(this.props.discounted_price_with_vat) : formatMoney(this.props.retail_price_with_vat)
                                    : null}

                                    {(!this.props.total) ?
                                        this.props.discounted_price > 0 ? <small><s>{formatMoney(this.props.retail_price_with_vat)}</s></small> : null
                                    : null}

                                    {/* fixny set */}
                                    {this.props.total && formatMoney(this.props.total_with_vat)}
                                </span>
                            </div>
                        </div>
                        <div className='popup-cell'>
                            <div className='popup-buttons'>
                                {this.props.fixedSet ?
                                    null
                                    :
                                    <a
                                        href='javascript:void(0);'
                                        onClick={() => this.addElementForCompare(this.props)}
                                    >
                                        {/* <span className='icon icon-eye' />{' '} */}
                                        <Icon path={mdiScaleBalance} size={1}/>
                                        <span className='hidden-xs'>
                                            {this.props.translate.i18n.COMPONENTS.QUICK_VIEW.COMPARE}
                                        </span>
                                    </a>
                                }
                                {this.props.fixedSet ?

                                <a onClick={() => this.goToFixedSetDetail(this.props.alias)}>
                                    <Icon path={mdiEyeOutline} size={1}/>
                                    <span className='hidden-xs'>
                                        {this.props.translate.i18n.COMPONENTS.QUICK_VIEW.VIEW_MORE}
                                    </span>
                                </a>
                                :
                                <a onClick={() => this.goToProductDetail(this.props.alias)}>
                                    <Icon path={mdiEyeOutline} size={1}/>
                                    <span className='hidden-xs'>
                                        {this.props.translate.i18n.COMPONENTS.QUICK_VIEW.VIEW_MORE}
                                    </span>
                                </a>
                                }
                                {this.state.loading ?
                                <a className={'aWithoutHover'}>
                                    <span >
                                        <div className='lds-dual-ring addToShoppingCart' />
                                    </span>
                                </a>
                                :
                                <a onClick={() => this.handleAddProductToShoppingCart(this.props)}>
                                    <Icon path={mdiCartOutline} size={1}/>
                                    <span className='hidden-xs'>
                                        {this.props.translate.i18n.COMPONENTS.QUICK_VIEW.BUY}
                                    </span>
                                </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(ProductQuickViewDialog))
