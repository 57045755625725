// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../../utils/api'

// assets
import mc from '../../../../src/assets/images/mc.png'
import visa from '../../../../src/assets/images/visa.png'

// i18n
import t from '../../../stories/i18n'

// stores
import internalStore from '../../../stories/internal'

// styles
import './Footer.scss'

// utils
import { regexes } from '../../../utils/fuctions'

class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            wrongEmailFormat: false, // nastavuje border-bottom-color: red, lebo je nespravny format emailu,
            loading: true,
            loadingSettings: true,
            loadingBottomMenu: true,
            sendEmailLoader: false
        }
    }

    componentDidMount() {
        Promise.all([]).then(() => {
            this.setState({ loading: false })
        })
    }

    onChangeEmail = data => {
        this.setState({
            email: data.target.value.toLowerCase(),
            wrongEmailFormat: false
        })
    }

    onClickSubscribe = () => {
        let email = this.state.email

        if (!regexes.email.test(email)) {
            this.setState({ wrongEmailFormat: true })

            internalStore.setSnackbarErrorData(t.i18n.GLOBAL.ALERTS.WRONG_EMAIL_FORMAT)
        } else {
            this.newsletter()
        }
    }

    newsletter = async () => {
        this.setState({ sendEmailLoader: true })

        let set_newsletter = await api({
            method: 'POST',
            url: 'newsletter',
            params: {
                email: this.state.email
            }
        })

        if (set_newsletter.success === true) {
            this.setState({ email: '', sendEmailLoader: false })
            internalStore.setSnackbarData(set_newsletter.data.message, true)
        } else {
            this.setState({ sendEmailLoader: false })
            internalStore.setSnackbarErrorData(set_newsletter.data.message)
        }
    }

    getContactMenuAlias = () => {
        const { topMenu, bottomMenu } = internalStore

        let alias = null

        for (let i in topMenu) {
            if (topMenu[i].frontend_menu_type_id === 3) {
                alias = topMenu[i].alias
            }
        }

        for (let i in bottomMenu) {
            if (bottomMenu[i].frontend_menu_type_id === 3) {
                alias = bottomMenu[i].alias
            }
        }

        return alias
    }

    handleGoToContact = e => {
        e.preventDefault()
        e.stopPropagation()

        let alias = this.getContactMenuAlias()

        internalStore.routerData.history.push(`/${alias}`)
    }

    goTo = (e, alias) => {
        e.preventDefault()
        e.stopPropagation()
        internalStore.routerData.history.push(`/${alias}`)
    }

    render() {
        if (!internalStore.footerData) {
            return null
        }

        const bekoshop = internalStore.footerData

        return (
            // <!-- ================== Footer  ================== -->
            this.state.loading ? null : (
                <footer id='footer'>
                    <div className='container'>
                        <div className='footer-showroom'>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <h2>{bekoshop.shop.showroom_title}</h2>
                                    <p className={'description'}>{bekoshop.shop.description}</p>
                                    <p>
                                        {bekoshop.address.street_name} {bekoshop.address.street_no},{' '}
                                        {bekoshop.address.zip} {bekoshop.address.city},{' '}
                                        {bekoshop.address.country}
                                    </p>
                                    <p>
                                        {t.i18n.COMPONENTS.FOOTER.WEEK}:{' '}
                                        {bekoshop.shop.opening_hours_mon_fri} &nbsp; &nbsp; | &nbsp;
                                        &nbsp; {t.i18n.COMPONENTS.FOOTER.WEEKEND}:{' '}
                                        {bekoshop.shop.opening_hours_sat_sun}{' '}
                                    </p>
                                </div>
                                <div className='col-sm-4 text-center contactUs'>
                                    {this.getContactMenuAlias() && (
                                        <a
                                            onClick={this.handleGoToContact}
                                            href={this.getContactMenuAlias()}
                                            className='btn btn-border btnWhiteBackground'
                                        >
                                            <span className='icon icon-map-marker' />{' '}
                                            {t.i18n.GLOBAL.FOOTER.DIRECTIONS}
                                        </a>
                                    )}
                                    {bekoshop.shop && bekoshop.shop.contact_phone && (
                                        <div className='call-us h4'>
                                            <span className='icon icon-phone-handset' />
                                            <a href={`tel:${bekoshop.shop.contact_phone}`}>
                                                {bekoshop.shop.contact_phone}
                                            </a>
                                        </div>
                                    )}
                                    {bekoshop.shop.contact_email && (
                                        <div className='call-us h4'>
                                            <span className='icon icon-email' />
                                            <a href={`mailto:${bekoshop.shop.contact_email}`}>
                                                {bekoshop.shop.contact_email}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='footer-links'>
                            <div className='row'>
                                {/* <div className='col-sm-12 col-md-8 col-md-offset-2 '> */}
                                <div className='col-sm-6 col-sm-offset-3 '>
                                    <h5>{t.i18n.GLOBAL.FOOTER.SIGN_UP_NEWSLETTER}</h5>
                                    <p>
                                        <i>{t.i18n.GLOBAL.FOOTER.SUBSCRIBE_SUBTITLE}</i>
                                    </p>
                                    <div className='form-group form-newsletter'>
                                        <div className='row'>
                                            <div className='col-sm-9'>
                                                <input
                                                    className={`form-control ${
                                                        this.state.wrongEmailFormat ? 'wrong' : ''
                                                    }`}
                                                    type='text'
                                                    name='email'
                                                    value={this.state.email}
                                                    placeholder={t.i18n.GLOBAL.TEXT.EMAIL}
                                                    onChange={this.onChangeEmail}
                                                />
                                            </div>
                                            <div className='col-sm-3'>
                                                {this.state.sendEmailLoader ? (
                                                    <div className='lds-dual-ring' />
                                                ) : (
                                                    <input
                                                        type='button'
                                                        className='btn btn-border btn-sm btnWhiteBackground btnSubscribe'
                                                        value={t.i18n.GLOBAL.BUTTONS.SUBSCRIBE}
                                                        onClick={this.onClickSubscribe}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='footer-social'>
                            <div className='row'>
                                <div className='col-sm-12 bottomMenu'>
                                    {internalStore.bottomMenu.map((item, index) => {
                                        const attrs = {}
                                        if (item.is_external) {
                                            attrs.target = '_blank'
                                        } else {
                                            attrs.onClick = e => this.goTo(e, item.alias)
                                        }

                                        return (
                                          <div key={index}>
                                              <a
                                                href={item.is_external ? item.external_url : `/${item.alias}`}
                                                {...attrs}
                                              >
                                                  {item.name}
                                              </a>
                                          </div>
                                        )
                                    })}
                                </div>
                                <div className={'col-sm-12 text-center cards'}>
                                    <img src={visa} style={{ height: '1em' }} alt='' />
                                    <img src={mc} style={{ height: '2em' }} alt='' />
                                </div>
                                <div className='col-sm-12 text-center' style={{ marginTop: 40 }}>
                                    <ul>
                                        {bekoshop.shop.facebook !== null ? (
                                            <li>
                                                <a
                                                    href={`${bekoshop.shop.facebook}`}
                                                    target='_blank'
                                                >
                                                    <i className='fa fa-facebook' />
                                                </a>
                                            </li>
                                        ) : null}
                                        {bekoshop.shop.twitter !== null ? (
                                            <li>
                                                <a
                                                    href={`${bekoshop.shop.twitter}`}
                                                    target='_blank'
                                                >
                                                    <i className='fa fa-twitter' />
                                                </a>
                                            </li>
                                        ) : null}
                                        {bekoshop.shop.google !== null ? (
                                            <li>
                                                <a href={`${bekoshop.shop.google}`} target='_blank'>
                                                    <i className='fa fa-google-plus' />
                                                </a>
                                            </li>
                                        ) : null}
                                        {bekoshop.shop.youtube !== null ? (
                                            <li>
                                                <a
                                                    href={`${bekoshop.shop.youtube}`}
                                                    target='_blank'
                                                >
                                                    <i className='fa fa-youtube' />
                                                </a>
                                            </li>
                                        ) : null}
                                        {bekoshop.shop.instagram !== null ? (
                                            <li>
                                                <a
                                                    href={`${bekoshop.shop.instagram}`}
                                                    target='_blank'
                                                >
                                                    <i className='fa fa-instagram' />
                                                </a>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                                <div className='col-sm-12 text-center copyright'>
                                    {bekoshop.shop.name} &copy; {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )
        )
    }
}

export default observer(Footer)
