//basic
import React, { Component } from 'react';
import { observer, inject } from "mobx-react";

//style
import './Loader.scss';

//api

//custom components

//material-ui

//other libraries


class Loader extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
          this.props.loading ?
            <div className={`page-loader-custom ${this.props.loading ? "" : 'loaded'}  `}>
                <div className='lds-dual-ring' />
            </div>
            :
            null
            
        )
    }
}

export default inject('internal')(observer(Loader));
