// core
import React from 'react'

const ScrollTop = React.memo(() => {
    return (
        <div className='scroll-top'>
            <i className='icon icon-chevron-up' />
        </div>
    )
})

export default ScrollTop
