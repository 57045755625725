// core
import React from 'react'
import { observer } from 'mobx-react'

// components
import ProductItem from '../../components/complex/ProductItem/ProductItem'

// i18n
import t from '../../stories/i18n'

const ProductsGridItems = observer(props => {
    const { filter, listingStaticSets, nextPageLoader, products, renderchips, wrapperClassName } = props

    return (
        <div className={wrapperClassName || 'col-md-10 col-xs-12'} id='productsGrid'>
            {filter ? (
                <div className={'row'}>
                    <div className={'col-xs-12'}>
                        <div className='lds-dual-ring filterLoader' />
                    </div>
                </div>
            ) : (
                <div id='products' className='row'>
                    <div className='renderChips'>{renderchips()}</div>
                    {products.length < 1 ? (
                        <div className={'noProductsWithParams'}>
                            <i className='fa fa-search' style={{ marginBottom: '10px' }} />
                            <p className={'noProductsWithParams'}>
                                {t.i18n.GLOBAL.ALERTS.NO_PRODUCTS_WITH_CHOOSEN_PARAMS}
                            </p>
                        </div>
                    ) : (
                        products.map((item, index) => {
                            return (
                                <div key={index}>
                                    <ProductItem
                                        item={item}
                                        parentClasses={'col-lg-3 col-md-4 col-sm-6 col-xs-12'}
                                        listOfProducts
                                        fixedSet={listingStaticSets}
                                    />
                                </div>
                            )
                        })
                    )}
                </div>
            )}
            {nextPageLoader ? <div className='lds-dual-ring nextPageLoader' /> : null}
        </div>
    )
})

export default ProductsGridItems
