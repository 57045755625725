// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// assets
import Icon from '@mdi/react'
import { mdiScaleBalance, mdiMagnify, mdiAccountOutline, mdiCartOutline, mdiClose } from '@mdi/js'

// components
import CartWrapper from '../../complex/CartWrapper/CartWrapper'
import SearchWrapper from '../../complex/SearchWrapper/SearchWrapper'
import LoginWrapper from '../../complex/LoginWrapper/LoginWrapper'

// stores
import internalStore from '../../../stories/internal'

// styles
import './NavigationTop.scss'

class NavigationTop extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpenCart: false,
            isSearchWrapperOpen: false,
            isLoginWrapperOpen: false,
            loadingShoppingCart: false
        }
    }

    goToCompare = () => {
        // const URLqueryString = this.props.internal.routerData.history.location.search
        // let queries = queryString.parse(URLqueryString, {arrayFormat: 'index'})
        // console.log("queries",queries)
        // console.log("props",require('mobx').toJS(this.props))
        internalStore.routerData.history.push('/porovnanie')
    }

    /**
     * show/hide dialog okno pre shoping cart
     */
    handleShowShoppingCart = () => {
        if (!internalStore.routerData.match.url.includes('nakupny-kosik')) {
            this.setState({
                isOpenCart: !this.state.isOpenCart,
                isSearchWrapperOpen: false,
                isLoginWrapperOpen: false
            })
        }
    }

    handleShowSearchDialog = () => {
        this.setState({
            isOpenCart: false,
            isSearchWrapperOpen: !this.state.isSearchWrapperOpen,
            isLoginWrapperOpen: false
        })
    }

    handleShowLoginDialog = () => {
        this.setState({
            isOpenCart: false,
            isSearchWrapperOpen: false,
            isLoginWrapperOpen: !this.state.isLoginWrapperOpen
        })
    }
    
    
    rerender = () => {
        this.setState({ loadingShoppingCart: !this.state.loadingShoppingCart }, () => {
            this.setState({ loadingShoppingCart: !this.state.loadingShoppingCart })
        })
    }

    render() {
        let compare = localStorage.getItem('compare')
        let productsForCompare = []
        let numProductsForCompare = 0
        if (compare) {
            productsForCompare = compare.split(',')
            if (productsForCompare[0] === '') {
                numProductsForCompare = productsForCompare.length - 1
            } else {
                numProductsForCompare = productsForCompare.length
            }
        }

        return (
            <div className='navigation navigation-top clearfix'>
                <ul>
                    <li>
                        <a className='open-search dialog' onClick={this.handleShowSearchDialog}>
                            {this.state.isSearchWrapperOpen ? (
                                <Icon path={mdiClose} size={1.2} />
                            ) : (
                                <Icon path={mdiMagnify} size={1.2} />
                            )}
                        </a>
                    </li>
                    <li>
                        <a href='/porovnanie' className='open-cart dialog'>
                            <Icon path={mdiScaleBalance} size={1.2} />
                            {/* <Icon path={mdiClose} size={1.2}/> */}
                            <span>{numProductsForCompare}</span>
                        </a>
                    </li>
                    <li>
                        <a className='open-login dialog' onClick={this.handleShowLoginDialog}>
                            {this.state.isLoginWrapperOpen ? (
                                <Icon path={mdiClose} size={1.2} />
                            ) : (
                                <Icon path={mdiAccountOutline} size={1.2} />
                            )}
                        </a>
                    </li>
                    <li>
                        <a className='open-cart dialog' onClick={this.handleShowShoppingCart}>
                            {this.state.isOpenCart ? (
                                <Icon path={mdiClose} size={1.2} />
                            ) : (
                                <Icon path={mdiCartOutline} size={1.2} />
                            )}
                            <span>
                                {internalStore.shoppingCartData &&
                                internalStore.shoppingCartData.items_count
                                    ? internalStore.shoppingCartData.items_count
                                    : 0}
                            </span>
                        </a>
                    </li>
                </ul>
                <CartWrapper
                    isOpen={this.state.isOpenCart}
                    loadingShoppingCart={this.props.loadingShoppingCart}
                    closeDialog={this.handleShowShoppingCart}
                    hideBackToProducts={true}
                />
                <SearchWrapper
                    isOpen={this.state.isSearchWrapperOpen}
                    closeDialog={this.handleShowSearchDialog}
                />
                <LoginWrapper
                    isOpen={this.state.isLoginWrapperOpen}
                    closeDialog={this.handleShowLoginDialog}
                />
            </div>
        )
    }
}
export default observer(NavigationTop)
