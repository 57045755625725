import React, { Component } from 'react'
import { createMuiTheme, IconButton, InputAdornment, MuiThemeProvider } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/sk'
import moment from 'moment'
import CalendarToday from '@material-ui/icons/CalendarToday'
import './DatePicker.scss'

const materialTheme = createMuiTheme({
  typography: {
    htmlFontSize: 9,
    useNextVariants: true,
  },
  overrides: {
    MuiInput: {
      underline: {
        '&::after': {
          borderBottomColor: '#777777',
          borderBottom: '2px solid #777777 !important',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#a6cbd1',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#a6cbd1',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#a6cbd1',
      },
      thumb: {
        backgroundColor: '#a6cbd1',
        borderColor: '#a6cbd1',
      },
    },
    MuiPickersClockNumber: {
      selected: {
        backgroundColor: '#a6cbd1',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#777777',
      },
      selected: {
        backgroundColor: '#a6cbd1',
        '&:hover': {
          backgroundColor: '#a6cbd1',
        },
      },
      current: {
        color: '#777777',
      },
    },
    MuiPickersYear: {
      root: {
        color: '#777777',
        '&:hover': {
          color: '#a6cbd1',
        },
      },
      selected: {
        color: '#a6cbd1 !important',
        '&:hover': {
          color: '#a6cbd1',
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#777777',
      },
    },
  },
})

export default class CustomDatePicker extends Component {
  render() {
    return <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="sk" moment={moment}>
        <div className='date-picker'>
          <DatePicker
            label={this.props.label}
            okLabel={this.props.saveLabel || this.props.translate.i18n.GLOBAL.BUTTONS.SAVE}
            cancelLabel={this.props.cancelLabel || this.props.translate.i18n.GLOBAL.BUTTONS.CANCEL}
            labelFunc={date => date ? date.format('DD.MM.YYYY') : ''}
            minDate={this.props.minDate}
            value={this.props.value}
            onChange={this.props.onChange}
            helperText={this.props.helperText || this.props.errorMessage}
            leftArrowIcon={this.props.leftArrowIcon}
            rightArrowIcon={this.props.rightArrowIcon}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton style={{ padding: 6 }}>
                    <CalendarToday/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoOk={!! this.props.autoOk}
            ampm={!! this.props.ampm}
            showTabs={!! this.props.showTabs}
            clearable={!! this.props.clearable}
            allowKeyboardControl={!! this.props.allowKeyboardControl}
            disableFuture={!! this.props.disableFuture}
            required={!! this.props.required}
            error={!! this.props.hasError}
          />
        </div>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  }
}
