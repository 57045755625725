//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//api
import { api } from '../../utils/api';

//style
import './ShoppingCart.scss';
import ReactGA from 'react-ga4'

//custom components
import CartItems from '../../pages/ShoppingCart/CartItems/CartItems';
import Delivery from '../../pages/ShoppingCart/Delivery/Delivery';
import Payment from '../../pages/ShoppingCart/Payment/Payment';
import Receipt from '../../pages/ShoppingCart/Receipt/Receipt';
//material-ui

//other libraries

const STEPS = [
    'produkty',
    'doprava',
    'platba',
    'sumar'
]

class ShoppingCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: STEPS[0],
            stepNumber: 0,
            loading: true,
        }

        // ReactGA.initialize('UA-130881408-1')
        this.addGoPayScriptTag()
    }

    addGoPayScriptTag() {
        let scriptTagID = 'go-pay-script-tag'

        if (null!==document.head.querySelector(`#${scriptTagID}`)) {
            return;
        }

        const script = document.createElement('script');
        script.setAttribute('type','text/javascript');
        script.setAttribute('async', true);
        script.setAttribute('id', scriptTagID);
        script.setAttribute('src', 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js');
        document.head.appendChild(script);
    }

    componentDidMount() {

        ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });



        // if(this.props.match.params.step === 'payment') {
        //     this.props.history.push("/nakupny-kosik/" + 'payment/'+ this.props.match.params.status + "/" + this.props.match.params.hash)
        //     this.props.match.params.step = 'payment'
        //     this.setState({step: STEPS[3]})
        // }
        // else
        if(this.props.match.params.step === undefined || (STEPS.includes(this.props.match.params.step) && this.props.match.params.step !== STEPS[0])) {
            this.props.match.params.step = ""
            this.props.history.push("/nakupny-kosik/" + this.state.step)
            this.props.match.params.step = this.state.step
        }

        Promise.all([
            this.getShoppingCartItems(),
            this.getCountriesCode()
        ]).then (() => {
            this.setState({loading: false})
            this.props.internal.setGlobalLoader(false);
            const { internal, ...other } = this.props
            this.props.internal.setRouterData(other);
        })
    }


    getShoppingCartItems = async() => {
        let load_shopping_cart = await api({
            scns_token: localStorage.getItem('scns_token'),
            url: 'shopping-cart',
            method: 'GET'
        })

        if(load_shopping_cart.success === true) {
            this.props.internal.setShoppingCartData(load_shopping_cart.data.data);
            this.setState({loadingShoppingCart: false})
        }
    }

    getCountriesCode = async() => {
        let load_countries = await api ({
            method: 'GET',
            url: 'countries',
        })

        if(load_countries.success === true) {
            this.props.internal.setCountries(load_countries.data.data)
        }
    }

    handleChangeStep = (stepNumber) => {
        this.setState({step: STEPS[stepNumber], stepNumber: stepNumber})
        let url = "/nakupny-kosik/" + STEPS[stepNumber]
        this.props.history.push(url)
        this.props.match.params.step = STEPS[stepNumber]
        ReactGA.send({ hitType: 'pageview', page: url });
    }

    render () {
        let routerData = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }
        return (
            this.state.loading ?
            null
            :
            <div className={'shoppingCart'}>
                <section className="main-header" >
                    <header>
                        {/* <div className="container text-center">
                            <h2 className="h2 title">Checkout</h2>
                        </div> */}
                    </header>
                </section>
                <div className="step-wrapper">
                    <div className="container">

                        <div className="stepper">
                            <ul className="row">
                                <li className={`col-md-3 ${this.state.stepNumber > 0 ? 'active' : this.state.stepNumber === 0 ? ' current' : ''}`}>
                                    <span data-text={this.props.translate.i18n.PAGES.SHOPPING_CART.CART_ITEMS}></span>
                                </li>
                                <li className={`col-md-3 ${this.state.stepNumber > 1 ? 'active' : this.state.stepNumber === 1 ? 'current' : ''}`}>
                                    <span data-text={this.props.translate.i18n.PAGES.SHOPPING_CART.DELIVERY}></span>
                                </li>
                                <li className={`col-md-3 ${this.state.stepNumber > 2 ? 'active' : this.state.stepNumber === 2 ? 'current' : ''}`}>
                                    <span data-text={this.props.translate.i18n.PAGES.SHOPPING_CART.PAYMENT}></span>
                                </li>
                                <li className={`col-md-3 ${this.state.stepNumber > 3 ? 'active' : this.state.stepNumber === 3 ? 'current' : ''}`}>
                                    <span data-text={this.props.translate.i18n.PAGES.SHOPPING_CART.RECEIPT}></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.step === STEPS[0] && <CartItems changeStep={this.handleChangeStep}/>}
                {this.state.step === STEPS[1] && <Delivery changeStep={this.handleChangeStep}/>}
                {this.state.step === STEPS[2] && <Payment changeStep={this.handleChangeStep}/>}
                {this.state.step === STEPS[3] && <Receipt changeStep={this.handleChangeStep} routerData={routerData}/>}
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(ShoppingCart));
