//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './DetailOrderDialog.scss'

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions'
import queryString from 'query-string'

class DetailOrderDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: {},
    }
  }
  
  handleClose = () => {
    this.props.onClose()
  }
  
  componentWillReceiveProps() {
    this.loadDetailOrder()
  }
  
  loadDetailOrder = async () => {
    this.setState({ loading: true })
    let search = queryString.parse(window.location.search)
    let id = search.id
    
    if (!id) {
      return false;
    }
    
    let loaded_data = await api({
      method: 'GET',
      url: `account/orders/${id}`,
    })
    
    if (loaded_data.success === true) {
      this.setState({ loading: false, data: loaded_data.data.data })
    }
  }
  
  render() {
    return (
      <div className={`modal fade ${this.props.open ? 'in' : ''} `} id='detailOrderDialog' role='dialog'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal' id="closeDetailOrder">
                &times;
              </button>
              <h4 className='modal-title'>
                {this.props.translate.i18n.PAGES.MY_ACCOUNT.DETAIL_ORDER_TITLE}
              </h4>
            </div>
            {this.state.loading ? (
              <div className={'modal-body detailOrderContent'}>
                <div className='lds-dual-ring loadingDetailOrder'/>
              </div>
            ) : (
              <div className={'modal-body detailOrderContent'}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='col-md-12 h4'>
                      {
                        this.props.translate.i18n.COMPONENTS.PAYMENTS
                          .BILLING_INFO
                      }
                    </div>
                    <hr/>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .FIRST_NAME
                          }
                        </strong>{' '}
                        <br/>
                        <span>
                                                        {this.state.data.billing_first_name}{' '}
                                                    </span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .LAST_NAME
                          }
                        </strong>{' '}
                        <br/>
                        <span>
                                                        {this.state.data.billing_last_name}
                                                    </span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.EMAIL
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_email}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.PHONE
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_phone}</span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.STREET_NAME
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_street}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.ZIP
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_zip}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.CITY
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_city}</span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .COUNTRY
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.billing_country}</span>
                      </div>
                    </div>
                  </div>
                  
                  
                  <div className='col-md-6'>
                    <div className='col-md-12 h4'>
                      {
                        this.props.translate.i18n.COMPONENTS.PAYMENTS
                          .SHIPPING_INFO
                      }
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .FIRST_NAME
                          }
                        </strong>{' '}
                        <br/>
                        <span>
                                                        {this.state.data.delivery_first_name}{' '}
                                                    </span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .LAST_NAME
                          }
                        </strong>{' '}
                        <br/>
                        <span>
                                                        {this.state.data.delivery_last_name}
                                                    </span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.EMAIL
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_email}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.PHONE
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_phone}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.STREET_NAME
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_street}</span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.ZIP
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_zip}</span>
                      </div>
                    </div>
                    
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.COMPONENTS
                              .DELIVERY_FORM.CITY
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_city}</span>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <strong>
                          {
                            this.props.translate.i18n.GLOBAL.CONTACT
                              .COUNTRY
                          }
                        </strong>
                        <br/>
                        <span>{this.state.data.delivery_country}</span>
                      </div>
                    </div>
                  </div>
                  
                  <hr className={'hr-divider'}/>
                  <div className='col-md-12'>
                    <div className='col-md-12 h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.LIST_ITEMS}</div>
                    <div className='col-md-12'>
                      <div className={'detailOrderTableContainer'}>
                        <table className={'detailOrderTable'}>
                          <thead>
                          <tr>
                            <th>{this.props.translate.i18n.PAGES.MY_ACCOUNT.PRODUCT_NAME}</th>
                            <th className={'text-right'}>{this.props.translate.i18n.PAGES.MY_ACCOUNT.QUANTITY}</th>
                            <th className={'text-right'}>{this.props.translate.i18n.PAGES.MY_ACCOUNT.ONE_PRICE_WITH_VAT}</th>
                            <th className={'text-right'}>{this.props.translate.i18n.PAGES.MY_ACCOUNT.TOTAL_PRICE_WITH_VAT}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {this.state.data.items && this.state.data.items.length > 0 ?
                            
                            this.state.data.items.map((item, index) => {
                              return (
                                <tr>
                                  <td style={{ width: '50%' }}>
                                    {item.product !== null && item.static_set === null
                                      ?
                                      item.product.name
                                      :
                                      item.static_set !== null
                                        ?
                                        item.static_set.name
                                        :
                                        'N/A'
                                    }
                                  </td>
                                  <td className={'text-right'}>{item.quantity}</td>
                                  <td className={'text-right'}>{formatMoney(item.one_price_with_vat)}</td>
                                  <td className={'text-right'}>{formatMoney(item.total_price_with_vat)}</td>
                                </tr>
                              )
                            })
                            :
                            null
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  
                  {(this.state.data.gift || (this.state.data.gifts && this.state.data.gifts.length > 0)) && (
                    <div className='col-md-12'>
                      <div className='col-md-12 h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.LIST_GIFTS}</div>
                      <div className='col-md-12'>
                        <div className={'detailOrderTableContainer'}>
                          <table className={'detailOrderTable'}>
                            <thead>
                            <tr>
                              <th>{this.props.translate.i18n.PAGES.MY_ACCOUNT.GIFT_NAME}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data.gifts && this.state.data.gifts.map((gift, index) => {
                              return (
                                <tr key={gift.id}>
                                  <td style={{ width: '50%' }}>
                                    {gift.name}
                                  </td>
                                </tr>
                              )
                            })}
                            {this.state.data.gift && (
                              <tr>
                                <td style={{ width: '50%' }}>
                                  {this.state.data.gift.name}
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  <hr className={'hr-divider'}/>
                  <div className='col-md-6'>
                    <div className='col-md-12 h4'>
                      {
                        this.props.translate.i18n.PAGES.MY_ACCOUNT.PAYMENT_TYPE
                      }
                    </div>
                    <hr/>
                    <div className='col-md-12'>
                      <div className='form-group'>
                                                    <span>
                                                        {this.state.data.payment_type !== null
                                                          ?
                                                          this.state.data.payment_type.name
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                        {' - '}
                        <span>
                                                        {this.state.data.payment_type_price_with_vat !== null || this.state.data.payment_type_price_with_vat === 0
                                                          ?
                                                          `${this.props.translate.i18n.PAGES.MY_ACCOUNT.PRICE_WITH_VAT}: ${formatMoney(this.state.data.payment_type_price_with_vat)}`
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='col-md-12 h4'>
                      {
                        this.props.translate.i18n.PAGES.MY_ACCOUNT.DELIVERY_TYPE
                      }
                    </div>
                    <hr/>
                    <div className='col-md-12'>
                      <div className='form-group'>
                                                    <span>
                                                        {this.state.data.delivery_type !== null
                                                          ?
                                                          this.state.data.delivery_type.name
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                        {' - '}
                        <span>
                                                        {this.state.data.payment_type_price_with_vat !== null || this.state.data.payment_type_price_with_vat === 0
                                                          ?
                                                          `${this.props.translate.i18n.PAGES.MY_ACCOUNT.PRICE_WITH_VAT}: ${formatMoney(this.state.data.delivery_type_price_with_vat)}`
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                      </div>
                    </div>
                  </div>
                  <hr className={'hr-divider'}/>
                  <div className='col-md-12'>
                    <div className='col-md-12 h4'>
                      {
                        this.props.translate.i18n.PAGES.MY_ACCOUNT.SUMMARY
                      }
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                                                    <span>
                                                        {this.props.translate.i18n.PAGES.MY_ACCOUNT.TOTAL_WITHOUT_VAT}:
                                                    </span>
                        <span className={'summaryPriceWithoutVat'}>
                                                        {this.state.data.total_with_vat_with_discount !== null
                                                          ?
                                                          formatMoney(this.state.data.total)
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                      </div>
                      <div className='form-group'>
                                                    <span>
                                                        {this.props.translate.i18n.PAGES.MY_ACCOUNT.TOTAL_WITH_VAT_WITH_DISCOUNT}:
                                                    </span>
                        <span className={'summaryPriceWithVat'}>
                                                        {this.state.data.total_with_vat_with_discount !== null
                                                          ?
                                                          formatMoney(this.state.data.total_with_vat_with_discount)
                                                          :
                                                          'N/A'
                                                        }
                                                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    
    )
  }
}

export default inject('internal', 'translate')(observer(DetailOrderDialog))
