//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api

//style
import './TabContent.scss'

//custom components
import RatingComponent from '../../complex/Rating/Rating'
import ProductsAlternativeRelated from '../../complex/ProductsAlternativeRelated/ProductsAlternativeRelated'
import DescriptionTab from '../../basic/DescriptionTab/DescriptionTab'
import ProductDetailTab from '../../basic/ProductDetailTab/ProductDetailTab'
import AboutMe from '../../../pages/MyAccount/AboutMe/AboutMe'
import Reviews from '../../../pages/MyAccount/Reviews/Reviews'
import WatchDog from '../../../pages/MyAccount/WatchDog/WatchDog'
import MyOrders from '../../../pages/MyAccount/MyOrders/MyOrders'

//material-ui

//other libraries

class TabContent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderContent = () => {
        // console.log("tu sme", this.props)
        if (this.props.data.id === 'rating') {
            return <RatingComponent data={this.props.data} my_review={this.props.data.my_review} />
        } else if (
            this.props.data.id === 'alternative' ||
            // this.props.data.id === 'related' ||
            this.props.data.id === 'accessories'
        ) {
            return (
                <ProductsAlternativeRelated
                    data={this.props.data}
                    routerData={this.props.routerData}
                />
            )
        } else if (this.props.data.id === 'description') {
            return (
                <DescriptionTab
                    data={this.props.data}
                    warehouseInfo={this.props.internal.productDetail.warehouse_info}
                />
            )
        } else if (this.props.data.productDetail) {
            return <ProductDetailTab data={this.props.data.productDetail} />
        } else if (this.props.data.editPersonData) {
            return <AboutMe />
        } else if (this.props.data.reviews) {
            return <Reviews />
        } else if (this.props.data.watchDog) {
            return <WatchDog />
        } else if (this.props.data.myOrders) {
            return <MyOrders />
        }
    }

    render() {
        return (
            <div
                role='tabpanel'
                className={`tab-pane ${this.props.data.active ? ' active' : ''}`}
                id={this.props.data.id}
            >
                <div className='content'>
                    {/* <h3 className={'tabContentTitle'}>{this.props.data.title}</h3> */}

                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(TabContent))
