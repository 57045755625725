//basic
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

//style
import './WatchDogDialog.scss';

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries

class WatchDogDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            product_available: false,
            product_price_less_than: false,
            price: 0
        }
    }

    // timeout = null
    // componentDidMount() {
    //     clearTimeout(this.timeout)
    //     this.timeout = setTimeout(() => {
    //         this.setState({loading: false})
    //     }, 1000)
    // }

    handleSetWatchDog = (field, val, checked) => {
        if(checked === true) {
            this.setState({[field]: val.target.checked})
        }
        else {
            let value = val.target.value
            let replaced = value.replace(/[^0-9]+/g, "")
            this.setState({[field]: replaced})
        }
    }

    handleActiveWatchDog = () => {
        let product_available = this.state.product_available
        let product_price_less_than = this.state.product_price_less_than
        let price = this.state.price

        if(product_available === false && product_price_less_than === false) {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.WATCH_DOGS_PARAMS_NOT_CHOOSEN)
            return
        }
        else {
            if(product_price_less_than === true && price <= 0) {
                this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.PRICE_MUST_BE_MORE_THAN_ZERO)
                return
            }
            else {
                this.activeWatchDog()
            }
        }
    }

    activeWatchDog = async() => {

        this.setState({loading: true})
        let params = {
            target_price: this.state.price,
            watch_price: this.state.product_price_less_than,
            watch_availability: this.state.product_available,
            use_email: true,
            use_sms: false
        }

        // vsetky data o komponente na ktory sme klikli
        let dataAboutClickedProduct = this.props.internal.watchDogData

        if(dataAboutClickedProduct.isFixedSet) {
            params.static_set_id = dataAboutClickedProduct.item.id
        } else if (dataAboutClickedProduct.isProduct) {
            params.product_id = dataAboutClickedProduct.item.id
        }
        
        let set_data = await api({
            url: 'account/watch-lists',
            method: "POST",
            params: params
        })


        if(set_data.success === true || (set_data.success === false && set_data.status === 201)) {
            this.props.internal.setSnackbarData(set_data.data.message, true)
        }
        else {
            this.props.internal.setSnackbarErrorData(set_data.data.message)
        }

        this.setState({loading: false, product_available: false, product_price_less_than: false, price: 0})
        let watchDog = document.getElementById("watchDog")
        watchDog.style.display = "none";
        document.body.classList.remove("modal-open")
        if(document.body.lastElementChild.classList.contains("modal-backdrop")) {
            document.body.lastElementChild.remove()
        }
    }

    render () {
        return (
            <div className='modal fade' id='watchDog' role='dialog'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <button type='button' className='close' data-dismiss='modal' >
                                &times;
                            </button>
                            <h4 className='modal-title'>
                                {this.props.translate.i18n.COMPONENTS.WATCH_DOG.HEADER_TITLE}
                            </h4>
                        </div>

                        {this.state.loading ?
                            <div className={'modal-body'} >
                                <div className='lds-dual-ring setDogWatch' />
                            </div>
                            :
                            <div className='modal-body'>
                                <div>
                                    <span>{this.props.translate.i18n.COMPONENTS.WATCH_DOG.BODY_TITLE}</span>
                                </div>
                                <span className="checkbox" >
                                    <input type="checkbox" id='available' checked={this.state.product_available} onChange={(val) => this.handleSetWatchDog('product_available',val, true)}/>
                                    <label htmlFor='available'>{this.props.translate.i18n.COMPONENTS.WATCH_DOG.IS_PRODUCT_AVAILABLE}</label>
                                </span>
                                <span className="checkbox" >
                                    <input type="checkbox" id='price_less_than' checked={this.state.product_price_less_than} onChange={(val) => this.handleSetWatchDog('product_price_less_than',val, true)}/>
                                    <label htmlFor='price_less_than'>{this.props.translate.i18n.COMPONENTS.WATCH_DOG.PRICE_LESS_THAN}</label>
                                    {this.state.product_price_less_than ?
                                        <div className='form-group  watchPrice'>
                                            <input
                                                type='text'
                                                step='1'
                                                min="1"
                                                className={`form-control `}
                                                id='price'
                                                value={this.state.price}
                                                onChange={val =>
                                                    this.handleSetWatchDog('price', val, false)
                                                }
                                                style={{textAlign: "right"}}
                                            />
                                            <span>EUR</span>
                                        </div>

                                        :
                                        null
                                    }
                                </span>


                                <button
                                    type='button'
                                    className='btn btnWhiteBackground activeWatchDog'
                                    onClick={this.handleActiveWatchDog}

                                >
                                    {
                                        this.props.translate.i18n.COMPONENTS.WATCH_DOG.SET_WATCH_DOG
                                    }
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}

export default inject('internal', 'translate')(observer(WatchDogDialog))
