// core
import React from 'react'

// components
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip'

const Handle = Slider.Handle

class ProductsGridHandleCustom extends React.PureComponent {
    render() {
        const { value, dragging, index, filter, ...restProps } = this.props

        const getTooltipContainer = () => document.getElementById('filters')

        return (
            <Tooltip
                overlayStyle={{ zIndex: 99999999 }}
                prefixCls='rc-slider-tooltip'
                overlay={value}
                visible={filter}
                // visible={filter} nefungovalo na desktope
                placement='top'
                key={index}
                getTooltipContainer={getTooltipContainer}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        )
    }
}

export default ProductsGridHandleCustom
