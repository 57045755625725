//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//api

//style
import './CartItems.scss'

//custom components
import CartBlockItem from '../../../components/complex/CartBlockItem/CartBlockItem'
import CartBlockHeader from '../../../components/complex/CartBlockHeader/CartBlockHeader'
import CartBlockFooter from '../../../components/complex/CartBlockFooter/CartBlockFooter'

//material-ui

//other libraries
import MetaTags from 'react-meta-tags'
import ReactGA from 'react-ga4'

class CartItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updating: false
        }
    }

    componentDidMount() {
        this.props.internal.setGlobalLoader(false)

        if (toJS(this.props.internal.shoppingCartData.items).length > 0) {
            ReactGA.event('view_cart', {
                items: toJS(this.props.internal.shoppingCartData.items).map(item => ({
                    item_id: item.product ? item.product.code : item.static_set.name,
                    item_name: item.product ? item.product.name : item.static_set.name,
                    affiliation: 'Bekoshop.sk',
                    coupon: item.product && item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
                    item_category: item.product ? item.product.category_path_alt[0] : null,
                    item_category2: item.product && item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
                    item_category3: item.product && item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
                    price: item.total_price,
                }))
            })
        }
        // window.init();
    }

    componentWillReceiveProps() {
        this.props.internal.setGlobalLoader(false)
        // window.init()
    }

    handleNext = () => {
        this.props.internal.setGlobalLoader(true)
        this.props.changeStep(1)
        ReactGA.event('begin_checkout', {
            currency: 'EUR',
            value: this.props.internal.shoppingCartData.total,
            coupon: this.props.internal.shoppingCartData.coupon ? this.props.internal.shoppingCartData.coupon.code : null,
            items: toJS(this.props.internal.shoppingCartData.items).map(item => ({
                item_id: item.product ? item.product.code : item.static_set.name,
                item_name: item.product ? item.product.name : item.static_set.name,
                affiliation: 'Bekoshop.sk',
                coupon: item.product && item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
                item_category: item.product ? item.product.category_path_alt[0] : null,
                item_category2: item.product && item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
                item_category3: item.product && item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
                price: item.total_price,
            }))
        })
    }

    updateCart = () => {
        this.setState({ updating: !this.state.updating })
        // this.setState({updating: true})
    }

    render() {
        return (
            <section className='checkout'>
                <MetaTags>
                    <title>{this.props.translate.i18n.PAGES.SHOPPING_CART.TITLE} {this.props.internal.footerData.shop.additional_page_title || ''}</title>
                </MetaTags>
                <div className='container'>
                    <div className='clearfix'>
                        <div className='row upperControls'>
                            {toJS(this.props.internal.shoppingCartData.items).length > 0 ? (
                                <div className='col-xs-6 text-right'>
                                    <a onClick={this.handleNext} className='btn btnBlueBackground'>
                                        {this.props.translate.i18n.COMPONENTS.CART_ITEMS.DELIVERY}
                                        <span className='icon icon-chevron-right' />
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* {this.state.updating ?  <div className='lds-dual-ring updateShoppingCart' /> : null} */}
                    {/* style={{opacity: `${this.state.updating ? 0 : 1}`}} */}
                    {toJS(this.props.internal.shoppingCartData.items).length > 0 ? (
                        <div className='cart-wrapper'>
                            <CartBlockHeader />
                            <CartBlockItem
                                cart
                                items={toJS(this.props.internal.shoppingCartData.items)}
                                updateCart={this.updateCart}
                            />
                            <CartBlockFooter
                                cart
                                total_with_vat={toJS(
                                    this.props.internal.shoppingCartData.products_total_with_vat
                                )}
                                total_without_vat={toJS(
                                    this.props.internal.shoppingCartData.products_total
                                )}
                                gifts={
                                    toJS(this.props.internal.shoppingCartData.gifts).length > 0
                                        ? true
                                        : false
                                }
                                selectedGift={this.props.internal.shoppingCartData.gift_id}
                                shipping={false}
                                payment_price={false}
                            />
                        </div>
                    ) : (
                        // null
                        <p className={'emptyShoppingCart'}>
                            {this.props.translate.i18n.PAGES.SHOPPING_CART.EMPTY_SHOPPING_CART}
                        </p>
                    )}
                    <div className='clearfix'>
                        <div className='row'>
                            <div className='col-xs-6'>
                                <a href='/' className='btn btnWhiteBackground'>
                                    <span className='icon icon-chevron-left' />{' '}
                                    {
                                        this.props.translate.i18n.COMPONENTS.CART_ITEMS
                                            .BACK_TO_PRODUCTS
                                    }
                                </a>
                            </div>
                            {toJS(this.props.internal.shoppingCartData.items).length > 0 ? (
                                <div className='col-xs-6 text-right'>
                                    <a onClick={this.handleNext} className='btn btnBlueBackground'>
                                        {this.props.translate.i18n.COMPONENTS.CART_ITEMS.DELIVERY}
                                        <span className='icon icon-chevron-right' />
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default inject('internal', 'translate')(observer(CartItems))
