//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api
import { api } from '../../../utils/api'

//style
import './SearchWrapper.scss'

//custom components

//material-ui

//other libraries
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

class SearchWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            founded: {
                products: [],
                categories: []
            },
            inputText: '',
            renderResultList: false,
            loader: false,
            notFound: false
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickOutsideOfSearchWrapper)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpen) {
            setTimeout(() => {
                document.getElementById('searchTerm').focus();
            }, 100);
        }
    }
    
    cnt = 0
    handleClickOutsideOfSearchWrapper = e => {
        if (this.props.isOpen === true) {
            this.cnt++
            if (!document.getElementById('searchWrapper').contains(e.target)) {
                if (this.cnt % 2 === 0) {
                    this.props.closeDialog()
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutsideOfSearchWrapper)
    }

    handleClarInput = () => {
        this.setState({
            founded: {
                products: [],
                categories: []
            },
            inputText: '',
            loader: false,
            renderResultList: false,
            notFound: false
        })
    }

    fistTime = true

    onChageSearch = event => {
        this.setState({ notFound: false })

        let inputText = event.target.value
        // if(this.fistTime) {
        //     inputText = ""
        //     this.fistTime = false
        // }

        this.setState({ loader: true, inputText: inputText }, () => {
            let inputTextTmp = this.state.inputText
            if (inputText.length > 2) {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    this.globalSearch(inputText)
                }, 650)
                this.setState({ renderResultList: true })
            } else {
                this.setState({ renderResultList: false, founded: [] })
                if (!this.state.loader) {
                    this.setState({ founded: [], notFound: true })
                }
            }
            // this.updateUrl('search', inputText)
        })
    }

    globalSearch = async inputText => {
        this.setState({founded: []});
        let load_data = await api({
            method: 'GET',
            url: `search`,
            params: {
                searchterm: inputText
            }
        })

        if (load_data.success === true) {
            if (load_data.data.message === '') {
                this.setState({ founded: load_data.data.data, loader: false })
            } else {
                this.setState({ notFound: true, loader: false, founded: [] })
            }
        }
    }

    handleGoTo = url => {
        // this.props.internal.setGlobalLoader(true)
        this.handleClarInput()

        // let paramsArray = url.split('/')
        // let matchPrams = this.props.internal.routerData.match.params
        // let indexParamsArray = 0
        // for (let i in matchPrams) {
        //     matchPrams[i] = undefined
        //     if (indexParamsArray < paramsArray.length) {
        //         matchPrams[i] = paramsArray[indexParamsArray]
        //         indexParamsArray++
        //     }
        // }
        // this.props.internal.routerData.match.params = matchPrams
        // this.props.internal.routerData.match.url = slug;
        // this.props.internal.routerData.location.pathname = slug;
        // window.location.href = `/${url}`
        this.props.internal.routerData.history.push(`/${url}`);
        this.props.closeDialog()
        
    }

    render() {
        return (
            <div
                className={`search-wrapper ${this.props.isOpen ? 'open' : ''}`}
                id={'searchWrapper'}
            >
                <input
                    className='form-control'
                    placeholder={this.props.translate.i18n.GLOBAL.TEXT.SEARCH}
                    value={this.state.inputText}
                    name={'searchTerm'}
                    onChange={this.onChageSearch}
                    id='searchTerm'
                    type={'text'}
                    autoComplete='new-password'
                    ref={ref => (this.searchterm = ref)}
                    autoFocus
                />
                {this.state.loader && this.state.renderResultList ? (
                    <div className='lds-dual-ring' />
                ) : null}
                {!this.state.loader &&
                this.state.renderResultList &&
                this.state.inputText !== '' ? (
                    <div onClick={this.handleClarInput}>
                        <Icon path={mdiClose} size={1.2} className={'clearIcon'} />
                    </div>
                ) : (
                    ''
                )}

                <div
                    className={`search-results ${this.state.renderResultList ? 'setPadding' : ''}`}
                >
                    {this.state.notFound && (
                        <p style={{ marginBottom: 0 }}>
                            {this.props.translate.i18n.GLOBAL.ALERTS.SEARCH_RESULT_NOT_FOUND}
                        </p>
                    )}

                    <div className='search-result-items'>
                        {this.state.renderResultList &&
                        this.state.founded &&
                        this.state.founded.products &&
                        this.state.founded.products.length ? (
                            <>
                                <div className='title h4'>
                                    {this.props.translate.i18n.GLOBAL.SEARCH.PRODUCTS}{' '}
                                </div>
                                <ul>
                                    {this.state.renderResultList &&
                                        this.state.founded &&
                                        this.state.founded.products &&
                                        this.state.founded.products.map((item, index) => {
                                            return (
                                                <li key={item.id}>
                                                    <a onClick={() => this.handleGoTo(item.url)}>
                                                        {/* <span className='id'>{item.id}</span>{' '} */}
                                                        <span className='name'>{item.title}</span>{' '}
                                                        <span className='categories'>
                                                            {item.description}
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                </ul>
                                <div className={'text-center'}>
                                    <a href={`/search?searchTerm=${this.state.inputText}`} className="btn btnBlueBackground my-4">Zobraziť ďalšie produkty</a>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className='search-result-items'>
                        {this.state.renderResultList &&
                        this.state.founded &&
                        this.state.founded.categories &&
                        this.state.founded.categories.length ? (
                            <>
                                <div className='title h4'>
                                    {this.props.translate.i18n.GLOBAL.SEARCH.CATEGORIES}{' '}
                                </div>
                                <ul>
                                    {this.state.renderResultList &&
                                        this.state.founded &&
                                        this.state.founded.categories &&
                                        this.state.founded.categories.map((item, index) => {
                                            return (
                                                <li key={item.id}>
                                                    <a onClick={() => this.handleGoTo(item.url)}>
                                                        {/* <span className='id'>{item.id}</span>{' '} */}
                                                        <span className='name'>{item.title}</span>{' '}
                                                        <span className='categories'>
                                                            {item.description}
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                </ul>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(SearchWrapper))
