//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//api
import { api } from '../../../utils/api'

//styles
import './AboutMe.scss';

//custom components

//material-ui

//other libraries
// import { regexes } from '../../../utils/fuctions'

const TIMEOUT = 1000

class AboutMe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            firstName: '',
            lastName: '',
            billingCompanyName: '',
            billingIdNo: '',
            billingIdTax: '',
            billingIdVat: '',
            billingZip: '',
            billingCity: '',
            billingCountry: '',
            billingEmail: '',
            billingPhone: '',
            consent_gdpr: false,
            consent_newsletter: false,
            billingStreetName: '',

            billing_equals_delivery: false,

            deliveryFirstName: '',
            deliveryLastName: '',
            deliveryCompanyName: '',
            deliveryZip: '',
            deliveryCity: '',
            deliveryCountry: '',
            deliveryEmail: '',
            deliveryPhone: '',
            deliveryStreetName: '',

            errors: {
                firstName: false,
                lastName: false,
                billingCompanyName: false,
                billingIdNo: false,
                billingIdTax: false,
                billingIdVat: false,
                billingZip: false,
                billingCity: false,
                billingCountry: false,
                billingEmail: false,
                billingPhone: false,
                billingStreetName: false,

                deliveryFirstName: false,
                deliveryLastName: false,
                deliveryZip: false,
                deliveryCity: false,
                deliveryCountry: false,
                deliveryEmail: false,
                deliveryPhone: false,
                deliveryStreetName: false,
            }
        }
    }

    componentDidMount() {
        // console.log("USER from STORE", toJS(this.props.internal.user.detail.data))
        this.props.internal.setGlobalLoader(true)
        Promise.all([
            
            this.loadUserData(),
            this.getCountriesCode(),
        ]).then( () => {
            this.setState({loading: false})
            this.props.internal.setGlobalLoader(false)
        })
       
    }

    loadUserData = async () => {
        // this.props.internal.setGlobalLoader(true)
        let load_user_data = await api({
            method: 'GET',
            url: 'account/user',
            authorization: this.props.internal.user.acces_token,

            // TODO: vymazat
            // postman: true
        })

        // console.log("load_user_data", load_user_data)
        if (load_user_data.success === true) {
            this.setState({
                firstName: load_user_data.data.data !== null && load_user_data.data.data.first_name ? load_user_data.data.data.first_name : "",
                lastName: load_user_data.data.data !== null && load_user_data.data.data.last_name ? load_user_data.data.data.last_name : "",
                consent_gdpr: !! (load_user_data.data.data !== null && load_user_data.data.data.consent_gdpr_at),
                consent_newsletter: !! (load_user_data.data.data !== null && load_user_data.data.data.consent_newsletter_at),
                billingCompanyName: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_company_name && load_user_data.data.data.data.billing_company_name !== null ? load_user_data.data.data.data.billing_company_name : "",
                billingZip: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_zip && load_user_data.data.data.data.billing_zip !== null ? load_user_data.data.data.data.billing_zip : "",
                billingCity: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_city && load_user_data.data.data.data.billing_city  !== null ? load_user_data.data.data.data.billing_city : "",
                billingCountry: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_country_id && load_user_data.data.data.data.billing_country_id !== null ? load_user_data.data.data.data.billing_country_id : 205,
                billingEmail: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_email && load_user_data.data.data.data.billing_email !== null ? load_user_data.data.data.data.billing_email : "",
                billingPhone: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_phone && load_user_data.data.data.data.billing_phone !== null ? load_user_data.data.data.data.billing_phone : "",
                billingStreetName: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_street && load_user_data.data.data.data.billing_street !== null ? load_user_data.data.data.data.billing_street : "",
                billingIdNo: load_user_data.data.data.data.billing_id_no || '' ,
                billingIdTax: load_user_data.data.data.data.billing_id_tax || '',
                billingIdVat: load_user_data.data.data.data.billing_id_vat || '',

                // billing_equals_delivery: load_user_data.data.data.data !== null && load_user_data.data.data.data.billing_equals_delivery && load_user_data.data.data.data.billing_equals_delivery !== null ? load_user_data.data.data.data.billing_equals_delivery : true,
                billing_equals_delivery: load_user_data.data.data.data !== null ? load_user_data.data.data.data.billing_equals_delivery : true,


                deliveryFirstName: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_first_name && load_user_data.data.data.data.delivery_first_name !== null ? load_user_data.data.data.data.delivery_first_name : "",
                deliveryLastName: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_last_name && load_user_data.data.data.data.delivery_last_name !== null ? load_user_data.data.data.data.delivery_last_name : "",
                deliveryCompanyName: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_company_name && load_user_data.data.data.data.delivery_company_name !== null ? load_user_data.data.data.data.delivery_company_name : "",
                deliveryZip: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_zip && load_user_data.data.data.data.delivery_zip !== null ? load_user_data.data.data.data.delivery_zip : "",
                deliveryCity: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_city && load_user_data.data.data.data.delivery_city !== null ? load_user_data.data.data.data.delivery_city : "",
                deliveryCountry: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_country_id && load_user_data.data.data.data.delivery_country_id !== null ? load_user_data.data.data.data.delivery_country_id : "",
                deliveryEmail: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_email && load_user_data.data.data.data.delivery_email !== null ? load_user_data.data.data.data.delivery_email : "",
                deliveryPhone: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_phone && load_user_data.data.data.data.delivery_phone !== null ? load_user_data.data.data.data.delivery_phone : "",
                deliveryStreetName: load_user_data.data.data.data !== null && load_user_data.data.data.data.delivery_street && load_user_data.data.data.data.delivery_street !== null ? load_user_data.data.data.data.delivery_street : "",
                
            })
            
        }
    }

    getCountriesCode = async() => {
        let load_countries = await api ({
            method: 'GET',
            url: 'countries',
        })

        // console.log("load_countries", load_countries)
        if(load_countries.success === true) {
            this.props.internal.setCountries(load_countries.data.data)
        }
    }

    timeout = null
    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    handleChangeDeliveryData = (prop, value) => {
        value = value.target.value

        this.setState({[prop]: value})

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.updateUserData()
        }, TIMEOUT)
        // console.log('prop', prop, '\nvalue', value.target.value)
    }

    handeChageBilligEqualsDelivery = (prop, value) => {
        this.setState({ [prop]: !value.target.checked })

        this.timeout = setTimeout(() => {
            this.updateUserData()
        }, 1000)
    }

    handleConsentChange = (prop, value) => {
        this.setState({ [prop]: value.target.checked }, () => this.updateUserData())
    }

    updateUserData = async () => {
        // @rs disabled cause it aint effective
        // if(!isEmail) {
        //     // console.log("billingEmail")
        //     this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.WRONG_EMAIL_FORMAT, true)
        //     return
        // }

        // if(!this.state.billing_equals_delivery && !isEmail2) {
        //     // console.log("deliveryEmail")
        //     this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.WRONG_EMAIL_FORMAT, true)
        //     return
        // }

        this.setState({
            errors: {
                firstName: this.state.firstName === '',
                lastName: this.state.lastName === '',
                billingCompanyName: false,  //do not chceck for errors by default
                billingIdNo: false,          //do not chceck for errors by default
                billingIdTax: false,          //do not chceck for errors by default
                billingIdVat: false,          //do not chceck for errors by default
                billingZip: !/^(?=.*[0-9])[ 0-9]+$/.test(this.state.billingZip),
                billingCity:  this.state.billingCity === '' ,
                billingCountry:  this.state.billingCountry === '' ,
                billingEmail: this.state.billingEmail === '',
                billingPhone: !/^(?=.*[0-9])[+ 0-9]+$/.test(this.state.billingPhone),
                billingStreetName: this.state.billingStreetName === '',

                deliveryFirstName: !this.state.billing_equals_delivery && this.state.deliveryFirstName === '' ,
                deliveryLastName: !this.state.billing_equals_delivery && this.state.deliveryLastName === '' ,
                deliveryZip: !this.state.billing_equals_delivery && !/^(?=.*[0-9])[ 0-9]+$/.test(this.state.deliveryZip) ,
                deliveryCity: !this.state.billing_equals_delivery && this.state.deliveryCity === '' ,
                deliveryCountry: !this.state.billing_equals_delivery && this.state.deliveryCountry === '' ,
                deliveryEmail: !this.state.billing_equals_delivery && (this.state.deliveryEmail === '')  ,
                deliveryPhone: !this.state.billing_equals_delivery && !/^(?=.*[0-9])[+ 0-9]+$/.test(this.state.deliveryPhone) ,
                deliveryStreetName: !this.state.billing_equals_delivery && this.state.deliveryStreetName === '' ,
            }
        })

        let allowUpdate = true
        for (const key of Object.keys(this.state.errors)) {
            if( this.state.errors[key] ) {
                if(!this.state.billing_equals_delivery){
                    allowUpdate = false
                }
                else if(!key.includes('delivery')) {
                    allowUpdate = false
                }
            }
        }

        if(allowUpdate) {

            let update_data = await api({
                url: 'account/user',
                method: 'PATCH',

                //TODO: vymazat ked bude na testovacom
                // postman: true,
                params: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    consent_gdpr: this.state.consent_gdpr,
                    consent_newsletter: this.state.consent_newsletter,
                    data: {
                        billing_first_name: this.state.firstName,
                        billing_last_name: this.state.lastName,
                        billing_email: this.state.billingEmail,
                        billing_phone: this.state.billingPhone,
                        billing_city: this.state.billingCity,
                        billing_zip: this.state.billingZip,
                        billing_country_id: this.state.billingCountry,
                        billing_company_name: this.state.billingCompanyName,
                        billing_street: this.state.billingStreetName,

                        billing_id_no: this.state.billingIdNo,
                        billing_id_tax: this.state.billingIdTax,
                        billing_id_vat: this.state.billingIdVat,

                        billing_equals_delivery: this.state.billing_equals_delivery,

                        delivery_email: this.state.deliveryEmail,
                        delivery_phone: this.state.deliveryPhone,
                        delivery_city: this.state.deliveryCity,
                        delivery_zip: this.state.deliveryZip,
                        delivery_country_id: this.state.deliveryCountry,
                        delivery_street: this.state.deliveryStreetName,
                        delivery_first_name: this.state.deliveryFirstName,
                        delivery_last_name: this.state.deliveryLastName
                    }
                }
            })

            // console.log('UPDATE USER data', update_data)
            if (update_data.success === true) {
                this.setState({
                    firstName: update_data.data.data !== null && update_data.data.data.first_name ? update_data.data.data.first_name : "",
                    lastName: update_data.data.data !== null && update_data.data.data.last_name ? update_data.data.data.last_name : "",
                    consent_gdpr: !! (update_data.data.data !== null && update_data.data.data.consent_gdpr_at),
                    consent_newsletter: !! (update_data.data.data !== null && update_data.data.data.consent_newsletter_at),
                    billingCompanyName: update_data.data.data.data !== null && update_data.data.data.data.billing_company_name && update_data.data.data.data.billing_company_name !== null ? update_data.data.data.data.billing_company_name : "",
                    billingZip: update_data.data.data.data !== null && update_data.data.data.data.billing_zip && update_data.data.data.data.billing_zip !== null ? update_data.data.data.data.billing_zip : "",
                    billingCity: update_data.data.data.data !== null && update_data.data.data.data.billing_city && update_data.data.data.data.billing_city  !== null ? update_data.data.data.data.billing_city : "",
                    billingCountry: update_data.data.data.data !== null && update_data.data.data.data.billing_country_id && update_data.data.data.data.billing_country_id !== null ? update_data.data.data.data.billing_country_id : 205,
                    billingEmail: update_data.data.data.data !== null && update_data.data.data.data.billing_email && update_data.data.data.data.billing_email !== null ? update_data.data.data.data.billing_email : "",
                    billingPhone: update_data.data.data.data !== null && update_data.data.data.data.billing_phone && update_data.data.data.data.billing_phone !== null ? update_data.data.data.data.billing_phone : "",
                    billingStreetName: update_data.data.data.data !== null && update_data.data.data.data.billing_street && update_data.data.data.data.billing_street !== null ? update_data.data.data.data.billing_street : "",

                    // billing_equals_delivery: update_data.data.data.data !== null && update_data.data.data.data.billing_equals_delivery && update_data.data.data.data.billing_equals_delivery !== null ? update_data.data.data.data.billing_equals_delivery : true,
                    billing_equals_delivery: update_data.data.data.data !== null ? update_data.data.data.data.billing_equals_delivery : true,


                    deliveryFirstName: update_data.data.data.data !== null && update_data.data.data.data.delivery_first_name && update_data.data.data.data.delivery_first_name !== null ? update_data.data.data.data.delivery_first_name : "",
                    deliveryLastName: update_data.data.data.data !== null && update_data.data.data.data.delivery_last_name && update_data.data.data.data.delivery_last_name !== null ? update_data.data.data.data.delivery_last_name : "",
                    deliveryCompanyName: update_data.data.data.data !== null && update_data.data.data.data.delivery_company_name && update_data.data.data.data.delivery_company_name !== null ? update_data.data.data.data.delivery_company_name : "",
                    deliveryZip: update_data.data.data.data !== null && update_data.data.data.data.delivery_zip && update_data.data.data.data.delivery_zip !== null ? update_data.data.data.data.delivery_zip : "",
                    deliveryCity: update_data.data.data.data !== null && update_data.data.data.data.delivery_city && update_data.data.data.data.delivery_city !== null ? update_data.data.data.data.delivery_city : "",
                    deliveryCountry: update_data.data.data.data !== null && update_data.data.data.data.delivery_country_id && update_data.data.data.data.delivery_country_id !== null ? update_data.data.data.data.delivery_country_id : "",
                    deliveryEmail: update_data.data.data.data !== null && update_data.data.data.data.delivery_email && update_data.data.data.data.delivery_email !== null ? update_data.data.data.data.delivery_email : "",
                    deliveryPhone: update_data.data.data.data !== null && update_data.data.data.data.delivery_phone && update_data.data.data.data.delivery_phone !== null ? update_data.data.data.data.delivery_phone : "",
                    deliveryStreetName: update_data.data.data.data !== null && update_data.data.data.data.delivery_street && update_data.data.data.data.delivery_street !== null ? update_data.data.data.data.delivery_street : "",
                })
                this.props.internal.setSnackbarData(update_data.data.message, true)
            }
            else {
                if(update_data.data.data.errors) {
                    this.setState(prevState => ({
                        errors: {
                            ...prevState.errors,
                            billingEmail: !!update_data.data.data.errors['data.billing_email'],
                            deliveryEmail: !!(update_data.data.data.errors['data.delivery_email'])
                        }
                    }))
                }
            }
        }
        else {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.REQUIRED_FIELDS_IS_EMPTY)
        }
    }
    getTermsAndConditionsAlias = () => {
        const { topMenu, bottomMenu } = toJS(this.props.internal)
        let alias = null
        for (let i in topMenu) {
            if (topMenu[i].frontend_menu_type_id === 5) {
                alias = topMenu[i].alias
            }
        }
        for (let i in bottomMenu) {
            if (bottomMenu[i].frontend_menu_type_id === 5) {
                alias = bottomMenu[i].alias
            }
        }
        
        return alias
    }
    
    
    getGDPRAlias = () => {
        const { topMenu, bottomMenu } = toJS(this.props.internal)
        let alias = null
        for (let i in topMenu) {
            if (topMenu[i].frontend_menu_type_id === 8) {
                alias = topMenu[i].alias
            }
        }
        for (let i in bottomMenu) {
            if (bottomMenu[i].frontend_menu_type_id === 8) {
                alias = bottomMenu[i].alias
            }
        }
        
        return alias
    }

    render() {
        const { countries } = toJS(this.props.internal)
        // console.log("state", this.state)
        return this.state.loading ? (
            <div>
                <div className='row'>
                    <div className={'col-xs-12'}>
                        <div className='lds-dual-ring loging' />
                    </div>
                </div>
            </div>
        ) : (
                <div>
                    <div className='row'>
                        <div className={'col-sm-12 col-md-6'}>
                            <div className={'row'}>
                                <div className='col-md-12'>
                                    <h4>
                                        {this.props.translate.i18n.PAGES.MY_ACCOUNT.BILLING_ADDRESS}
                                    </h4>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.firstName}
                                            className={`form-control ${this.state.errors.firstName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.FIRST_NAME +
                                                ' *'
                                            }
                                            required
                                            onChange={val =>
                                                this.handleChangeDeliveryData('firstName', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.lastName}
                                            className={`form-control ${this.state.errors.lastName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.LAST_NAME +
                                                ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('lastName', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingCompanyName}
                                            className={`form-control ${this.state.errors.billingCompanyName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.COMPANY_NAME
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingCompanyName', val)
                                            }
                                        />
                                    </div>
                                </div>

                                {/*
                                @rs added inputs for ico, dic, dph
                                */}
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingIdNo}
                                            className={`form-control ${this.state.errors.billingIco ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.ICO
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingIdNo', val)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingIdTax}
                                            className={`form-control ${this.state.errors.billingDic ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DIC
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingIdTax', val)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingIdVat}
                                            className={`form-control ${this.state.errors.billingDph ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DPH
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingIdVat', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingStreetName}
                                            className={`form-control ${this.state.errors.billingStreetName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.STREET_NAME +
                                                ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingStreetName', val)
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingCountry}
                                            className='form-control'
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.COUNTRY + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingCountry', val)
                                            }
                                        />
                                    </div>
                                </div> */}
                                <div className='col-md-6 countries aboutMe'>
                                    <div className='form-group'>
                                        <select
                                            className={`classic2 ${this.state.errors.billingCountry ? 'invalid' : ''}`}
                                            onChange={val =>
                                                this.handleChangeDeliveryData(
                                                    'billingCountry',
                                                    val
                                                )
                                            }
                                            defaultValue={this.state.billingCountry !== '' ? this.state.billingCountry : '0'}
                                        >
                                            {countries.map((country, index) => {
                                                return (
                                                    <option key={index} value={country.id} >
                                                    {/* selected={this.state.billingCountry === country.id ? true : false} */}
                                                        {country.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingZip}
                                            className={`form-control ${this.state.errors.billingZip ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.ZIP + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingZip', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-8'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingCity}
                                            className={`form-control ${this.state.errors.billingCity ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.CITY + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingCity', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingEmail}
                                            className={`form-control ${this.state.errors.billingEmail ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.EMAIL + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingEmail', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.billingPhone}
                                            className={`form-control ${this.state.errors.billingPhone ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.PHONE + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('billingPhone', val)
                                            }
                                        />
                                    </div>
                                </div>
                                {/* toggles whether you billing address/information matches the delivery one or not */}
                                <div className='col-md-12'>
                                    <span className='checkbox'>
                                        <input
                                            type='checkbox'
                                            id='billing_equals_delivery'
                                            defaultChecked={!this.state.billing_equals_delivery}
                                            onChange={val =>
                                                this.handeChageBilligEqualsDelivery(
                                                    'billing_equals_delivery',
                                                    val
                                                )
                                            }
                                        />
                                        <label htmlFor='billing_equals_delivery'>
                                            {
                                                this.props.translate.i18n
                                                    .COMPONENTS.DELIVERY_FORM
                                                    .BILLING_IS_DELIVERY
                                            }
                                        </label>
                                    </span>
                                </div>
                                <div className='col-md-12'>
                                    <h4 className={'mt-20'}>
                                        {this.props.translate.i18n.PAGES.MY_ACCOUNT.CONSENTS}
                                    </h4>
                                </div>
                                <div className='col-md-12'>
                                    <span className="checkbox" >
                                        <input type="checkbox" id='consent_gdpr' checked={this.state.consent_gdpr} onChange={(val) => this.handleConsentChange('consent_gdpr',val)}/>
                                        <label htmlFor='consent_gdpr' className={`consent-label- ${this.state.consent_gdpr ? '' : 'text-red'}`}>
                                            {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_PART_ONE}
                                            <a href={this.getTermsAndConditionsAlias()} target="_blank">{this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_TOS}</a>
                                            {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_AND}
                                            <a href={this.getGDPRAlias()} target="_blank">{this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_GDPR_GDPR}</a>
                                        </label>
                                    </span>
                                    <span className="checkbox" >
                                        <input type="checkbox" id='consent_newsletter' checked={this.state.consent_newsletter} onChange={(val) => this.handleConsentChange('consent_newsletter',val)}/>
                                        <label htmlFor='consent_newsletter' className={'consent-label-'}>
                                            {this.props.translate.i18n.COMPONENTS.REGISTER_FORM.CONSENT_NEWSLETTER}
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* if billing info does not match the delivery one, show separate inputs for delivery info */}
                        {this.state.billing_equals_delivery ?
                            null
                            :
                            <div className={'col-sm-12 col-md-6'}>
                            <div className={'row'}>
                                <div className='col-md-12'>
                                    <h4>
                                        {this.props.translate.i18n.PAGES.MY_ACCOUNT.DELIVERY_ADDRESS}
                                    </h4>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryFirstName}
                                            className={`form-control ${this.state.errors.deliveryFirstName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.FIRST_NAME
                                                // +' *'
                                            }
                                            required
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryFirstName', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryLastName}
                                            className={`form-control ${this.state.errors.deliveryLastName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.LAST_NAME
                                                // +' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryLastName', val)
                                            }
                                        />
                                    </div>
                                </div>

                                {/* <div className='col-md-12'>
                        <div className='form-group'>
                            <input
                                type='text'
                                value={this.state.deliveryCompanyName}
                                className='form-control'
                                placeholder={this.props.translate.i18n.GLOBAL.CONTACT.COMPANY_NAME}
                                onChange={val =>
                                    this.handleChangeDeliveryData('deliveryCompanyName', val)
                                }
                            />
                        </div>
                    </div> */}
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryStreetName}
                                            className={`form-control ${this.state.errors.deliveryStreetName ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.STREET_NAME
                                                // +' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryStreetName', val)
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryCountry}
                                            className='form-control'
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.COUNTRY
                                                // + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryCountry', val)
                                            }
                                        />
                                    </div>
                                </div> */}
                                <div className='col-md-6 countries aboutMe'>
                                    <div className='form-group'>
                                        <select
                                            className={`classic2 ${this.state.errors.deliveryCountry ? 'invalid' : ''}`}
                                            onChange={val =>
                                                this.handleChangeDeliveryData(
                                                    'deliveryCountry',
                                                    val
                                                )
                                            }
                                            defaultValue={this.state.deliveryCountry !== '' ? this.state.deliveryCountry : '0'}
                                        >
                                            {countries.map((country, index) => {
                                                return (
                                                    <option key={index} value={country.id} >
                                                    {/* selected={this.state.deliveryCountry === country.id ? true : false} */}
                                                        {country.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryZip}
                                            className={`form-control ${this.state.errors.deliveryZip ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.ZIP
                                                // + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryZip', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-8'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryCity}
                                            className={`form-control ${this.state.errors.deliveryCity ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.CITY
                                                // + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryCity', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryEmail}
                                            className={`form-control ${this.state.errors.deliveryEmail ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.EMAIL
                                                // + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryEmail', val)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            value={this.state.deliveryPhone}
                                            className={`form-control ${this.state.errors.deliveryPhone ? 'invalid' : ''}`}
                                            placeholder={
                                                this.props.translate.i18n.GLOBAL.CONTACT.PHONE
                                                // + ' *'
                                            }
                                            onChange={val =>
                                                this.handleChangeDeliveryData('deliveryPhone', val)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            )
    }
}
export default inject('internal', 'translate')(observer(AboutMe))
