//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api
import { api } from '../../../utils/api';

//style
import './Rating.scss';

//custom components
import CommentUser from '../../complex/CommentUser/CommentUser';
import RatingStars from '../../basic/RatingStars/RatingStars';

//material-ui

//other libraries
import Rating from 'react-rating';

class RatingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleAllComents: false,
            userIsLogged: false,
            userName: "",
            userEmail: "",
            userComment: "",
            myNumStars: 0,
            addingReview: false,
            can_add_review: props.my_review === null || typeof props.my_review === 'undefined',
            reviews: props.data.reviewData.data
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'))
        if(user && user.logged) {
            this.setState({userIsLogged: true})
        }
        else {
            this.setState({userIsLogged: false})
        }
    
        this.props.internal.setGlobalLoader(false)

    }

    handleShowAllComments = () => {
        this.setState({visibleAllComents: !this.state.visibleAllComents})
    }

    handleChangeInput = ({target}) => {
        this.setState({[target.name]: target.value})
    }

    handleAddComment = () => {
        let userName = this.state.userName;
        let userEmail = this.state.userEmail;
        let userComment = this.state.userComment;
        if(userComment === '') {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.REQUIRED_FIELDS_IS_EMPTY)
        }
        else if(this.state.myNumStars === 0) {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.NUM_STARS_IS_REQUIRED)
        }
        else {
            this.addComent()
                this.props.internal.productDetailRef.wrappedInstance.rerender()

        }
    }
    

    addComent = async() => {
        this.setState({addingReview: true})
        let product_alias = this.props.internal.routerData.match.params.alias

        //TODO: vymazat
        // product_alias = '57438394-some-product-name'

        let post_data = await api({
            url: `reviews/${product_alias}`,
            method: 'POST',
            authorization: this.props.internal.user.acces_token,

            //TODO: vymazat
            // postman: true,

            params: {
                content: this.state.userComment,
                stars: this.state.myNumStars
            }
        })


        if(post_data.success === true || (post_data.success=== false && post_data.status === 201)) {
            this.props.internal.setSnackbarData(post_data.data.message, true)
            this.setState({
                addingReview: false,
                userComment: "",
                userEmail: "",
                userName: "",
                can_add_review: false,
            })


            if (this.state.reviews.length) {

                let reviews = this.state.reviews;
                reviews.push(post_data.data.data)
                this.setState({
                    reviews: reviews
                });
                
            } else {
                // this.props.internal.productDetailRef.wrappedInstance.rerender()
            }

        }
        else {
            this.setState({addingReview: false, userComment: "", userEmail: "", userName: ""})
            this.props.internal.setSnackbarErrorData(post_data.data.message)
        }
    }

    handleChangeMyNumStars = (val) => {
        this.setState({myNumStars: val})
    }

 

    render() {
        const { reviewData } = this.props.data
        // reviewData.statistics.stars[4] = 8
        // reviewData.statistics.stars[1] = 3
        // reviewData.statistics.total_reviews = 13
        let onePercent = 100 / reviewData.statistics.total_reviews;
        return (
            <div className='row'>
                <div className='col-md-12'>
                {reviewData && reviewData !== null && reviewData.data.length > 0 ?
                    <div className='comments' >
                        <div className='rating'>
                            <div className={"averageRating"}>
                                <span className={'value'}>{(Math.round(reviewData.statistics.total_score * 10)/10).toFixed(1)}</span>
                                <RatingStars num={reviewData.statistics.total_score} average/>
                                <span className={'total_reviews'}>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.TOTAL_REVIEWS_1}{reviewData.statistics.total_reviews}{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.TOTAL_REVIEWS_2}</span>
                            </div>
                            <div className={'perNumStars'}>
                                <div>5&nbsp;<RatingStars num={reviewData.statistics.stars[5] || 0} forValue={5}/><span className={'grayBox'} style={{width: onePercent*(reviewData.statistics.stars[5] || 0)}}></span>{reviewData.statistics.stars[5] || 0}x</div>
                                <div>4&nbsp;<RatingStars num={reviewData.statistics.stars[4] || 0} forValue={4}/><span className={'grayBox'} style={{width: onePercent*(reviewData.statistics.stars[4] || 0)}}></span>{reviewData.statistics.stars[4] || 0}x</div>
                                <div>3&nbsp;<RatingStars num={reviewData.statistics.stars[3] || 0} forValue={3}/><span className={'grayBox'} style={{width: onePercent*(reviewData.statistics.stars[3] || 0)}}></span>{reviewData.statistics.stars[3] || 0}x</div>
                                <div>2&nbsp;<RatingStars num={reviewData.statistics.stars[2] || 0} forValue={2}/><span className={'grayBox'} style={{width: onePercent*(reviewData.statistics.stars[2] || 0)}}></span>{reviewData.statistics.stars[2] || 0}x</div>
                                <div>1&nbsp;<RatingStars num={reviewData.statistics.stars[1] || 0} forValue={1}/><span className={'grayBox'} style={{width: onePercent*(reviewData.statistics.stars[1] || 0)}}></span>{reviewData.statistics.stars[1] || 0}x</div>
                            </div>
                        </div>
                        {/* ${this.state.visibleAllComents ? 'all' : 'one'} */}
                        <div className={`comment-wrapper all`}>
                            {this.state.reviews && this.state.reviews.map((comment, index) => {
                                return <CommentUser key={index} reviewData={comment} showUserInfo={true}/>
                            })}

                        </div>

                        {/* <div className='comment-header'>
                            <a className='btn btn-clean-dark' onClick={this.handleShowAllComments}>
                                {this.state.visibleAllComents ?
                                    this.props.translate.i18n.PAGES.PRODUCT_DETAIL.SHOW_LESS_COMMENTS
                                    :
                                    this.props.translate.i18n.PAGES.PRODUCT_DETAIL.SHOW_ALL_COMMENTS
                                }
                            </a>
                        </div> */}


                    </div>
                    :
                    <h4 className={'text-center noReviews'}>{this.props.translate.i18n.GLOBAL.ALERTS.PRODUCT_DOESNT_HAVE_REVIEWS}</h4>
                }
                <div className='comments commentsEmpty' >
                {this.state.can_add_review ?
                <div className='comment-add' style={{position: 'relative'}}>
                        {this.state.addingReview ? <div className='lds-dual-ring updateReview' /> : null}
                            {this.state.userIsLogged ?


                                <form className={'row'} style={{opacity: this.state.addingReview ? 0 : 1}}>
                                    {/* <div className="form-group col-sm-12 col-md-6">
                                        <input type="text" className="form-control" name="userName" value={this.state.userName} onChange={this.handleChangeInput} placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.NAME} *`} />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6">
                                        <input type="email" className="form-control" name="userEmail" value={this.state.userEmail} onChange={this.handleChangeInput} placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.EMAIL} *`} />
                                    </div> */}

                                    <div className="form-group col-md-12 myRating" >
                                        <span>{this.props.translate.i18n.COMPONENTS.RATING.MY_RATING}</span>
                                       <Rating
                                            emptySymbol='fa fa-star-o '
                                            fullSymbol='fa fa-star '
                                            // onHover={this.handleChangeMyNumStars}
                                            onClick={this.handleChangeMyNumStars}
                                            initialRating={this.state.myNumStars}

                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <textarea rows={3} className="form-control" name="userComment" value={this.state.userComment} onChange={this.handleChangeInput}  placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.COMMENT} *`}></textarea>
                                    </div>
                                    <div className="clearfix text-center">
                                        <a onClick={this.handleAddComment} className="btn btn-main">{this.props.translate.i18n.GLOBAL.BUTTONS.ADD_REVIEW}</a>
                                    </div>
                                </form>


                                :
                                <div className='comment-reply-message'>
                                    <div className='h3 title'>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.LEAVE_COMMENT}</div>
                                    <p>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.LEAVE_COMMENT_DESCRIPTION}</p>
                                </div>
                            }
                        </div>
                        :
                        null}
                        </div>
                        
                </div>
                <div>
                </div>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(RatingComponent))
