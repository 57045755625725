//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './ThankYouPage.scss';


//custom components

//material-ui

//other libraries
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga4';
import $ from 'jquery'
import scrollTo from 'jquery.scrollto'


class ThankYouPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            status: null,
        }
    }

    componentDidMount() {
        const { internal, translate,...other } = this.props
        const isLocalhost = null !== window.location.origin.match(/\/localhost/);
        // console.log("this.props", this.props)

        this.setState({loading: false})
        // let routerData = {history: this.props.history, location: this.props.location, match: this.props.match}
        // this.props.internal.setRouterData(routerData);
        // // this.props.internal.setGlobalLoader(true)
        // let match = this.props.match.params
        // this.setState({status: match.status, loading: false})
        if(localStorage.getItem('order_detail')) {
            let order_detail = JSON.parse(localStorage.getItem('order_detail'))
            // console.log("tu", order_detail)
            // console.log("addTransaction", {
            //     id: order_detail.variable_symbol,
            //     affiliation: "",
            //     revenue: order_detail.total_with_vat_with_discount,
            //     shipping: order_detail.delivery_type_price_with_vat,
            //     tax: order_detail.total_with_vat_with_discount - order_detail.total,
            //     currency: 'EUR'
            // })

            // if (false === isLocalhost) {
                // ReactGA.initialize('UA-130881408-1')
                // ReactGA.plugin.require('ecommerce');
                // ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                //     id: order_detail.variable_symbol,
                //     affiliation: "",
                //     revenue: order_detail.total_with_vat_with_discount,
                //     shipping: order_detail.delivery_type_price_with_vat,
                //     tax: order_detail.total_with_vat_with_discount - order_detail.total,
                //     currency: 'EUR'
                // })
            // }
            // console.log("addTransaction", {
            //     id: order_detail.variable_symbol,
            //     affiliation: "",
            //     revenue: order_detail.total_with_vat_with_discount,
            //     shipping: order_detail.delivery_type_price_with_vat,
            //     tax: order_detail.total_with_vat_with_discount - order_detail.total,
            //     currency: 'EUR'
            // })

            if (false === isLocalhost) {
                ReactGA.event('purchase', {
                    transaction_id: order_detail.variable_symbol,
                    shipping: order_detail.delivery_type.price,
                    currency: 'EUR',
                    value: order_detail.total,
                    coupon: order_detail.coupon ? order_detail.coupon.code : null,
                    items: order_detail.items.map(item => ({
                        item_id: item.product ? item.product.code : item.static_set.name,
                        item_name: item.product ? item.product.name : item.static_set.name,
                        affiliation: 'Bekoshop.sk',
                        coupon: item.product && item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
                        item_category: item.product ? item.product.category_path_alt[0] : null,
                        item_category2: item.product && item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
                        item_category3: item.product && item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
                        price: item.total_price,
                    }))
                })
            }
            // console.log('ecommerce:send')
            localStorage.removeItem('order_detail')
        }
        this.props.internal.setGlobalLoader(false)


        setTimeout(() => {
            $(window).scrollTo('body', 500);
        }, 250);
    }

    render() {
        // ReactGA.plugin.execute('ecommerce', 'addTransaction', {

        // })
        return (
            this.state.loading ? null :
            <div  className="thankYouPage">

                <section className="main-header" >
                    <header>
                        <div className='container'>
                            <div className='row'>
                            <div className="col-xs-12 text-center">
                                {/* <h1>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.TITLE}</h1> */}
                                <h1>{this.props.data.title}</h1>
                            </div>
                            </div>

                        </div>
                    </header>
                </section>

                <section className={'content bgGrayLight'}>
                    <div className={'container'}>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className={'text-center'}>
                                    {ReactHtmlParser(this.props.data.content)}
                                    {/* {this.state.status === 'success' ? <p className={'text-center'}>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.PAYMENT.SUCCESS}</p> : null }
                                    {this.state.status === 'error' ? <p className={'text-center'}>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.PAYMENT.ERROR}</p> : null }
                                    {this.state.status === 'invalid' ? <p className={'text-center'}>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.PAYMENT.INVALID}</p> : null }
                                    {this.state.status === 'pending' ? <p className={'text-center'}>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.PAYMENT.PENDING}</p> : null }
                                    {this.state.status === 'missing' ? <p className={'text-center'}>{this.props.translate.i18n.PAGES.THANK_YOU_PAGE.PAYMENT.MISSING}</p> : null } */}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(ThankYouPage))
