//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './InviteFriendDialog.scss';

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { regexes } from '../../../utils/fuctions';

class InviteFriendDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: ""
        }
    }

    inviteFriend = async() => {
        let isEmail = regexes.email.test(this.state.email)

        if(!isEmail) {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.COMPONENTS.REGISTER_FORM.EMAIL_NOT_VALID)
            return
        }

        this.setState({loading: true})

        let load_data = await api({
            method: "POST",
            url: "account/invite",
            params: {
                email: this.state.email
            }
        })

        if(load_data.success === true) {
            this.setState({email: ""})
            this.props.internal.setSnackbarData(load_data.data.message, true)
            let inviteFriendDialog = document.getElementById("inviteFriendDialog")
            inviteFriendDialog.style.display = "none";
            document.body.classList.remove("modal-open")
            if(document.body.lastElementChild.classList.contains("modal-backdrop")) {
                document.body.lastElementChild.remove()
            }
        }
        else {
            let errors = load_data.data.data.errors;
            let resultError = ""
            for(let i in errors) {
                resultError += errors[i]+ " "
            }
            this.props.internal.setSnackbarErrorData(resultError)
        }

        this.setState({loading: false})


    }

    handleSetEmail = (field, val) => {
        this.setState({[field]: val.target.value})
    }


    render () {
        return (
            <div className='modal fade' id='inviteFriendDialog' role='dialog'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <button type='button' className='close' data-dismiss='modal' >
                                &times;
                            </button>
                            <h4 className='modal-title'>
                                {this.props.translate.i18n.COMPONENTS.INVITE_FRIEND.HEADER_TITLE}
                            </h4>
                        </div>

                        {this.state.loading ?
                            <div className={'modal-body'} >
                                <div className='lds-dual-ring setDogWatch' />
                            </div>
                            :
                            <div className='modal-body'>
                                <div>
                                    <span>{''}</span>
                                </div>
                                <div className='form-group friendEmail'>
                                    <input
                                        type='email'
                                        className={`form-control `}
                                        id='email'
                                        value={this.state.email}
                                        onChange={val =>
                                            this.handleSetEmail('email', val)
                                        }
                                        placeholder={this.props.translate.i18n.COMPONENTS.INVITE_FRIEND.EMAIL}
                                    />
                                </div>




                                <button
                                    type='button'
                                    className='btn btnWhiteBackground activeWatchDog'
                                    onClick={this.inviteFriend}

                                >
                                    {
                                        this.props.translate.i18n.COMPONENTS.INVITE_FRIEND.SEND_EMAIL
                                    }
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}

export default inject('internal', 'translate')(observer(InviteFriendDialog))
