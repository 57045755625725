//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './CommentUser.scss';

//api
import { api } from '../../../utils/api';

//custom components
import RatingStars from '../../basic/RatingStars/RatingStars';

//material-ui

//other libraries
import Icon from '@mdi/react';
import { mdiAccountCircle} from '@mdi/js'
import { formatDate } from '../../../utils/fuctions';
import Rating from 'react-rating';

import no_image from '../../../assets/images/no_image.png'

class CommentUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            content: this.props.reviewData.content || '',
            loading: false,
            myNumStars: this.props.reviewData.stars || 5
        }
    }

    handleEditReview = () => {
        this.setState({edit: !this.state.edit}, () => {
            if(this.state.edit === false) {
                this.updateReview()
            }
        })
        
    }

    handleChangeReviewContent = (e) => {
        this.setState({content: e.target.value})
    }

    updateReview = async() => {
        this.setState({loading: true})
        let update_review = await api({
            url: `account/reviews/${this.props.reviewData.id}`,
            method: 'PATCH',
            params: {
                content: this.state.content,
                stars: this.state.myNumStars
            }
        })

        if(update_review.success === true) {
            this.props.internal.setSnackbarData(update_review.data.message, true)
            this.setState({loading: false})
        }
    }

    handleChangeMyNumStars = (val) => {
        this.setState({myNumStars: val})
    }

    render () {
        // console.log("user comment", this.props)
        return (
            
            
            <div className='comment-block' style={{position: "relative"}}>
            {this.state.loading ? <div className='lds-dual-ring updateReview' /> : null}
            <div style={{opacity: this.state.loading ? 0 : 1}}>
                <div className='comment-user'>
                    <div>
                        {this.props.reviewData.customer.photo ?
                            // <img src={this.props.reviewData.customer.photo} alt={this.props.reviewData.customer.name}/>
                            this.props.showUserInfo === true ?
                                <img src={this.props.reviewData.customer.photo} alt={this.props.reviewData.customer.username}/>
                                :
                                this.props.reviewData.product.image !== null
                                ?
                                <img src={this.props.reviewData.product.image} alt={this.props.reviewData.product.name} className={'productImage'}/>
                                :
                                <img src={no_image} alt={this.props.reviewData.product.name}/>
                            :
                            <Icon path={mdiAccountCircle} size={3} />
                        }
                    </div>
                    <div>
                        <h5>
                            {/* <span>{this.props.reviewData.customer.username}</span> */}
                            <span>{this.props.showUserInfo === true ? this.props.reviewData.customer.username : this.props.reviewData.product.name}</span>
                            <span className='pull-right'>
                            {this.props.editable && this.state.edit ?
                                <Rating
                                    emptySymbol='fa fa-star'
                                    fullSymbol='fa fa-star active'
                                    onClick={this.handleChangeMyNumStars}
                                    initialRating={this.state.myNumStars}
                                    // readonly={this.state.edit}
                                    className={'editableStars'}
                                />
                                :
                                <RatingStars num={this.state.myNumStars} userStars/>
                            }
                            
                            </span>
                            <small>{formatDate(this.props.reviewData.updated_at)}</small>
                        </h5>
                    </div>
                </div>

                <div className={`comment-desc ${this.props.editable && this.state.edit ? 'edit' : ''}`}>
                    
                        {this.props.editable && this.state.edit
                            ?
                            <textarea value={this.state.content} rows={5} className={'editReview'} onChange={this.handleChangeReviewContent}/>
                            :
                            // <p>{this.props.reviewData.content}</p>
                            <p>{this.state.content}</p>
                        }
                </div>
                {this.props.editable ?
                    <div>
                        <button className={'btn btnWhiteBackground'} onClick={this.handleEditReview}>
                        {this.state.edit
                            ?
                            this.props.translate.i18n.GLOBAL.BUTTONS.SAVE
                            :
                            this.props.translate.i18n.GLOBAL.BUTTONS.EDIT
                        }
                        </button>
                    </div>
                    :
                    null
                }
            </div>
        </div>
        )
    }
}

export default inject('internal', 'translate')(observer(CommentUser))
