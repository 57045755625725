// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../utils/api'

// components
import ProductsGridBreadcrumbs from '../ProductsGrid/ProductsGridBreadcrumbs'
import ProductsGridItems from '../ProductsGrid/ProductsGridItems'
import ProductsGridPriceFilter from '../ProductsGrid/ProductsGridPriceFilter'
import ProductsGridSortBy from '../ProductsGrid/ProductsGridSortBy'

// i18n
import t from '../../stories/i18n'

// libraries
import queryString from 'query-string'

// stores
import internalStore from '../../stories/internal'

// styles
import '../ProductsGrid/ProductsGrid.scss'
import 'rc-slider/assets/index.css'
import { debounce } from 'lodash'

class SearchPage extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: true,
      filter: false,
      price_from: 0,
      price_to: 4000,
      filtersIsUsed: false,
      filtersPhone: false,
      maxPrice: 4000,
      nextPageLoader: false,
      sort: 'name-asc',
      searchTerm: null,
      categoryPath: {},
    }
  }
  
  componentDidMount() {
    const setState = {}
    
    const { internal, translate, ...other } = this.props
    internalStore.setRouterData(other)
    
    if (internalStore.routerData.location.search) {
      let search = queryString.parse(internalStore.routerData.location.search)
      if (search.price_from && search.price_to) {
        // this.slider([search.price_from, search.price_to])
        setState.price_from = Number(search.price_from)
        setState.price_to = Number(search.price_to)
      }
      
      if (search.searchTerm) {
        setState.searchTerm = search.searchTerm
      }
    }
    
    
    let userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
    if (userFromLocalStorage && userFromLocalStorage.logged === true) {
      const { internal, ...other } = this.props
      internalStore.setRouterData(other)
    }
    
    window.addEventListener('scroll', this.handleScroll)
    
    window.addEventListener('click', this.handleClickOutOfFilters)
    
    this.setState(setState, () => this.loadSearch())
  }
  
  setAfterLoad = () => {
    this.setState({ maxPrice: this.props.data.prices.max })
    internalStore.setProductsForCategory(this.props.data.data)
    this.setState({ loading: false })
    
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('click', this.handleClickOutOfFilters)
  }
  
  hiddeMobileFilters = false
  
  handleClickOutOfFilters = e => {
    let filters = document.getElementById('filters')
    if (filters && filters.classList.contains('active')) {
      if (filters.contains(e.target)) {
      } else {
        if (this.hiddeMobileFilters) {
          filters.classList.remove('active')
          this.hiddeMobileFilters = false
          this.hadnelClosePhoneFilters()
        } else {
          this.hiddeMobileFilters = true
        }
      }
    }
  }
  
  nextPageWasLoaded = false
  
  handleScroll = e => {
    let lastProduct = document.querySelector('#products > div:last-child > div')
    if (null === lastProduct) {
      return
    }
    
    lastProduct = lastProduct.getBoundingClientRect()
    
    if (lastProduct.top > lastProduct.height * 2) {
      return
    }
    
    let nextPage = internalStore.allAboutListProducts.links.next
    
    if (nextPage !== null && ! this.nextPageWasLoaded) {
      this.loadNextPage(nextPage)
      this.nextPageWasLoaded = true
    }
  }
  
  loadSearch = debounce(async () => {
    // this.setState({ nextPageLoader: true })
    let search = queryString.parse(internalStore.routerData.history.location.search)
    
    let order;
    if (search.orderBy && search.orderDir) {
      order = [{ by: search.orderBy, dir: search.orderDir }]
    }
    
    let load_data = await api({
      method: 'GET',
      url: 'full-page-search',
      params: {
        ...search,
        searchTerm: this.state.searchTerm,
        page: 12,
        order
      },
    })
    
    if (load_data.success) {
      internalStore.setProductsForCategory(load_data.data.data)
      internalStore.setAllAboutListProducts(load_data.data)
      this.setState({
        maxPrice: load_data.data.prices.max,
        categoryPath: load_data.data.category_path,
      })
    }
    
    internalStore.setGlobalLoader(false)
    this.setState({ nextPageLoader: false, loading: false, filter: false })
  }, 500)
  
  loadNextPage = async url => {
    this.setState({ nextPageLoader: true })
    
    let load_next_page = await api({
      method: 'GET',
      url: '',
      useNextPage: true,
      nextPageUrl: url,
    })
    
    if (load_next_page.success === true) {
      internalStore.updateAllAboutListProducts({
        data: load_next_page.data.data,
        links: load_next_page.data.links,
        meta: load_next_page.data.meta,
      })
      
      let lastProduct = document
        .querySelector('#products > div:last-child > div')
        .getBoundingClientRect()
      
      if (lastProduct.top < lastProduct.height && null !== load_next_page.data.links.next) {
        this.nextPageWasLoaded = false
        
        this.handleScroll()
        
        return
      }
      
      this.nextPageWasLoaded = false
      this.setState({ nextPageLoader: false })
      
      window.init()
    }
  }
  
  setFilters = full_path => {
    this.setState({ filter: true, filtersIsUsed: true })
    // this.loadData(full_path)
    this.loadSearch()
  }
  
  slider = async e => {
    this.setState({ filter: true })
    
    const URLqueryString = internalStore.routerData.history.location.search
    let queries = queryString.parse(URLqueryString)
    queries.price_from = e[0]
    queries.price_to = e[1]
    
    let new_path = `?${queryString.stringify(queries)}`
    internalStore.routerData.history.push(new_path)
    
    // this.loadData(null, e)
    this.loadSearch()
  }
  
  handleSort = e => {
    this.setState({ filter: true, filtersIsUsed: true })
    
    const URLqueryString = internalStore.routerData.history.location.search
    let queries = queryString.parse(URLqueryString)
    
    // queries.orderBy = 'reatail_price';
    queries.orderBy = e.target.value.split('-')[0]
    queries.orderDir = e.target.value.split('-')[1]
    
    let new_path = `?${queryString.stringify(queries)}`
    internalStore.routerData.history.push(new_path)
    
    // this.loadData(null, e)
    this.loadSearch()
  }
  
  handleActiveFilterPhone = () => {
    this.setState({ filtersPhone: true }) //tooltips
    document.getElementById('productsGrid').style.pointerEvents = 'none'
    document.getElementById('productsGrid').style.opacity = '0.5'
    document.body.style.overflowY = 'hidden'
  }
  
  hadnelClosePhoneFilters = () => {
    this.setState({ filtersPhone: false }) //tooltips
    let filters = document.getElementById('filters')
    filters.classList.remove('active')
    document.getElementById('productsGrid').style.pointerEvents = 'auto'
    document.getElementById('productsGrid').style.opacity = '1'
    document.body.style.overflowY = 'auto'
  }
  
  renderChips = () => {
    const URLqueryString = window.location.search
    let queries = queryString.parse(URLqueryString)
    
    let params = []
    let paramsvalues = []
    
    let renderValues = []
    for (let i in queries) {
      if (i === 'price_to') {
        renderValues.push(
          <div className="chip">
                        <span>
                            Cena do: {queries[i]} €
                            <i
                              onClick={() => this.handleCancelFilter(i)}
                              className="fa fa-times"
                              aria-hidden="true"
                            />
                        </span>
          </div>,
        )
      } else if (i === 'price_from') {
        renderValues.push(
          <div className="chip">
                        <span>
                            Cena od: {queries[i]} €
                            <i
                              onClick={() => this.handleCancelFilter(i)}
                              className="fa fa-times"
                              aria-hidden="true"
                            />
                        </span>
          </div>,
        )
      } else if (i === 'orderBy') {
        // renderValues.push(<div className="chip"><span>Zoradiť podľa: {this.props.translate.i18n.PAGES.PRODUCT_GRID[queries[i]]} <i onClick={() => this.handleCancelFilter(i)} className="fa fa-times" aria-hidden="true"></i></span></div> )
      } else if (i === 'orderDir') {
        // renderValues.push(<div className="chip"><span>Smer: {this.props.translate.i18n.PAGES.PRODUCT_GRID[queries[i]]}<i onClick={() => this.handleCancelFilter(i)} className="fa fa-times" aria-hidden="true"></i></span></div> )
      } else if (i !== 'price_to' && i !== 'price_from') {
        if (Array.isArray(queries[i])) {
          for (let k in queries[i]) {
            renderValues.push(
              <div className="chip">
                                <span>
                                    {params[i]} : {paramsvalues[queries[i][k]]}
                                  <i
                                    onClick={() => this.handleCancelFilter(i, k)}
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  />
                                </span>
              </div>,
            )
          }
        }
      }
    }
    return renderValues
  }
  
  handleCancelFilter = (parameter, val = undefined) => {
    const URLqueryString = internalStore.routerData.history.location.search
    let queries = queryString.parse(URLqueryString)
    
    for (let i in queries) {
      if (i === 'price_from') {
        delete queries[parameter]
        this.setState({ price_from: 0 })
        this.setState({ price_to: queries[i] })
      } else if (i === 'price_to') {
        delete queries[parameter]
        this.setState({ price_to: this.state.maxPrice })
        this.setState({ price_from: queries[i] })
      } else {
        if (val !== undefined) {
          //ked je v url pole(jeden parameter s viacerimi hodnotami, napr: 2=99&2=100&2=101)
          delete queries[parameter][val]
        } else {
          delete queries[parameter]
        }
      }
    }
    
    let new_path = `?${queryString.stringify(queries)}`
    
    internalStore.routerData.history.push(new_path)
    this.setFilters(new_path)
    // odkomentovat
    // this.setState({loading: true})
    
    // zakomentovat nefunguje rerender
    // this.props.internal.setGlobalLoader(true);
  }
  
  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.currentTarget.value, filter: true }, () => {
      const URLqueryString = internalStore.routerData.history.location.search
      let queries = queryString.parse(URLqueryString)
      queries.searchTerm = this.state.searchTerm
      
      let new_path = `?${queryString.stringify(queries)}`
      internalStore.routerData.history.push(new_path)
      
      this.loadSearch()
    })
  }
  
  render() {
    return this.state.loading ? null : (
      <div className={'cateogry'} id="scroling">
        <section className="main-header">
          <ProductsGridBreadcrumbs categoryPath={this.state.categoryPath}/>
          
          <ProductsGridSortBy
            listingStaticSets={false}
            defaultValue={'default-asc'}
            defaultDescription={t.i18n.GLOBAL.SORT.DEFAULT}
            handleActiveFilterPhone={this.handleActiveFilterPhone}
            handleSort={this.handleSort}
          />
        </section>
        
        <section
          className={`products ${
            internalStore.productsForCategory.length < 1 && this.state.filtersIsUsed
              ? 'emptyList'
              : ''
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-xs-12">
                <div id="filters" className={`filters `}>
                  <div className="filter-box active hidden-md hidden-lg ">
                    <div className="filter-content">
                      <div className={'phoneFilterMenu'}>
                        <div className="filtertMenuTitle">
                          {t.i18n.PAGES.CATEGORIES.FILTERS}
                        </div>
                        <div
                          className=""
                          onClick={this.hadnelClosePhoneFilters}
                        >
                                                        <span className="close-menu">
                                                            <i className="icon icon-cross"/>
                                                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <ProductsGridPriceFilter
                    filter={this.state.filter}
                    maxPrice={this.state.maxPrice}
                    priceFrom={this.state.price_from}
                    priceTo={this.state.price_to}
                    onAfterChange={this.slider}
                  />
                </div>
              </div>
              
              <div className={'col-md-10 col-xs-12'}>
                <div className="form-group searchTermWrapper">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.searchTerm}
                    onChange={this.handleSearchChange}
                  />
                </div>
                {internalStore.productsForCategory.length < 1 || this.state.searchTerm.length < 1 ? (
                  <p className={'emptyCategory'}>{t.i18n.GLOBAL.ALERTS.NO_PRODUCTS_FOUND}</p>
                ) : (
                  <ProductsGridItems
                    wrapperClassName={' '}
                    filter={this.state.filter}
                    listingStaticSets={false}
                    nextPageLoader={this.state.nextPageLoader}
                    products={internalStore.productsForCategory}
                    renderchips={this.renderChips}
                  />
                )}
              </div>
            
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default observer(SearchPage)
