//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//style
import './FilterParameters.scss';

//api

//custom components

//material-ui


//other libraries
import queryString from 'query-string';

class FilterParameters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeUrl: false,
            filters: [],
            urlValues: Array.from((new URLSearchParams(window.location.search.substring(1))).values())
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
      let urlValues = Array.from((new URLSearchParams(window.location.search.substring(1))).values())
      if (prevState.urlValues.length !== urlValues.length || false === prevState.urlValues.every(i => urlValues.includes(i))) {
            this.setState({urlValues})
        }
    }
    
    isClickable = (value, wasChecked) => {
        return true === (value.products_count === 0 && false === wasChecked);
    }
    
    setFilterUrl3 = (parameter, value) => {
        this.setState({changeUrl: true,})
        const URLqueryString = this.props.internal.routerData.history.location.search
        let queries = queryString.parse(URLqueryString);

        if (value !== null) {
            if(parameter.parameter_type_id === 2) { //multiple


                if(queries[parameter.id]) { // ak uz v url exituje tento parameter

                    if (!Array.isArray(queries[parameter.id])) { // ak hotnoty nie su ulozene v poli tak ich dame do pola
                        queries[parameter.id] = [queries[parameter.id]]
                    }
                    let values = queries[parameter.id].map(Number)

                    if(values.includes(value.parameter_item_id)) { // ak uz exituje tak ho vymazeme z url
                        const index = queries[parameter.id].findIndex(name => Number(name) === Number(value.parameter_item_id))
                        queries[parameter.id].splice(index, 1)
                    }
                    else { // inak pridame value do url
                        queries[parameter.id].push(value.parameter_item_id)
                    }
                }
                else { // ak v url neexituje parameter
                    queries[parameter.id] = value.parameter_item_id
                }


            }
            else if(parameter.parameter_type_id === 1) { // single selec

                if(queries[parameter.id] === value.parameter_item_id) {
                    queries[parameter.id] = null;
                }
                else {
                    queries[parameter.id] = value.parameter_item_id
                }

            }

            let queriesFromUrl = queryString.parse(window.location.search);
            if(queries['price']) {
                queries['price'] = queriesFromUrl.price
            }
        }
        else{
            delete queries[parameter.id]
        }

        let new_path = `?${queryString.stringify(queries)}`

        this.props.internal.routerData.history.push(new_path)
        this.setState({changeUrl: false,})

        this.props.setFilters(new_path)
    }

    renderImageDescriptionControl() {
        if (null === this.props.data.image && null === this.props.data.description) {
            return null;
        }

        return (
            <i
                className={'fa fa-info-circle'}
                data-container={'body'}
                data-toggle={'popover'}
                data-trigger={'hover'}
                data-placemnet={'right'}
                data-html={true}
                data-title={this.props.data.image ? `<img style="margin: -9px -15px;max-width: 200px;max-height: 200px;border-radius: 3px 3px 0 0;border-bottom: 1px solid gray;;" src="${this.props.data.image}" alt="${this.props.data.name}" />` : undefined}
                data-content={this.props.data.description}
            > </i>
        )
    }

    render () {

        // parameter
        let parameterNameWithouWhitespaces = this.props.data.external_name.replace(/\s/g,'-')
        let externalNameNormalized = parameterNameWithouWhitespaces.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

        return (
            <div>
            {this.props.radio ?
                <div className="filter-box active">
                    <div className="title">
                        {this.props.title}
                        {this.renderImageDescriptionControl()}
                    </div>
                    <div className="filter-content">
                        <span className="checkbox" onChange={() => this.setFilterUrl3(this.props.data, null)}>
                            <input type="radio" id={`all-${this.props.data.id}`} name={externalNameNormalized} value={''} defaultChecked={true} />
                            <label htmlFor={`all-${this.props.data.id}`}>{this.props.translate.i18n.COMPONENTS.FILTERS.ALL}</label>
                        </span>
                        {this.props.items.map((row, index) => {
                            // parameter - values
                            let valueWithoutWhitespaces = row.name.replace(/\s/g,'-')
                            let valueNameNormalized = valueWithoutWhitespaces.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    
                            const checked =  this.state.urlValues.includes(row.parameter_item_id.toString())
                            return (
                              <span key={index} className={`checkbox ${row.products_count === 0 ? 'disabled' : '' } ${checked ? 'but-checked' : '' }`} onChange={() => this.setFilterUrl3(this.props.data, row)}>
                                    <input
                                      type="radio"
                                      id={row.parameter_item_id}
                                      name={externalNameNormalized}
                                      value={row.parameter_item_id}
                                      disabled={this.isClickable(row, checked)}
                                    />
                                    <label htmlFor={row.parameter_item_id}>{row.name} <span className={'paramProductCount'}>({row.products_count})</span></label>
                                </span>
                            );
                        })}
                    </div>
                </div>
                :
                null
            }
            {this.props.checkbox ?
                <div className="filter-box active">
                    <div className="title">
                        {this.props.title}
                        {this.renderImageDescriptionControl()}
                    </div>
                    {/* <div className="title">{this.props.external_name}</div> */}
                    <div className="filter-content">
                        {this.props.items.map((row, index) => {
                            // parameter - values
                            let valueWithoutWhitespaces = row.name.replace(/\s/g,'-')
                            let valueNameNormalized = valueWithoutWhitespaces.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                            const checked =  this.state.urlValues.includes(row.parameter_item_id.toString())
                            return (
                                <span key={index} className={`checkbox ${row.products_count === 0 ? 'disabled' : '' } ${checked ? 'but-checked' : '' }`} onChange={() => this.setFilterUrl3(this.props.data, row)}>
                                    <input
                                      type="checkbox"
                                      id={row.parameter_item_id}
                                      value={row.parameter_item_id}
                                      name={`${externalNameNormalized}-${valueNameNormalized}`}
                                      disabled={this.isClickable(row, checked)}
                                    />
                                    <label htmlFor={row.parameter_item_id}>{row.name} <span className={'paramProductCount'}>({row.products_count})</span></label>
                                </span>
                            );
                        })}
                    </div>
                </div>
                :
                null
            }
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(FilterParameters));
