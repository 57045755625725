// core
import React from 'react'
import { observer } from 'mobx-react'

// libraries
import uuid from 'uuid'

// stores
import internalStore from '../../stories/internal'

const ProductsGridBreadcrumbs = observer(props => {
    let resultForRender = []

    const handleGoToCategory = url => e => {
        e.preventDefault()

        internalStore.routerData.history.push(`/${url}`)
    }

    const renderOneBreadcrumb = categoryPath => {
        const key = uuid()

        if (categoryPath.next === null) {
            resultForRender.push(
                <li key={key}>
                    <a onClick={handleGoToCategory(categoryPath.alias)}>{categoryPath.name}</a>
                </li>
            )
        } else {
            resultForRender.push(
                <li key={key}>
                    <a onClick={handleGoToCategory(categoryPath.alias)}>{categoryPath.name}</a>
                </li>
            )

            renderOneBreadcrumb(categoryPath.next)
        }
    }

    const renderBreadcrumbFullPath = () => {
        const { categoryPath } = props

        renderOneBreadcrumb(categoryPath)

        return resultForRender
    }

    return (
        <header>
            <div className='container'>
                <ol className='breadcrumb breadcrumb-inverted'>
                    <li>
                        <a href='/'>
                            <span className='icon icon-home' />
                        </a>
                    </li>

                    {renderBreadcrumbFullPath()}
                </ol>
            </div>
        </header>
    )
})

export default ProductsGridBreadcrumbs
