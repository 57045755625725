//basic
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './FixedSetDetail.scss'

//api
import { api } from '../../utils/api'

//custom components
import InfoBox from '../../components/basic/InfoBox/InfoBox'
import Gallery from '../../components/basic/Gallery/Gallery'
import Tabs from '../../components/complex/Tabs/Tabs'

//material-ui

//other libraries
import { formatMoney } from '../../utils/fuctions'
import Icon from '@mdi/react'
import { mdiScaleBalance, mdiShopping } from '@mdi/js'
import { Helmet } from 'react-helmet'

//ravaltrade logo
import logoRavalTrade from '../../assets/images/logo-ravaltrade.svg'

class FixedSetDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.props.internal.setGlobalLoader(true)
        const { internal, ...other } = this.props
        this.props.internal.setRouterData(other.routerData)

        this.props.internal.setProductDetail(this.props.data)
        this.setState({ loading: false })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.routerData.location.pathname !== nextProps.routerData.location.pathname) {
            this.props.internal.setGlobalLoader(true)
            this.props.internal.routerData.history.push(`${nextProps.routerData.location.pathname}`)
        }
    }

    handleAddProductToShoppingCart = async () => {
        const { productDetail } = toJS(this.props.internal)

        let shoppingCartData = toJS(this.props.internal.shoppingCartData)

        if (shoppingCartData.items !== null && shoppingCartData.items.length > 0) {
            let items = shoppingCartData.items

            let index = items.findIndex(x => x.static_set_id === productDetail.id)
            if (index > -1) {
                this.props.internal.setSnackbarData(
                    this.props.translate.i18n.GLOBAL.ALERTS.STATIC_SET_IS_IN_SHOPPING_CART,
                    true,
                    'openBasket'
                )
                return
            }
        }

        this.setState({ loadingBtn: true })

        let update_shop_cart = await api({
            url: 'shopping-cart',
            method: 'PUT',
            scns_token: localStorage.getItem('scns_token'),
            //TODO: vymazat
            // postman: true,
            params: {
                static_set: productDetail.id,
                quantity: 1
            }
        })

        // console.log("update_shop_cart", update_shop_cart)
        if (update_shop_cart.success === true) {
            this.props.internal.setShoppingCartData(update_shop_cart.data.data)
            // console.log("new state shopping cart", toJS(this.props.internal.shoppingCartData))
            this.setState({ loadingBtn: false })
            // window.init()
            this.props.internal.setSnackbarData(update_shop_cart.data.message, true, 'openBasket')

            this.props.internal.loginWrapperRef.rerender()
        }
    }

    render() {
        let routerData = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }

        const { data } = this.props
        let internal = toJS(this.props.internal)
        // console.log("fixed set data", data, toJS(this.props.internal))

        let tabs = []
        let content = []
        let products = data.products

        let active = true

        for (let i in products) {
            tabs.push({
                id: products[i].id,
                title: products[i].name,
                active: active
            })

            content.push({
                id: products[i].id,
                title: products[i].name,
                active: active,
                productDetail: products[i]
            })

            active = false
        }

        return this.state.loading ? null : (
            <div>
                <Helmet>
                    <title>
                        {data &&
                        data.page_title &&
                        data.page_title !== '' &&
                        data.page_title !== null
                            ? data.page_title
                            : data.name}{' '}
                        {internal.footerData &&
                        internal.footerData.shop &&
                        internal.footerData.shop.additional_page_title
                            ? internal.footerData.shop.additional_page_title
                            : internal &&
                              internal.footerData &&
                              internal.footerData.shop &&
                              internal.footerData.shop.name
                            ? internal.footerData.shop.name
                            : ''}
                    </title>
                    <meta
                        name='description'
                        content={`${
                            data.description && data.description !== '' && data.description !== null
                                ? data.description
                                : ''
                        }
                        `}
                    />
                    <meta
                        property='og:title'
                        content={`${
                            data.description && data.description !== '' && data.description !== null
                                ? data.description
                                : ''
                        }
                        `}
                    />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:url'
                        content={`${
                            internal &&
                            internal.footerData &&
                            internal.footerData.shop &&
                            internal.footerData.shop.url &&
                            internal.footerData.shop.url !== '' &&
                            internal.footerData.shop.url !== null
                                ? internal.footerData.shop.url + data.alias
                                : ''
                        }
                    `}
                    />
                    <meta
                        property='og:image'
                        content={`${
                            data && data.image && data.image !== null && data.image !== ''
                                ? data.image
                                : logoRavalTrade
                        }`}
                    />
                    <meta
                        property='og:description'
                        content={`${
                            data.description && data.description !== '' && data.description !== null
                                ? data.description
                                : ''
                        }
                        `}
                    />
                    <meta property='og:locale' content='sk_SK' />
                    <meta
                        property='og:site_name'
                        content={`${
                            data &&
                            data.page_title &&
                            data.page_title !== '' &&
                            data.page_title !== null
                                ? data.page_title
                                : data.name
                        }`}
                    />
                </Helmet>
                <section className='main-header productDetail fixedSetDetail'>
                    <header>
                        <div className='container'>
                            <h1 className='h2 title text-left'>{data.name}</h1>
                        </div>
                    </header>
                </section>
                <section className='product'>
                    <div className='main'>
                        <div className='container'>
                            <div className='row product-flex'>
                                <div className='col-md-6 col-sm-12 product-flex-info'>
                                    <div className='clearfix'>
                                        {/* <h1 className="title" data-title={productDetail.category.name}>{productDetail.name}<small>{productDetail.seo_description}</small></h1> */}
                                        <div className='clearfix'>
                                            <div className='price'>
                                                <span>
                                                    {
                                                        this.props.translate.i18n.PAGES
                                                            .PRODUCT_DETAIL.PRICE_WITH_VAT
                                                    }
                                                    :&nbsp;
                                                </span>
                                                <span className='h3'>
                                                    {formatMoney(data.total_with_vat)}
                                                </span>
                                                {/* <small>{formatMoney(productDetail.retail_price_with_vat)}</small> */}
                                            </div>
                                            <div className='price withoutVat'>
                                                <span>
                                                    {
                                                        this.props.translate.i18n.PAGES
                                                            .PRODUCT_DETAIL.PRICE_WITHOUT_VAT
                                                    }
                                                    :&nbsp;
                                                </span>
                                                <span className='h3'>
                                                    {formatMoney(data.total)}
                                                </span>
                                                {/* <small>{formatMoney(productDetail.retail_price_with_vat)}</small> */}
                                            </div>
                                            {data.coupons && data.coupons.length > 0 ? (
                                              data.coupons.map(coupon => (
                                                <Fragment key={coupon.id}>
                                                    <hr/>
                                                    <div className="coupon">
                                                        <span>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.PRICE_WITH_CODE}:&nbsp; {coupon.code}</span>
                                                        <span class="h3">{formatMoney(coupon.price_with_coupon)}</span>
                                                    </div>
                                                </Fragment>
                                              ))
                                            ) : null}
                                            <hr />

                                            {data.products.map((product, index) => {
                                                return (
                                                    <InfoBox
                                                        key={index}
                                                        title={product.name}
                                                        value={
                                                            product.category !== null
                                                                ? product.category.name
                                                                : ''
                                                        }
                                                    />
                                                )
                                            })}
                                            <hr />

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly',
                                                    position: 'relative'
                                                }}
                                            >
                                                {/* btnCompare btn-clean-dark */}
                                                {/* <button type="submit" className="btn btn-border btnWhiteBackground"><Icon path={mdiScaleBalance} size={1}/>{this.props.translate.i18n.GLOBAL.BUTTONS.COMPARE}</button> */}
                                                {this.state.loadingBtn ? (
                                                    <div className='lds-dual-ring addingToShoopingCart' />
                                                ) : null}
                                                <button
                                                    style={{
                                                        display: `${
                                                            this.state.loadingBtn ? 'none' : 'block'
                                                        }`
                                                    }}
                                                    type='submit'
                                                    className='btn btn-border btnBlueBackground'
                                                    onClick={() =>
                                                        this.handleAddProductToShoppingCart()
                                                    }
                                                >
                                                    <Icon path={mdiShopping} size={1} />
                                                    {this.props.translate.i18n.GLOBAL.BUTTONS.BUY}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 col-sm-12 product-flex-gallery '>
                                    <Gallery data={data.image} fixedSet={true} product={false} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='info bgGrayLight'>
                        <div className='container'>
                            <div className='row'>
                                <div className={'tabs'}>
                                    <Tabs tabs={tabs} content={content} routerData={routerData} />
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default inject('internal', 'translate')(observer(FixedSetDetail))
