import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import './ServiceProtocol.scss'
import AuthInput from '../../components/basic/AuthInput'
import {
  Button,
  Checkbox,
  createMuiTheme,
  FormControlLabel,
  MuiThemeProvider,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import CustomDatePicker from '../../components/basic/DatePicker/DatePicker'
import { api } from '../../utils/api'

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 9,
    useNextVariants: true,
  },
})

class ServiceProtocol extends Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()
  }

  componentDidMount() {
    const { routerData, ...other } = this.props
    this.props.internal.setRouterData(routerData)
    this.props.internal.setGlobalLoader(false)
  }

  handleChange = (field, e) => this.setState({
    errors: this.state.errors,
    form: { ...this.state.form, [field]: e.target.value },
  })

  handleCheckboxChange = (field, e) => this.setState({ form: { ...this.state.form, [field]: e.target.checked } })

  handleDateChange = (field, date) => this.setState({ form: { ...this.state.form, [field]: date } })

  handleFileChange = (field, e) => this.setState({ form: { ...this.state.form, [field]: (e.target.files[0] || null) } })

  submitForm = () => {
    this.setState({ errors: {}, loading: true })
    api({
      method: 'POST',
      url: 'service-protocol',
      authorization: this.props.internal.user.access_token,
      formData: true,
      params: this.state.form,
    }).then(response => {
      this.setState({loading: false})
      if (false === response.success) {
        if (response.status === 422) {
          this.setState({ errors: { ...response.data.data.errors } })
          this.props.internal.setSnackbarErrorData(response.data.message)
        } else {
          this.props.internal.setSnackbarErrorData(response.data.message)
        }
      } else {
        this.setState(this.getInitialState())
        this.props.internal.setSnackbarData(response.data.message, true)
      }
    })
  }

  getInitialState() {
    return {
      form: {
        service_protocol_type_id: '1',
        company_name: '',
        company_street: '',
        company_city: '',
        company_zip: '',
        company_email: '',
        name: '',
        surname: '',
        street_name: '',
        street_no: '',
        zip: '',
        city: '',
        email: '',
        phone: '',
        file_warranty: '',
        file_production_label: '',
        file_photo: '',
        warranty_number: '',
        ordered_at: null,
        product_name: '',
        product_code: '',
        serial_number: '',
        product_code_prf: '',
        product_count: '',
        is_opened: '',
        defect_details: '',
        other_details: '',
      },
      errors: {},
      loading: false
    }
  }

  hasErrors = field => this.state.errors.hasOwnProperty(field)

  getError = field => this.hasErrors(field) ? this.state.errors[field][0] : ''

  render() {
    return <MuiThemeProvider theme={theme}>
      <div className="article">
        <section className="main-header productDetail ">
          <header>
            <div className="container">
              <h1 className={'title'}>{this.props.data.name}</h1>
            </div>
          </header>
        </section>

        <section className={'section-content'}>
          <div className={'container bgGrayLight'}>
            <div className={'content'}>
              <div className="p-4">
                <h5>Postup pri vypĺňaní reklamácie:</h5>
                <ul>
                  <li>Nafotenie povinných fotografií</li>
                  <li>Vyplnenie a odoslanie formuláru</li>
                  <li>ako fotografie prosím vkladajte len súbory *.JPG, alebo *.PDF</li>
                  <li className="text-red">Pri uplatnení záručnej opravy, reklamácie, je nutmé priložiť fotografiu výrobného štítku produktu. Výrobný štítok sa nachádza pod tukovým filtrom odsávača, ďakujeme.</li>
                </ul>

                <fieldset className="fieldset">
                  <legend>Informácie o objednávke</legend>
                  <div className="w-full p-4">
                    <h5>Typ servisu *</h5>
                    <div>
                      <RadioGroup
                        aria-label="Typ servisu"
                        name="service_protocol_type"
                        value={this.state.form.service_protocol_type_id}
                        onChange={e => this.handleChange('service_protocol_type_id', e)}
                      >
                        <FormControlLabel value="1" control={<Radio/>} label="Záručný servis"/>
                        <FormControlLabel value="2" control={<Radio/>} label="Pozáručný servis"/>
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-4">
                      <h5>Adresa a názov predajne (partner, odberateľ)</h5>
                      <AuthInput
                        label="Názov firmy:"
                        required
                        value={this.state.form.company_name}
                        className="w-100"
                        name='company_name'
                        hasError={this.hasErrors('company_name')}
                        errorMessage={this.getError('company_name')}
                        onChange={e => this.handleChange('company_name', e)}
                      />
                      <AuthInput
                        label="Ulica a číslo:"
                        required
                        value={this.state.form.company_street}
                        className="w-100"
                        name="company_street"
                        hasError={this.hasErrors('company_street')}
                        errorMessage={this.getError('company_street')}
                        onChange={e => this.handleChange('company_street', e)}
                      />
                      <AuthInput
                        label="Mesto:"
                        required
                        value={this.state.form.company_city}
                        className="w-100"
                        name="company_city"
                        hasError={this.hasErrors('company_city')}
                        errorMessage={this.getError('company_city')}
                        onChange={e => this.handleChange('company_city', e)}
                      />
                      <AuthInput
                        label="PSČ:"
                        type={'number'}
                        required
                        value={this.state.form.company_zip}
                        className="w-100"
                        name="company_zip"
                        hasError={this.hasErrors('company_zip')}
                        errorMessage={this.getError('company_zip')}
                        onChange={e => this.handleChange('company_zip', e)}
                      />
                      <AuthInput
                        label="E-mail:"
                        type={'email'}
                        value={this.state.form.company_email}
                        className="w-100"
                        name="company_email"
                        hasError={this.hasErrors('company_email')}
                        errorMessage={this.getError('company_email')}
                        onChange={e => this.handleChange('company_email', e)}
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-4">
                      <h5>Vaše údaje / zákazník</h5>
                      <AuthInput
                        label="Meno:"
                        required
                        value={this.state.form.name}
                        className="w-100"
                        name='name'
                        hasError={this.hasErrors('name')}
                        errorMessage={this.getError('name')}
                        onChange={e => this.handleChange('name', e)}
                      />
                      <AuthInput
                        label="Priezvisko:"
                        required
                        value={this.state.form.surname}
                        className="w-100"
                        name="surname"
                        hasError={this.hasErrors('surname')}
                        errorMessage={this.getError('surname')}
                        onChange={e => this.handleChange('surname', e)}
                      />
                      <div className={'flex'}>
                        <div className="w-4/5">
                          <AuthInput
                            label="Adresa:"
                            required
                            value={this.state.form.street_name}
                            className="w-100"
                            name="street_name"
                            hasError={this.hasErrors('street_name')}
                            errorMessage={this.getError('street_name')}
                            onChange={e => this.handleChange('street_name', e)}
                          />
                        </div>
                        <div className="w-1/5">
                          <AuthInput
                            label="Číslo:"
                            required
                            value={this.state.form.street_no}
                            className="w-100"
                            name="street_no"
                            hasError={this.hasErrors('street_no')}
                            errorMessage={this.getError('street_no')}
                            onChange={e => this.handleChange('street_no', e)}
                          />
                        </div>
                      </div>
                      <AuthInput
                        label="PSČ:"
                        required
                        value={this.state.form.zip}
                        className="w-100"
                        name="zip"
                        hasError={this.hasErrors('zip')}
                        errorMessage={this.getError('zip')}
                        onChange={e => this.handleChange('zip', e)}
                      />
                      <AuthInput
                        label="Mesto:"
                        required
                        value={this.state.form.city}
                        className="w-100"
                        name="city"
                        hasError={this.hasErrors('city')}
                        errorMessage={this.getError('city')}
                        onChange={e => this.handleChange('city', e)}
                      />
                      <AuthInput
                        label="E-mail:"
                        required
                        value={this.state.form.email}
                        className="w-100"
                        name="email"
                        hasError={this.hasErrors('email')}
                        errorMessage={this.getError('email')}
                        onChange={e => this.handleChange('email', e)}
                      />
                      <AuthInput
                        label="Telefón:"
                        required
                        value={this.state.form.phone}
                        className="w-100"
                        name="phone"
                        hasError={this.hasErrors('phone')}
                        errorMessage={this.getError('phone')}
                        onChange={e => this.handleChange('phone', e)}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="fieldset">
                  <legend>Doklady o záruke</legend>
                  <div className="w-1/2">
                    <div className="p-4">
                      <div>
                        <AuthInput
                          label="Záručný list:"
                          required
                          type="file"
                          name="file_warranty"
                          hasError={this.hasErrors('file_warranty')}
                          errorMessage={this.getError('file_warranty')}
                          value={this.state.form.file_warranty}
                          accept=".jpg,.jpeg,.pdf"
                          onChange={e => this.handleFileChange('file_warranty', e)}
                        />
                      </div>
                      <div>
                        <AuthInput
                          label="Výrobný štítok:"
                          required
                          type="file"
                          name="file_production_label"
                          hasError={this.hasErrors('file_production_label')}
                          errorMessage={this.getError('file_production_label')}
                          value={this.state.form.file_production_label}
                          onChange={e => this.handleFileChange('file_production_label', e)}
                        />
                      </div>
                      <div>
                        <AuthInput
                          label="Foto:"
                          required
                          type="file"
                          name="file_photo"
                          hasError={this.hasErrors('file_photo')}
                          errorMessage={this.getError('file_photo')}
                          value={this.state.form.file_photo}
                          onChange={e => this.handleFileChange('file_photo', e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-4">
                      <AuthInput
                        label="Číslo záručného listu (povinné ak ide o záručný servis)"
                        required={this.state.form.service_protocol_type_id === '1'}
                        value={this.state.form.warranty_number}
                        className="w-100"
                        name="warranty_number"
                        hasError={this.hasErrors('warranty_number')}
                        errorMessage={this.getError('warranty_number')}
                        onChange={e => this.handleChange('warranty_number', e)}
                      />

                      <CustomDatePicker
                        label="Dátum nákupu"
                        value={this.state.form.ordered_at}
                        name="ordered_at"
                        translate={this.props.translate}
                        hasError={this.hasErrors('ordered_at')}
                        errorMessage={this.getError('ordered_at')}
                        onChange={e => this.handleDateChange('ordered_at', e)}
                        required
                        autoOk
                        disableFuture
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="fieldset">
                  <legend>Informácia o výrobku a dôvod vrátenia</legend>
                  <div className="w-1/2">
                    <div className="p-4">
                      <AuthInput
                        label="Názov produktu ( Model ):"
                        required
                        value={this.state.form.product_name}
                        className="w-100"
                        name='product_name'
                        hasError={this.hasErrors('product_name')}
                        errorMessage={this.getError('product_name')}
                        onChange={e => this.handleChange('product_name', e)}
                      />
                      <AuthInput
                        label="Sériové číslo (F.Nr.):"
                        required
                        value={this.state.form.serial_number}
                        className="w-100"
                        name='serial_number'
                        hasError={this.hasErrors('serial_number')}
                        errorMessage={this.getError('serial_number')}
                        onChange={e => this.handleChange('serial_number', e)}
                      />
                      <AuthInput
                        label="Kód produktu PRF:"
                        required
                        value={this.state.form.product_code_prf}
                        className="w-100"
                        name='product_code_prf'
                        hasError={this.hasErrors('product_code_prf')}
                        errorMessage={this.getError('product_code_prf')}
                        onChange={e => this.handleChange('product_code_prf', e)}
                      />
                      <AuthInput
                        label="Kód produktu 12NC:"
                        required
                        value={this.state.form.product_code}
                        className="w-100"
                        name='product_code'
                        hasError={this.hasErrors('product_code')}
                        errorMessage={this.getError('product_code')}
                        onChange={e => this.handleChange('product_code', e)}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.form.is_opened}
                            onChange={e => this.handleCheckboxChange('is_opened', e)}
                            color="primary"
                          />
                        }
                        label="produkt je otvorený"
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-4">
                      <AuthInput
                        label="Údaje o chybe:"
                        multiline
                        value={this.state.form.defect_details}
                        className="w-100"
                        name="defect_details"
                        hasError={this.hasErrors('defect_details')}
                        errorMessage={this.getError('defect_details')}
                        onChange={e => this.handleChange('defect_details', e)}
                      />
                      <AuthInput
                        label="Ďalšie informácie:"
                        multiline
                        value={this.state.form.other_details}
                        className="w-100"
                        name="other_details"
                        hasError={this.hasErrors('other_details')}
                        errorMessage={this.getError('other_details')}
                        onChange={e => this.handleChange('other_details', e)}
                      />
                    </div>
                  </div>
                </fieldset>
                <div>
                  <button type='button' disabled={this.state.loading} className='btn btn-block btnLogin btnWhiteBackground' onClick={this.submitForm}>
                    Pokračovať
                  </button>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </MuiThemeProvider>
  }
}

export default inject('internal', 'translate')(observer(ServiceProtocol))
