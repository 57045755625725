// basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//api
import { api } from '../../../utils/api';

//style
import './CartBlockItem.scss';

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions';

//no image
import no_image from '../../../assets/images/no_image.png'
import ReactGA from 'react-ga4'


class CartBlockItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 1,
            items: [],
            loading: true,
        }
    }

    componentDidMount () {
        let items = this.props.items
        let quantity = []
        for(let i in items) {
            quantity.push(items[i].quantity)
        }
        this.setState({quantity: quantity, items: items, loading: false}, () => {
        })
    }

    timeout = null

    handleChangeQuantity = (index, event, item) => {

        let newQuantity = Number(event.target.value)

        // this.setState({items: toJS(this.props.internal.shoppingCartData.items)})
        let items = toJS(this.props.internal.shoppingCartData.items)
        items[index].quantity = newQuantity
        this.setState({items: items})


        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if(item.product !== null && item.static_set === null) {
                this.updateQuantityOfProduct(newQuantity, item.product_id)
            }
            else {
                this.updateQuantityOfStaticSet(newQuantity, item.static_set_id)
            }
        }, 10)
    }

    updateQuantityOfProduct = async(quantity, product_id) => {
        this.props.updateCart()
        let update_data = await api ({

            url: "shopping-cart",
            method: 'PATCH',
            scns_token: localStorage.getItem('scns_token'),
            params: {
                product_id: product_id,
                to: quantity,
                action: "quantity"
            }
        })

        if(update_data.success === true) {
            this.props.internal.setShoppingCartData(update_data.data.data);
            this.props.internal.setSnackbarData(update_data.data.message, true)
            this.props.internal.loginWrapperRef.rerender()
            this.props.updateCart()
        }
    }

    updateQuantityOfStaticSet = async(quantity, static_set_id) => {
        this.props.updateCart()
        let update_data = await api ({

            url: "shopping-cart",
            method: 'PATCH',
            scns_token: localStorage.getItem('scns_token'),
            params: {
                static_set_id: static_set_id,
                to: quantity,
                action: "quantity"
            }
        })

        if(update_data.success === true) {
            this.props.internal.setShoppingCartData(update_data.data.data);
            this.props.internal.setSnackbarData(update_data.data.message, true)
            this.props.internal.loginWrapperRef.rerender()
            this.props.updateCart()
        }
    }

    removeItemFromShoppingCart = (index, item) => {
        // let items = this.state.items
        // items = items.splice(index, 1)
        // this.setState({items: items})

        if(item.product !== null && item.static_set === null) {
            // document.getElementById(item.product_id).style.visibility = "hidden"
            // document.getElementById(item.product_id).style.display = "none"

            let children = document.getElementById(item.product_id).children
            for(let i=0; i<children.length; i++) {
                children[i].style.visibility = "hidden"
            }

            let div = document.createElement("div")
            div.classList.add('lds-dual-ring', 'removeItemFromShoppingCartLoader')
            document.getElementById(item.product_id).appendChild(div)

            this.removeProduct(item)
        }
        else {
            // document.getElementById(item.static_set_id).style.display = "none"
            let children = document.getElementById(item.static_set_id).children
            for(let i=0; i<children.length; i++) {
                children[i].style.visibility = "hidden"
            }

            let div = document.createElement("div")
            div.classList.add('lds-dual-ring', 'removeItemFromShoppingCartLoader')
            document.getElementById(item.static_set_id).appendChild(div)
            this.removeStaticSet(item)
        }
    }

    removeProduct = async(item) => {
        const { product_id } = item
        this.props.updateCart()
        let delete_data = await api({

            url: `shopping-cart/product/${product_id}`,
            scns_token: localStorage.getItem('scns_token'),
            method: 'DELETE'
        })

        if(delete_data.success === true) {
            ReactGA.event('remove_from_cart', {
                currency: 'EUR',
                price: item.total_price,
                items: [
                    {
                        item_id: item.product.code,
                        item_name: item.product.name,
                        affiliation: 'Bekoshop.sk',
                        coupon: item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
                        item_category: item.product.category_path_alt[0],
                        item_category2: item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
                        item_category3: item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
                        price: item.total_price,
                    }
                ]
            })
            let children = document.getElementById(product_id).children
            for(let i=0; i<children.length; i++) {
                children[i].style.visibility = "visible"
            }
            document.getElementById(product_id).lastChild.remove()
            document.getElementById(product_id).style.visibility = "visible"

            this.props.internal.setShoppingCartData(delete_data.data.data);
            this.props.internal.setSnackbarData(delete_data.data.message, true)
            this.props.internal.loginWrapperRef.rerender()
            this.props.updateCart()
        }
    }

    removeStaticSet = async(item) => {
        const { static_set_id } = item
        this.props.updateCart()
        let delete_data = await api({

            url: `shopping-cart/static-set/${static_set_id}`,
            scns_token: localStorage.getItem('scns_token'),
            method: 'DELETE'
        })

        if(delete_data.success === true) {
            ReactGA.event('remove_from_cart', {
                currency: 'EUR',
                price: item.total_price,
                items: [
                    {
                        item_id: item.static_set.name,
                        item_name: item.static_set.name,
                        affiliation: 'Bekoshop.sk',
                        price: item.total_price,
                    }
                ]
            })
            let children = document.getElementById(static_set_id).children
            for(let i=0; i<children.length; i++) {
                children[i].style.visibility = "visible"
            }
            document.getElementById(static_set_id).lastChild.remove()
            document.getElementById(static_set_id).style.visibility = "visible"
            this.props.internal.setShoppingCartData(delete_data.data.data);
            this.props.internal.setSnackbarData(delete_data.data.message, true)
            this.props.internal.loginWrapperRef.rerender()
            this.props.updateCart()
        }
    }

    handleGoTo = (item) => {
        let url;
        if(item.product !== null && item.static_set === null) {
            url = item.product.alias
        }
        else {
            url = item.static_set.alias
        }

        this.props.internal.setGlobalLoader(true)
        this.props.internal.routerData.history.push(`/${url}`)
        this.props.internal.routerData.match.params.alias = url
        this.props.internal.loginWrapperRef.handleShowShoppingCart()
    }

    render() {
        return (
            this.state.loading ? null :
            <div className='clearfix'>

                {this.props.items && this.props.items.map((item, index) => {
                    return (
                        <div key={index} className='cart-block cart-block-item clearfix' id={item.product !== null ? item.product_id : item.static_set_id}>
                            {/* <div className={'priceTitle'}> */}
                            {/* PRODUCT image */}
                                {item.product !== null ?
                                    <div className={`image ${item.product.asset_image_thumb === null ? '': ''}`}>
                                        {item.product.asset_image_thumb !== null ?
                                        <a onClick={() => this.handleGoTo(item)}><img src={item.product.asset_image_thumb} alt="" /></a>
                                        :
                                        <img src={no_image} alt="" />
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {/* STATIC SET image */}
                                {item.static_set !== null ?
                                    <div className={`image ${item.static_set.image === null ? '': ''}`}>
                                        {item.static_set.image !== null ?
                                        <a onClick={() => this.handleGoTo(item)}><img src={item.static_set.image } alt="" /></a>
                                        :
                                        <img src={no_image} alt="" />
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {/* PRODUCT title */}
                                {item.product !== null ?
                                    <div className='title'>
                                        <div className=''>
                                            <a onClick={() => this.handleGoTo(item)}>{item.product.name}</a>
                                        </div>
                                        {item.product.gifts && item.product.gifts.length > 0 && (
                                          <div className={'small'}>{this.props.translate.i18n.PAGES.SHOPPING_CART.GIFTS}: {item.product.gifts.map(gift => gift.name).join(', ')}</div>
                                        )}
                                        {/* <div>{item.product.category.name}</div> */}
                                        {item.discount_message && item.discount_message !== "" && item.discount_message !== null
                                            ?
                                            <span className={'discountMessage'}>{item.discount_message}</span>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {/* STATIC SET title */}
                                {item.static_set !== null ?
                                    <div className='title'>
                                        <div className=''>
                                            <a onClick={() => this.handleGoTo(item)}>{item.static_set.name}</a>
                                        </div>
                                        {/* <div>{item.product.category.name}</div> */}
                                        {item.static_set.gifts && item.static_set.gifts.length > 0 && (
                                          <div className={'small'}>{this.props.translate.i18n.PAGES.SHOPPING_CART.GIFTS}: {item.static_set.gifts.map(gift => gift.name).join(', ')}</div>
                                        )}
                                    </div>
                                    :
                                    null
                                }
                            {/* </div> */}
                            <div className='quantity'>
                                {/*this.state.quantity[index] value={item.quantity} value={this.state.items[index].quantity} */}
                                {/* onChange={(val) => this.handleChangeQuantity(index,val, item)} */}
                                <input type='number' min={1} value={this.state.items[index] ? this.state.items[index].quantity : item.quantity}  className='form-control form-quantity'  onChange={(val) => this.handleChangeQuantity(index,val, item)}/>
                            </div>
                            <div className='price'>
                                <span className='final h3'>{formatMoney(item.total_price_with_vat)}</span>
                                {this.props.cart && item.discount > 0 ? (
                                  <span className='coupon-discount'>{formatMoney(item.one_price_with_vat * item.quantity)}</span>
                                ) : null}
                            </div>
                            <span className='icon icon-cross icon-delete' onClick={() => this.removeItemFromShoppingCart(index,item)}/>
                        </div>
                    );
                })}
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(CartBlockItem))
