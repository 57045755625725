// core
import React, { Component } from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../utils/api'

// components
import ProductsGridBreadcrumbs from './ProductsGridBreadcrumbs'
import ProductsGridItems from './ProductsGridItems'
import ProductsGridMeta from './ProductsGridMeta'
import ProductsGridPriceFilter from './ProductsGridPriceFilter'
import ProductsGridSortBy from './ProductsGridSortBy'
import FilterParameters from '../../components/complex/FilterParameters/FilterParameters'

// i18n
import t from '../../stories/i18n'

// libraries
import queryString from 'query-string'

// stores
import internalStore from '../../stories/internal'

// styles
import './ProductsGrid.scss'
import 'rc-slider/assets/index.css'

class ProductsGrid extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listingStaticSets: false,
            loading: true,
            filters: [],
            filter: false,
            price_from: 0,
            price_to: 4000,
            filtersIsUsed: false,
            filtersPhone: false,
            maxPrice: 4000,
            nextPageLoader: false,
            sort: 'name-asc'
        }
    }

    componentDidMount() {
        if (this.props.routerData.location.search) {
            let search = queryString.parse(this.props.routerData.location.search)
            if (search.price_from && search.price_to) {
                this.slider([search.price_from, search.price_to])
                this.setState({
                    price_from: Number(search.price_from),
                    price_to: Number(search.price_to)
                })
            }
        }

        const { prices } = this.props.data

        if (false === prices) {
            this.setState({ listingStaticSets: true })
        } else {
            this.setState({ maxPrice: this.props.data.prices.max, listingStaticSets: false })
        }

        const { internal, ...other } = this.props
        internalStore.setRouterData(other.routerData)
        internalStore.setProductsForCategory(this.props.data.data)
        internalStore.setAllAboutListProducts(this.props.data)
        this.setState({ loading: false })
        const { parameters } = this.props.data.lists
        this.setState({ filters: parameters })
        // internalStore.setGlobalLoader(true)

        window.addEventListener('scroll', this.handleScroll)

        window.addEventListener('click', this.handleClickOutOfFilters)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.routerData.location.pathname !== nextProps.routerData.location.pathname) {
            internalStore.setGlobalLoader(true)
            internalStore.routerData.history.push(`${nextProps.routerData.location.pathname}`)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('click', this.handleClickOutOfFilters)
    }

    hiddeMobileFilters = false

    handleClickOutOfFilters = e => {
        let filters = document.getElementById('filters')
        if (filters && filters.classList.contains('active')) {
            if (filters.contains(e.target)) {
            } else {
                if (this.hiddeMobileFilters) {
                    filters.classList.remove('active')
                    this.hiddeMobileFilters = false
                    this.hadnelClosePhoneFilters()
                } else {
                    this.hiddeMobileFilters = true
                }
            }
        }
    }

    nextPageWasLoaded = false

    handleScroll = e => {
        let lastProduct = document.querySelector('#products > div:last-child > div')
        if (null === lastProduct) {
            return
        }

        lastProduct = lastProduct.getBoundingClientRect()

        if (lastProduct.top > lastProduct.height * 2) {
            return
        }

        let nextPage = internalStore.allAboutListProducts.links.next

        if (nextPage !== null && !this.nextPageWasLoaded) {
            this.loadNextPage(nextPage)
            this.nextPageWasLoaded = true
        }
    }

    loadNextPage = async url => {
        this.setState({ nextPageLoader: true })

        let load_next_page = await api({
            method: 'GET',
            url: '',
            useNextPage: true,
            nextPageUrl: url
        })

        if (load_next_page.success === true) {
            internalStore.updateAllAboutListProducts({
                data: load_next_page.data.data,
                links: load_next_page.data.links,
                meta: load_next_page.data.mesta
            })
    
            let element = document.querySelector('#products > div:last-child > div')
            if (element) {
                let lastProduct = element.getBoundingClientRect()
    
                if (lastProduct.top < lastProduct.height && null !== load_next_page.data.links.next) {
                    this.nextPageWasLoaded = false
    
                    this.handleScroll()
    
                    return
                }
            }

            this.nextPageWasLoaded = false
            this.setState({ nextPageLoader: false })

            window.init()
        }
    }

    loadData2 = async () => {
        // this.setState({filtersIsUsed: false})
        let search = queryString.parse(this.props.routerData.history.location.search)

        let slug = ''
        let params2 = {}
        let filters = []

        let params = this.props.routerData.match.params
        for (let i in params) {
            if (params[i] !== undefined) {
                slug += params[i] + '/'
            }
        }
        let tmpSort = ''

        slug = slug.slice(0, -1)
        let order = [{}]
        let orderIsUsed = false
        for (let i in search) {
            if (i === 'price_from' || i === 'price_to') {
                filters.push({ retail_price: search[i] })
                this.setState({ filtersIsUsed: true })
            } else if (i === 'orderBy') {
                order[0].by = search[i]
                orderIsUsed = true
                tmpSort += search[i] + '-'
            } else if (i === 'orderDir') {
                order[0].dir = search[i]
                orderIsUsed = true
                tmpSort += search[i]
                this.setState({ sort: tmpSort })
            } else {
                if (Array.isArray(search[i])) {
                    for (let index = 0; index < search[i].length; index++) {
                        filters.push({ parameter_item_id: search[i][index] })
                    }
                } else {
                    filters.push({ parameter_item_id: search[i] })
                }
                this.setState({ filtersIsUsed: true })
            }
        }

        if (orderIsUsed) {
            params2.order = order
        }
        params2.page = 12

        params2.filter = filters

        let load_data = await api({
            method: 'GET',
            url: `resolver/${slug}`,
            params: params2
        })

        if (load_data.success === true) {
            internalStore.setProductsForCategory(load_data.data.data)
            this.setState({
                filter: false,
                filters: load_data.data.lists.parameters,
                loadedData: load_data.data /*, listingStaticSets: load_data.prices === false*/
            })
            internalStore.setAllAboutListProducts(load_data.data)
        } else {
            // pri chybe
            internalStore.setProductsForCategory(null)
        }
        this.setState({ loading: false })
        internalStore.setGlobalLoader(false)
        // window.init()
    }

    setFilters = full_path => {
        this.setState({ filter: true, filtersIsUsed: true })
        // this.loadData(full_path)
        this.loadData2()
    }

    slider = async e => {
        this.setState({ filter: true, filtersIsUsed: true })

        const URLqueryString = internalStore.routerData.history.location.search
        let queries = queryString.parse(URLqueryString)
        queries.price_from = e[0]
        queries.price_to = e[1]

        let new_path = `?${queryString.stringify(queries)}`
        internalStore.routerData.history.push(new_path)

        // this.loadData(null, e)
        this.loadData2()
    }

    handleSort = e => {
        this.setState({ filter: true, filtersIsUsed: true })

        const URLqueryString = internalStore.routerData.history.location.search
        let queries = queryString.parse(URLqueryString)

        // queries.orderBy = 'reatail_price';
        queries.orderBy = e.target.value.split('-')[0]
        queries.orderDir = e.target.value.split('-')[1]

        let new_path = `?${queryString.stringify(queries)}`
        internalStore.routerData.history.push(new_path)

        // this.loadData(null, e)
        this.loadData2()
    }

    handleActiveFilterPhone = () => {
        this.setState({ filtersPhone: true }) //tooltips
        document.getElementById('productsGrid').style.pointerEvents = 'none'
        document.getElementById('productsGrid').style.opacity = '0.5'
        document.body.style.overflowY = 'hidden'
    }

    hadnelClosePhoneFilters = () => {
        this.setState({ filtersPhone: false }) //tooltips
        let filters = document.getElementById('filters')
        filters.classList.remove('active')
        document.getElementById('productsGrid').style.pointerEvents = 'auto'
        document.getElementById('productsGrid').style.opacity = '1'
        document.body.style.overflowY = 'auto'
    }

    renderChips = () => {
        const URLqueryString = internalStore.routerData.history.location.search
        let queries = queryString.parse(URLqueryString)

        var j
        let params = []
        let paramsvalues = []
        // console.clear();
        for (j = 0; j < this.props.data.lists.parameters.length; j++) {
            var id = this.props.data.lists.parameters[j].id
            var externalName = this.props.data.lists.parameters[j].external_name

            params[id] = externalName

            for (let e in this.props.data.lists.parameters[j].values) {
                let value = this.props.data.lists.parameters[j].values[e]

                paramsvalues[value.parameter_item_id] = value.name
            }
        }

        let renderValues = []
        for (let i in queries) {
            if (i === 'price_to') {
                renderValues.push(
                    <div className='chip'>
                        <span>
                            Cena do: {queries[i]} €
                            <i
                                onClick={() => this.handleCancelFilter(i)}
                                className='fa fa-times'
                                aria-hidden='true'
                            />
                        </span>
                    </div>
                )
            } else if (i === 'price_from') {
                renderValues.push(
                    <div className='chip'>
                        <span>
                            Cena od: {queries[i]} €
                            <i
                                onClick={() => this.handleCancelFilter(i)}
                                className='fa fa-times'
                                aria-hidden='true'
                            />
                        </span>
                    </div>
                )
            } else if (i === 'orderBy') {
                // renderValues.push(<div className="chip"><span>Zoradiť podľa: {this.props.translate.i18n.PAGES.PRODUCT_GRID[queries[i]]} <i onClick={() => this.handleCancelFilter(i)} className="fa fa-times" aria-hidden="true"></i></span></div> )
            } else if (i === 'orderDir') {
                // renderValues.push(<div className="chip"><span>Smer: {this.props.translate.i18n.PAGES.PRODUCT_GRID[queries[i]]}<i onClick={() => this.handleCancelFilter(i)} className="fa fa-times" aria-hidden="true"></i></span></div> )
            } else if (i !== 'price_to' && i !== 'price_from') {
                if (Array.isArray(queries[i])) {
                    for (let k in queries[i]) {
                        renderValues.push(
                            <div className='chip'>
                                <span>
                                    {params[i]} : {paramsvalues[queries[i][k]]}
                                    <i
                                        onClick={() => this.handleCancelFilter(i, k)}
                                        className='fa fa-times'
                                        aria-hidden='true'
                                    />
                                </span>
                            </div>
                        )
                    }
                } else {
                    renderValues.push(
                        <div className='chip'>
                            <span>
                                {params[i]} : {paramsvalues[queries[i]]}
                                <i
                                    onClick={() => this.handleCancelFilter(i)}
                                    className='fa fa-times'
                                    aria-hidden='true'
                                />
                            </span>
                        </div>
                    )
                }
            }
        }
        return renderValues
    }
    handleCancelFilter = (parameter, val = undefined) => {
        const URLqueryString = internalStore.routerData.history.location.search
        let queries = queryString.parse(URLqueryString)

        for (let i in queries) {
            if (i === 'price_from') {
                delete queries[parameter]
                this.setState({ price_from: 0 })
                this.setState({ price_to: queries[i] })
            } else if (i === 'price_to') {
                delete queries[parameter]
                this.setState({ price_to: this.state.maxPrice })
                this.setState({ price_from: queries[i] })
            } else {
                if (val !== undefined) {
                    //ked je v url pole(jeden parameter s viacerimi hodnotami, napr: 2=99&2=100&2=101)
                    delete queries[parameter][val]
                } else {
                    delete queries[parameter]
                }
            }
        }

        let new_path = `?${queryString.stringify(queries)}`

        internalStore.routerData.history.push(new_path)
        this.setFilters(new_path)
        // odkomentovat
        // this.setState({loading: true})

        // zakomentovat nefunguje rerender
        // this.props.internal.setGlobalLoader(true);
    }

    render() {
        return this.state.loading ? null : (
            <div className={'cateogry'} id='scroling'>
                <ProductsGridMeta categoryPath={this.props.data.category_path} />

                <section className='main-header'>
                    <ProductsGridBreadcrumbs categoryPath={this.props.data.category_path} />

                    <ProductsGridSortBy
                        listingStaticSets={this.state.listingStaticSets}
                        handleActiveFilterPhone={this.handleActiveFilterPhone}
                        handleSort={this.handleSort}
                    />
                </section>

                <section
                    className={`products ${
                        internalStore.productsForCategory.length < 1 && this.state.filtersIsUsed
                            ? 'emptyList'
                            : ''
                    }`}
                >
                    <div className='container'>
                        <header className='hidden'>
                            <h3 className='h3 title'>Product category grid</h3>
                        </header>
                        {this.props.data.lists.parameters.length === 0 &&
                        this.state.filtersIsUsed === false &&
                        internalStore.productsForCategory.length < 1 ? (
                            <p
                                className={
                                    'emptyCategory' // ka nebol pouzity ziaden filter a pirslo prazne pole porduktov
                                }
                            >
                                {t.i18n.GLOBAL.ALERTS.NO_PRODUCTS_IN_CATEGORY}
                            </p>
                        ) : (
                            <div className='row'>
                                {!this.state.listingStaticSets && (
                                    <div className='col-md-2 col-xs-12'>
                                        <div id='filters' className={`filters `}>
                                            <div className='filter-box active hidden-md hidden-lg '>
                                                <div className='filter-content'>
                                                    <div className={'phoneFilterMenu'}>
                                                        <div className='filtertMenuTitle'>
                                                            {t.i18n.PAGES.CATEGORIES.FILTERS}
                                                        </div>
                                                        <div
                                                            className=''
                                                            onClick={this.hadnelClosePhoneFilters}
                                                        >
                                                            <span className='close-menu'>
                                                                <i className='icon icon-cross' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ProductsGridPriceFilter
                                                filter={this.state.filter}
                                                maxPrice={this.state.maxPrice}
                                                priceFrom={this.state.price_from}
                                                priceTo={this.state.price_to}
                                                onAfterChange={this.slider}
                                            />

                                            {this.state.filters.map(
                                                (filter, index) => {
                                                    return (
                                                        <FilterParameters
                                                            key={index}
                                                            range={
                                                                filter.parameter_type_id === 'range'
                                                            }
                                                            radio={filter.parameter_type_id === 1} //radio
                                                            checkbox={
                                                                filter.parameter_type_id === 2
                                                            } //multiple
                                                            title={filter.external_name}
                                                            items={filter.values}
                                                            data={filter}
                                                            setFilters={this.setFilters}
                                                        />
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}

                                <ProductsGridItems
                                    filter={this.state.filter}
                                    listingStaticSets={this.state.listingStaticSets}
                                    nextPageLoader={this.state.nextPageLoader}
                                    products={internalStore.productsForCategory}
                                    renderchips={this.renderChips}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </div>
        )
    }
}
export default observer(ProductsGrid)
