//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api
import { api } from '../../../utils/api'

//style
import './Header.scss'

//custom components
import NavigationTop from '../NavigationTop/NavigationTop'
import NavigationMain from '../NavigationMain/NavigationMain'
//material-ui

//other libraries

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            founded: {
                products: [],
                category: []
            },
            inputText: '',
            renderResultList: false,
            regEmail: '',
            regPass: '',
            regPassConfirm: '',
            isEmailFormat: true,
            isPassMinLenght: true,
            passIsEqual: true,
            loader: false,
            accountWasCreated: false,
            accountCreatedMsg: '',

            googleLogin: false,
            facebookLogin: false,
            loginWithForm: false,

            loadingShoppingCart: true,

            menuCategories: []
        }
    }

    componentDidMount() {
        this.getShoppingCartItems();
        // this.timer = setInterval(() => this.getShoppingCartItems(), 600000) // 10 minut
    }

    getShoppingCartItems = async () => {
        let load_shopping_cart = await api({
            scns_token: localStorage.getItem('scns_token'),
            url: 'shopping-cart',
            method: 'GET'
        })

        if (load_shopping_cart.success === true) {
            this.props.internal.setShoppingCartData(load_shopping_cart.data.data)
            this.setState({ loadingShoppingCart: false })
            localStorage.setItem('scns_token', load_shopping_cart.data.data.scns_token)
        }
    }

    // @rs
    // get the data for menu from API
    loadMenu = async () => {
        // let load_data = await api({
        //     method: 'GET',
        //     url: 'menu'
        // })
        // return await load_data

        // await this.props.internal.loadMenuItems();

        const productItem = this.props.internal.menuItems.find(
            item => item.frontend_menu_type.name === 'product'
        )

        if (productItem) {
            this.setState({
                menuCategories: productItem.category.children
            })
        }
    }

    // @rs
    // get item categories if API call was successful
    parseMenuCategories = () => {
        // // parse out menu items for the nav above header
        // const LOAD_MENU_RESPONSE = this.loadMenu()
        //
        // LOAD_MENU_RESPONSE.then(response => {
        //     if (!response.success && !response.data.error) {
        //         console.error('API Error Occured')
        //     } else {
        //         for (const dat of response.data.data) {
        //             if (dat.frontend_menu_type.name === 'product') {
        //                 this.setState({
        //                     menuCategories: dat.category.children
        //                 })
        //             }
        //         }
        //     }
        // })
        this.loadMenu()
    }

    componentWillMount() {
        // this.parseMenuCategories()
    }

    goToCategory = (e, alias) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.internal.routerData.history.push(`/${alias}`)
        // this.props.internal.routerData.url = '/';
        // this.props.internal.routerData.match.params = alias.split('/');
    }

    render() {
        let menuCategories = []
        if (this.props.internal.menuItems) {
            const productItem = this.props.internal.menuItems.find(
                item => item.frontend_menu_type.name === 'product'
            )

            if (productItem) {
                menuCategories = productItem.category.children
            }
        }

        return (
            <nav className='navbar-fixed'>
                <div className='container'>
                    {/* <!-- ==========  Top navigation ========== --> */}
                    <NavigationTop ref={ref => this.props.internal.setLoginWrapperRef(ref)} loadingShoppingCart={this.state.loadingShoppingCart} />

                    {/* <!-- ==========  Main navigation ========== --> */}
                    <NavigationMain />
                </div>
                {/* <!--/container--> */}

                {/* @rs added desktop menu for quick access */}
                {menuCategories.length ? (
                    <div className={'categories-menu-wrapper'}>
                        <ul className={'categories-menu'}>
                            {menuCategories.map((category, ii) => (
                                <li key={`category_${category.alias}`} className={'menu-item'}>
                                    <a
                                        href={`/${category.alias}`}
                                        onClick={e => this.goToCategory(e, category.alias)}
                                        className={
                                            category.children.length
                                                ? `navbar-dropdown-trigger`
                                                : undefined
                                        }
                                    >
                                        {category.name}
                                    </a>
                                    {category.children && category.children.length > 0 && (
                                        <div className={`navbar-dropdown`}>
                                            <div className='navbar-box'>
                                                <div className='box-2'>
                                                    <div className='box clearfix'>
                                                        <ul>
                                                            {category.children.map(child => (
                                                                <li
                                                                    key={`subCategory_${
                                                                        child.alias
                                                                    }`}
                                                                >
                                                                    <a
                                                                        href={`/${child.alias}`}
                                                                        onClick={e =>
                                                                            this.goToCategory(
                                                                                e,
                                                                                child.alias
                                                                            )
                                                                        }
                                                                    >
                                                                        {child.name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </nav>
        )
    }
}

export default inject('internal', 'translate')(observer(Header))
