//basic
import React, { Component } from 'react'
import { inject } from 'mobx-react'
import { toJS } from 'mobx'
import ReactGA from 'react-ga4'
import MetaTags from 'react-meta-tags'

//style
import './CompareProducts.scss'

//api
import { api } from '../../utils/api'

//custom components
import RatingStars from '../../components/basic/RatingStars/RatingStars'

//material-ui

//other libraries
import uuid from 'uuid'
import Icon from '@mdi/react'
import { mdiScaleBalance, mdiShopping } from '@mdi/js'

//images
import no_image from '../../assets/images/no_image.png'

//global functions
import { formatMoney } from '../../utils/fuctions'

class CompareProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: [],
            allDataAboutProducts: [],
            reloading: false,
            loadingBtn: false
        }

        // ReactGA.initialize('UA-130881408-1')

    }

    componentDidMount() {
        ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });

        // console.group('ProductsGrid')
        // console.log('componentDidMount props:', this.props)
        // console.groupEnd()
        const { internal, ...other } = this.props
        this.props.internal.setRouterData(other)

        let compareData = localStorage.getItem('compare')
        // console.log("compareData", compareData)
        if (compareData !== null && compareData !== '') {
            let compareArray = compareData.split(',')
            // console.log("compareArray", compareArray)
            this.numberOfProducts = compareArray.length
            // console.log("this.numberOfProducts", this.numberOfProducts)
            for (let i in compareArray) {
                this.loadData(compareArray[i])
            }
        } else {
            this.props.internal.setGlobalLoader(false)
            this.setState({ reloading: false, loading: false })
        }
    }

    numberOfProducts = 0

    loadData = async slug => {
        // console.log("slug", slug)
        let load_data = await api({
            method: 'GET',
            url: `resolver/${slug}`
        })
        if (load_data.success === true) {
            let tmpData = this.state.data
            tmpData.push(load_data.data.data.parameters)
            let allData = this.state.allDataAboutProducts
            allData.push(load_data.data.data)
            this.setState({ data: tmpData, allDataAboutProducts: allData })
            if (this.numberOfProducts - 1 === 0) {
                this.setState({ loading: false, reloading: false }) //reloading: false
                this.props.internal.setGlobalLoader(false)
            }
        }
        this.numberOfProducts--
    }

    removeDuplicates(originalArray, prop) {
        var newArray = []
        var lookupObject = {}

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i]
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i])
        }
        return newArray
    }

    hanleAddProductToShoppingCart = async item => {
        // this.setState({loadingBtn: true})

        let shoppingCartData = toJS(this.props.internal.shoppingCartData)

        if (shoppingCartData.items !== null && shoppingCartData.items.length > 0) {
            let items = shoppingCartData.items

            let index = items.findIndex(x => x.product_id === item.id)
            if (index > -1) {
                this.props.internal.setSnackbarErrorData(
                    this.props.translate.i18n.GLOBAL.ALERTS.PRODUCT_IS_IN_SHOPPING_CART,
                    true,
                    'openBasket'
                )
                return
            }
        }

        let update_shop_cart = await api({
            url: 'shopping-cart',
            method: 'PUT',
            scns_token: localStorage.getItem('scns_token'),
            params: {
                product: item.id,
                quantity: 1
            }
        })

        if (update_shop_cart.success === true) {
            this.props.internal.setShoppingCartData(update_shop_cart.data.data)
            this.props.internal.setSnackbarData(update_shop_cart.data.message, true, 'openBasket')
            this.props.internal.loginWrapperRef.rerender()
        }
        // this.setState({loadingBtn: false})
    }

    renderBodyTable2 = () => {
        let parameters = []
        let tmpParams = this.state.data
        parameters = [].concat.apply([], tmpParams)
        // console.log("parameters", parameters)

        let uniq = this.removeDuplicates(parameters, 'id')
        // console.log("uniq", uniq)

        let resultForRender = []

        let allDataAboutProducts = this.state.allDataAboutProducts
        let imageRow = []
        for (let i in allDataAboutProducts) {
            // console.log("allDataAboutProducts[i]", allDataAboutProducts[i])
            imageRow.push(
                <td key={uuid()} className={'compareAssetImage'}>
                    <img
                        src={
                            allDataAboutProducts[i].asset_image_thumb
                                ? allDataAboutProducts[i].asset_image_thumb
                                : no_image
                        }
                        alt='asset_image'
                        onClick={() => this.handleGoToProductDetail(allDataAboutProducts[i])}
                    />
                </td>
            )
        }

        resultForRender.push(
            <tr key={uuid()} className={'imageRow'}>
                <td>&nbsp;</td>
                {imageRow}
            </tr>
        )

        //riadok
        for (let param in uniq) {
            //stlpec
            let row = []
            row.push(<td key={uuid()}>{uniq[param].external_name}</td>)
            let paramIsUsed = false
            // row.push(<tr key={param}><td>{uniq[param].external_name}</td><td></td><td></td><td></td></tr>)
            for (let item in tmpParams) {
                for (let i in tmpParams[item]) {
                    if (tmpParams[item][i].external_name === uniq[param].external_name) {
                        // text
                        if (tmpParams[item][i].parameter_type_id === 3) {
                            row.push(<td key={uuid()}>{tmpParams[item][i].value}</td>)
                        }
                        // one
                        else if (tmpParams[item][i].parameter_type_id === 1) {
                            row.push(<td key={uuid()}>{tmpParams[item][i].value.name}</td>)
                        }
                        // multi
                        else if (tmpParams[item][i].parameter_type_id === 2) {
                            if (tmpParams[item][i].value && tmpParams[item][i].value.length > 0) {
                                let values = ''
                                let resultString = ''
                                let isUsed = false
                                for (let val in tmpParams[item][i].value) {
                                    isUsed = true
                                    values += tmpParams[item][i].value[val].name + ', '
                                }

                                if (isUsed === true) {
                                    resultString = values.slice(0, -2)
                                }

                                row.push(<td key={uuid()}>{resultString}</td>)
                            }

                            // row.push(
                            //     <td key={uuid()}>{tmpParams[item][i].value.name }</td>
                            // )
                        }
                        paramIsUsed = true
                    }
                }

                // ak produkt nema parameter, alebo ak pordukt nema ziadne parametre
                if (!paramIsUsed || tmpParams[item].length === 0) {
                    row.push(<td key={uuid()}>-</td>)
                }

                paramIsUsed = false
            }
            resultForRender.push(<tr key={uuid()}>{row}</tr>)
        }

        // // cena s DPH
        // let priceRowWithVat = []
        // priceRowWithVat.push(
        //     <td key={uuid()}>{this.props.translate.i18n.PAGES.COMPARE.RETAIL_PRICE}</td>
        // )
        //
        // for (let i in allDataAboutProducts) {
        //     priceRowWithVat.push(
        //         <td key={uuid()}>{formatMoney(allDataAboutProducts[i].retail_price)}</td>
        //     )
        // }
        // resultForRender.push(<tr key={uuid()}>{priceRowWithVat}</tr>)

        // cena bez DPH
        let priceRowWithoutVat = []
        priceRowWithoutVat.push(
            <td key={uuid()}>{this.props.translate.i18n.PAGES.COMPARE.RETAIL_PRICE_WITH_VAT}</td>
        )

        for (let i in allDataAboutProducts) {
            priceRowWithoutVat.push(
                <td key={uuid()}>{formatMoney(allDataAboutProducts[i].use_discount_price ? allDataAboutProducts[i].discounted_price_with_vat : allDataAboutProducts[i].retail_price_with_vat)} {allDataAboutProducts[i].use_discount_price && allDataAboutProducts[i].retail_price_with_vat !== allDataAboutProducts[i].discounted_price_with_vat && (<s>{formatMoney(allDataAboutProducts[i].retail_price_with_vat)}</s>)}</td>
            )
        }
        resultForRender.push(<tr key={uuid()}>{priceRowWithoutVat}</tr>)

        // priemerne hodnotenie produktu
        let reviews = []
        reviews.push(
            <td key={uuid()}>{this.props.translate.i18n.PAGES.COMPARE.AVERAGE_REVIEWS}</td>
        )

        for (let i in allDataAboutProducts) {
            reviews.push(
                <td key={uuid()}>
                    <RatingStars num={allDataAboutProducts[i].average_score} average yellow />
                    <span className={'averageNumberCompare'}>
                        {(Math.round(allDataAboutProducts[i].average_score * 10) / 10).toFixed(1)}
                    </span>
                </td>
            )
        }
        resultForRender.push(<tr key={uuid()}>{reviews}</tr>)

        // pridat do kosika
        let addToShoppingCart = []
        addToShoppingCart.push(<td key={uuid()}>&nbsp;</td>)

        for (let i in allDataAboutProducts) {
            addToShoppingCart.push(
                <td key={uuid()}>
                    {/* <span className={'addToShopingCartFromCompare'} onClick={() => this.hanleAddProductToShoppingCart(allDataAboutProducts[i])}>{this.props.translate.i18n.PAGES.COMPARE.ADD_TO_SHOPPING_CART}</span> */}
                    <button
                        className='btn btn-border btnBlueBackground'
                        onClick={() => this.hanleAddProductToShoppingCart(allDataAboutProducts[i])}
                    >
                        <Icon path={mdiShopping} size={1} />
                        {this.props.translate.i18n.GLOBAL.BUTTONS.BUY}
                    </button>
                </td>
            )
        }
        resultForRender.push(<tr key={uuid()}>{addToShoppingCart}</tr>)

        return resultForRender
    }

    handleRemoveProductFromCompare = product => {
        // console.log("product", product)
        let compare = localStorage.getItem('compare')
        // console.log("compare", compare.includes(product.alias))
        let compareArr = compare.split(',')
        // console.log("compareArr", compareArr)
        // console.log("compareArr", compareArr.includes(product.alias))
        if (compareArr.includes(product.alias)) {
            let index = compareArr.findIndex(item => item === product.alias)
            // console.log("index", index)
            let el = compareArr.filter(item => item !== product.alias)
            // console.log("el", el)
            let newCompare = compareArr.slice(index, 1)
            // console.log("\nnewCompare", el.join(','))
            localStorage.setItem('compare', el.join(','))
            this.setState({ reloading: true, data: [], allDataAboutProducts: [] })
            this.componentDidMount()
            this.props.internal.loginWrapperRef.rerender()
        }
    }

    handleGoToProductDetail = product => {
        this.props.internal.setGlobalLoader(true)
        this.props.internal.routerData.history.push(`/${product.alias}`)
    }

    render() {
        const {footerData} = this.props.internal;

        return (
            <div className='compareProducts'>
                <MetaTags>
                    <title>{this.props.translate.i18n.PAGES.COMPARE.PAGE_TITLE} {footerData && footerData.shop && footerData.shop.additional_page_title}</title>
                </MetaTags>
                <section className='main-header'>
                    <header>
                        <div className='container'>
                            <h1 className='h2 title'>
                                {this.props.translate.i18n.PAGES.COMPARE.PAGE_TITLE}
                            </h1>
                        </div>
                    </header>
                </section>
                <section>
                    <div className='container'>
                        <div className='panel ' id='tables'>
                            {this.state.reloading ? (
                                <div className='lds-dual-ring reloadCompareProduct' />
                            ) : this.state.loading === false && this.state.data.length > 0 ? (
                                <div className='panel-body'>
                                    <table className='table table-hover'>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{ position: 'relative', border: 'none' }}
                                                >
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    {
                                                        this.props.translate.i18n.PAGES.COMPARE
                                                            .PRODUCT_NAME
                                                    }
                                                </td>
                                                {this.state.allDataAboutProducts.map(
                                                    (product, index) => {
                                                        return (
                                                            <td
                                                                key={product.id}
                                                                className={'productTitle'}
                                                            >
                                                                <span
                                                                    onClick={() =>
                                                                        this.handleGoToProductDetail(
                                                                            product
                                                                        )
                                                                    }
                                                                >
                                                                    {product.name}
                                                                </span>
                                                                <span
                                                                    className={
                                                                        'removeProductFromCompare'
                                                                    }
                                                                    onClick={() =>
                                                                        this.handleRemoveProductFromCompare(
                                                                            product
                                                                        )
                                                                    }
                                                                >
                                                                    &times;
                                                                </span>
                                                            </td>
                                                        )
                                                    }
                                                )}
                                            </tr>
                                            {/* {this.renderBodyTable()} */}
                                            {this.renderBodyTable2()}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div>
                                    <div className={'compageIcon'}>
                                        <Icon path={mdiScaleBalance} size={4} />
                                    </div>
                                    <p style={{ textAlign: 'center' }}>
                                        {
                                            this.props.translate.i18n.PAGES.COMPARE
                                                .EMPTY_LIST_FOR_COMAPE
                                        }
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default inject('internal', 'translate')(CompareProducts)
