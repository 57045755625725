//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//api

//custom components

//material-ui

//other libraries
import ReactGA from 'react-ga4'

class Tab extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handelChangeTab = (val) => {
        this.props.internal.routerData.match.params.step = val
        let url = `/moj-ucet/${val}`
        this.props.internal.routerData.history.push(url)
        ReactGA.send({ hitType: 'pageview', page: url });
    }

    render () {
        return (
            <li role="presentation" className={`${this.props.data.active ? ' active' : ''} `} style={{whiteSpace: 'nowrap'}}>
                {this.props.updateUrl ?
                    <a href={`#${this.props.data.id}`} aria-controls={this.props.data.id} role="tab" data-toggle="tab" onClick={() => this.handelChangeTab(this.props.data.id)} className="bgGrayLight">
                        {/* <i className="icon icon-user"></i> */}
                        <span>{this.props.data.title}</span>
                    </a>
                    :
                    <a href={`#${this.props.data.id}`} aria-controls={this.props.data.id} role="tab" data-toggle="tab" className="bgGrayLight">
                        {/* <i className="icon icon-user"></i> */}
                        <span>{this.props.data.title}</span>
                    </a>
                }
            </li>
        )
    }
}

export default inject('internal', 'translate')(observer(Tab));
