import React from 'react'

import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import './index.scss'

import { Button, FormHelperText, FormLabel } from '@material-ui/core'
import { mdiUpload } from '@mdi/js'
import Icon from '@mdi/react'


export default class AuthInput extends React.Component {
  render() {
    if ('file' === this.props.type) {
      return <FormControl className={this.props.className} error={!! this.props.hasError}>
        <FormLabel htmlFor={this.props.id || this.props.name}>
          <Button component={"span"} className={`${this.props.className}`}><Icon size={1} path={mdiUpload} /> {this.props.label} {this.props.value ? this.props.value.name : "N/A"}</Button>
        </FormLabel>
        <Input
          type="file"
          name={this.props.name}
          id={this.props.id || this.props.name}
          style={{ display: 'none' }}
          inputProps={{
            accept: this.props.accept
          }}
          onChange={this.props.onChange}
        />
        <FormHelperText
          component="div"
          className={this.props.className}
          id={`help-${this.props.id || this.props.name}`}>{this.props.helperText || this.props.errorMessage}</FormHelperText>
      </FormControl>
    }
    
    return (
      <FormControl className={'custom-input ' + this.props.className} error={!! this.props.hasError}>
        <InputLabel
          htmlFor={this.props.name}
          className={''}
        >
          {this.props.label} {this.props.required ? '*' : ''}
        </InputLabel>
        <Input
          name={this.props.name}
          value={this.props.value}
          type={this.props.type}
          id={this.props.id || this.props.name}
          className={`input-input ${this.props.className}`}
          onChange={this.props.onChange}
          required={this.props.required ? this.props.required : false}
          autoFocus={!! this.props.autoFocus}
          multiline={!! this.props.multiline}
          disabled={!! this.props.disabled}
          inputProps={{
            style: {
              textAlign: `${this.props.align ? this.props.align : 'left'}`,
            },
          }}
        />
        <FormHelperText
          component="div"
          className={this.props.className}
          id={`help-${this.props.id || this.props.name}`}>{this.props.helperText || this.props.errorMessage}</FormHelperText>
      </FormControl>
    )
  }
}
