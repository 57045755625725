//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './WatchDog.scss';

//api
import { api } from '../../../utils/api';

//custom components
import ProductQuickViewDialog from '../../../components/complex/ProductQuickViewDialog/ProductQuickViewDialog';
import ProductItem from '../../../components/complex/ProductItem/ProductItem';

//material-ui

//other libraries

class WatchDog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        Promise.all([
            this.loadWatchDog(),
        ]).then(() => {
            this.setState({loading: false,})
        })
    }

    loadWatchDog = async() => {
        this.setState({loading: true})
        let load_data = await api({
            url: "account/watch-lists",
            method: 'GET'
        })

        if(load_data.success === true) {
            // console.log("watch dog data", load_data)
            this.props.internal.setTabWatchDog(load_data.data.data)
        }
    }

    handleDeleteWatchDog = async(item) => {
        // console.log("item", item)
        this.setState({loading: true})

        let delete_data = await api({
            method: "DELETE",
            url: `account/watch-lists/${item.id}`
        })

        if(delete_data.success === true) {
            this.props.internal.setSnackbarData(delete_data.data.message, true)
            this.props.internal.setTabWatchDog(delete_data.data.data)
        }

        this.setState({loading: false})
    }

    render() {
        let tabWatchDog = toJS(this.props.internal.tabWatchDog)
        // console.log("tabWatchDog", tabWatchDog)


        return (
                this.state.loading
                    ?
                    <div className=' watchDogTable'>
                        <div className='lds-dual-ring setDogWatch' />
                    </div>
                    :
                    tabWatchDog.length > 0
                        ?
                        <section className="products watchDogTable">
                            <div className="container">
                                {tabWatchDog.map((item, index) => {
                                    // console.log("item", item)
                                    const { product, ...other } = item
                                    return (
                                        <div key={index} >
                                            <ProductItem item={product} {...item} favorite parentClasses={'col-lg-3 col-md-4 col-sm-6 col-xs-12'} watchDog topProduct={item.product !== null} fixedSet={item.static_set !== null} deleteWatchDog={this.handleDeleteWatchDog}/>
                                            {/*<ProductQuickViewDialog {...product} {...item}  routerData={this.props.routerData} topProduct={item.product !== null} fixedSet={item.static_set !== null}/>*/}
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                        :
                        // <div className='watchDogTable'>
                            <p className='text-center' style={{marginBottom: 0}}>{this.props.translate.i18n.PAGES.MY_ACCOUNT.NOT_HAVE_SET_WATCH_DOG}</p>
                        // </div>
        )

        // return (
        //     this.state.loading ?
        //     <div className=' watchDogTable'>
        //         <div className='lds-dual-ring setDogWatch' />
        //     </div>
        //     :
        //     tabWatchDog.length > 0 ?
        //     <div className=' watchDogTable'>
        //         <div className='row header'>
        //             <div className='col-xs-5'>
        //                 <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.PRODUCT_NAME}</span>
        //             </div>
        //             <div className='col-xs-7'>
        //                 <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.WATCH_DOG}</span>
        //             </div>
        //         </div>
        //         {tabWatchDog.map((item, index) => {
        //             return (
        //                 <div key={index} className='row watchDogOnProduct'>
        //                     <div className='col-xs-5'>
        //                         <span>{item.product.name}</span>
        //                     </div>
        //                     <div className='col-xs-6'>
        //                         <div className='row'>

        //                         {item.watch_availability === true ?
        //                             <div className='col-xs-12'>
        //                                 <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.WATCH_AVAILABILITY}</span>
        //                             </div>
        //                             :
        //                             null
        //                         }
        //                         {item.watch_price === true ?
        //                             <div className='col-xs-12'>
        //                                 <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.WATCH_PRICE} {item.target_price}</span>
        //                             </div>
        //                             :
        //                             null
        //                         }
        //                         </div>
        //                     </div>
        //                     <div className='col-xs-1 ' onClick={() => this.handleDeleteWatchDog(item)}>
        //                         <span className='deleteWatchDog'>&times;</span>
        //                     </div>
        //                 </div>
        //             )
        //         })}

        //     </div>
        //     :
        //     <div className='watchDogTable'>
        //         <p className='text-center'>{this.props.translate.i18n.PAGES.MY_ACCOUNT.NOT_HAVE_SET_WATCH_DOG}</p>
        //     </div>
        // )
    }
}

export default inject('internal', 'translate')(WatchDog)
