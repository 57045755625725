//basic
import React, { Component } from 'react'

//api

//custom components
import InfoBox from '../../basic/InfoBox/InfoBox'

//material-ui

//other libraries
import ReactHtmlParser from 'react-html-parser'

//style
import './ProductDetailTab.scss'

import no_image from '../../../assets/images/no_image.png'

export default class ProductDetailTab extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <div className='col-md-6 col-sm-12'>
                    <img
                        src={
                            this.props.data.asset_image !== null
                                ? this.props.data.asset_image
                                : no_image
                        }
                        alt={this.props.data.name}
                        className={'fixedSetProductImage'}
                    />
                    {/* <Gallery
                        data={this.props.data}
                        product
                    /> */}
                </div>
                <div className='col-md-6 col-sm-12'>
                    {this.props.data.parameters &&
                        this.props.data.parameters.map((param, index) => {
                            let renderValue = ''
                            if (param.parameter_type_id === 3) {
                                renderValue = param.value
                            } else if (param.parameter_type_id === 1) {
                              if (false === param.hasOwnProperty('value')) {
                                renderValue = 'N/A'
                              } else {
                                renderValue = param.value.name
                              }
                            } else {
                                let multiValues = param.value
                                for (let i in multiValues) {
                                    renderValue += multiValues[i].name
                                    if (Number(i) < multiValues.length - 1) {
                                        renderValue += ', '
                                    }
                                }
                            }
                            return (
                                // <div>
                                //     <strong>{param.external_name}</strong>
                                //     <span>{renderValue}</span>
                                // </div>
                                <InfoBox
                                    key={index}
                                    title={param.external_name}
                                    value={renderValue}
                                />
                            )
                        })}
                    {this.props.data.description && (
                        <div className={'fixedSetDetailContent'}>
                            {ReactHtmlParser(this.props.data.description)}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
