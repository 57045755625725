//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//style
import './Gallery.scss'

//api

//custom components

//material-ui

//other libraries

//no image
import no_image_ from '../../../assets/images/no_image.png'


class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  
  render() {
    return (
      <div className="owl-product-gallery open-popup-gallery">
        {this.props.product === true && this.props.data !== null && this.props.data.asset_image_small && this.props.data.asset_image_small !== null ?
          <a href={this.props.data.asset_image}><img src={this.props.data.asset_image_small} alt={this.props.data.name} height="500"/></a>
          :
          // this.props.product === true   pred zmenou zobrazovalo no_image tam kde boli spristupnene obrazky
          this.props.product !== true && (this.props.data === null || this.props.data.asset_image_small === null) ?
            <img src={no_image_} alt="no_image" height="500"/>
            :
            null
        }
        
        {this.props.product === true && this.props.data !== null && this.props.data.gallery ?
          this.props.product === true && this.props.data.gallery && this.props.data.gallery.map(image => {
            return <a key={image.id} href={image.asset_image}><img data-src={image.asset_image_small} className={'lazyOwl'} alt={this.props.data.name} height="500"/></a>
          })
          :
          // <img src={no_image_} alt="" height="500" />
          null
        }
        
        {/* {this.props.product === true && this.props.data !== null && this.props.data.asset_image === null && this.props.data.gallery === null || this.props.data.gallery.length < 1 ?

                    <div className={'emptyGallery'}>
                        <span>{this.props.translate.i18n.GLOBAL.ALERTS.EMPTY_GALLERY}</span>
                    </div>
                    :
                    null
                } */}
        
        
        {this.props.fixedSet === true && this.props.data && this.props.data !== null ?
          <img src={this.props.data} alt="" height="500"/>
          :
          // this.props.product === false   pred zmenou zobrazovalo 2x no_image
          this.props.fixedSet === false ?
            <div className={'emptyGallery'}>
              {/* <span>{this.props.translate.i18n.GLOBAL.ALERTS.EMPTY_GALLERY}</span> */}
              <img src={no_image_} alt="" height="500"/>
            </div>
            :
            null
        }
      </div>
    )
  }
}

export default inject('internal', 'translate')(observer(Gallery))
