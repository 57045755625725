//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

//api

//style
import './CartBlockFooter.scss'
import giftImage from '../../../assets/images/gift.png'

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions'
import { api } from '../../../utils/api'

class CartBlockFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      giftChecked: props.selectedGift,
      couponCode: '',
      loading: false
    }
  }

  timeout = null
  handleChooseGift = (event) => {
    this.setState({ giftChecked: event.currentTarget.value })

    clearTimeout(this.timeout)
    // this.timeout =
      this.updateGift(event.currentTarget.value)

  }

  updateGift = async (id) => {
    let update_cart = await api({
      method: 'PATCH',
      url: 'shopping-cart',
      scns_token: localStorage.getItem('scns_token'),
      authorization: this.props.internal.user.acces_token,

      params: {
        action: 'attributes',
        gift_id: id,
      },
    })

    if (update_cart.success === true) {
      this.props.internal.setSnackbarData(update_cart.data.message, true)
    } else {
      this.props.internal.setSnackbarErrorData(update_cart.data.message)
    }
  }

  applyCoupon = async () => {
    this.setState({loading: true})
    let update_cart = await api({
      method: 'PUT',
      url: 'shopping-cart/coupon',
      scns_token: localStorage.getItem('scns_token'),
      authorization: this.props.internal.user.acces_token,
      redirectOn401: false,
      params: {
        code: this.state.couponCode,
      },
    })
    this.setState({loading: false})

    if (update_cart.success === true) {
      this.props.internal.setSnackbarData(update_cart.data.message, true)
      this.props.internal.setShoppingCartData(update_cart.data.data)
    } else {
      this.props.internal.setSnackbarErrorData(update_cart.data.message)
    }
  }
  removeCouponFromShoppingCart = () => {
    this.setState({couponCode: null}, () => this.applyCoupon());
  }

  render() {
    const { shoppingCartData, user } = this.props.internal
    let discount_price = 0
    for (let i in shoppingCartData.items) {
      if (shoppingCartData.items[i].discount_percent && shoppingCartData.items[i].discount_percent !== null && shoppingCartData.items[i].discount_percent !== undefined) {
        let total_retail_price_with_vat = shoppingCartData.items[i].product.retail_price_with_vat * shoppingCartData.items[i].quantity // retail_price * pocet produktov
        discount_price += total_retail_price_with_vat - shoppingCartData.items[i].total_price_with_vat
      }
    }

    return (
      <div>
        <div className={'clearfix'}>
          {this.props.gifts
            ?
            <div className={'cart-block cart-block-footer clearfix giftsBox'}>
              {/* <div>
                                <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.GIFTS}</strong>
                            </div> */}
              <div>
                <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.GIFTS}</strong>
                {shoppingCartData && shoppingCartData.gifts && shoppingCartData.gifts.map((gift, index) => {
                  return (
                    <span className='checkbox giftsRadioInput' key={index}>
                                            <input
                                              type='radio'
                                              value={gift.id}
                                              id={`freeGift-${gift.id}`}
                                              name={'gift'}
                                              onChange={this.handleChooseGift}
                                              defaultChecked={
                                                this.state.giftChecked === gift.id
                                              }
                                            />
                                            <label htmlFor={`freeGift-${gift.id}`}>
                                                <img src={gift.photo || giftImage} alt={gift.description} className={'giftImage'}/>
                                                <span>{gift.name}</span>
                                            </label>
                                        </span>

                  )
                })}
                {/* <select className={'classic'} onChange={this.handleChooseGift}>
                                    {shoppingCartData && shoppingCartData.gifts && shoppingCartData.gifts.map((gift, index) => {
                                        return (
                                            <option key={index} value={gift.id} className={'giftOption'} >
                                                {gift.name}
                                            </option>
                                        )
                                    })}
                                </select> */}
              </div>
            </div>
            :
            null
          }
        </div>
        <div className='clearfix'>
          {/* <div className='cart-block cart-block-footer clearfix'>
                        <div>
                            <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.DISCOUNT}</strong>
                        </div>
                        <div>
                            <span>{formatMoney(159)}</span>
                        </div>
                    </div> */}
          {this.props.shipping ? (
            <div className='cart-block cart-block-footer clearfix'>
              <div>
                <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.SHIPPING}</strong>
              </div>
              <div className={'isDiscountApplied'}>
                {shoppingCartData.hasOwnProperty('delivery_type_price_with_vat') && shoppingCartData.delivery_type_price_with_vat !== null ?
                  <div><span>{formatMoney(shoppingCartData.delivery_type_price_with_vat)}</span></div>
                  :
                  null
                }
                {shoppingCartData.hasOwnProperty('delivery_type_discount_message') ?
                  <div><small>{shoppingCartData.delivery_type_discount_message}</small></div>
                  :
                  null
                }
              </div>
            </div>
          ) : null}
          {this.props.payment_price ? (
            <div className='cart-block cart-block-footer clearfix'>
              <div>
                <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.PAYMENT_PRICE}</strong>
              </div>
              <div className={'isDiscountApplied'}>
                {shoppingCartData.hasOwnProperty('payment_type_price_with_vat') && shoppingCartData.payment_type_price_with_vat !== null ?
                  <div><span>{formatMoney(shoppingCartData.payment_type_price_with_vat)}</span></div>
                  // <div><span>{shoppingCartData.payment_type_price_with_vat.toString()}</span></div>
                  :
                  null
                }
                {shoppingCartData.hasOwnProperty('payment_type_discount_message') ?
                  <div><small>{shoppingCartData.payment_type_discount_message}</small></div>
                  :
                  null
                }
              </div>
            </div>
          ) : null}

          {discount_price > 0 ?
            <div className='cart-block cart-block-footer clearfix discountPrice'>
              <div>
                <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.DISCOUNT}</strong>
              </div>
              <div>
                <span>{formatMoney(discount_price)}</span>
              </div>
            </div>
            :
            null
          }


          {this.props.cart && (<div className='cart-block cart-block-footer clearfix'>
            <div>
              <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.COUPON}</strong>
            </div>
            <div className={'text-right'}>
              {shoppingCartData.coupon ? (
                <React.Fragment>
                <span className={'coupon-info'}>{shoppingCartData.coupon.code} - {shoppingCartData.coupon.discount_type === 'percent' ? formatMoney(shoppingCartData.coupon.discount_value / 100, 'percent') : formatMoney(shoppingCartData.coupon.discount_value)}</span>
                  {this.state.loading ? (
                    <i className={`fa ${this.state.loading ? 'fa-spinner fa-spin' : 'fa-check'}`}></i>
                  ) : (
                    <span className='icon icon-cross icon-delete' onClick={() => this.removeCouponFromShoppingCart()}/>
                  )}
                </React.Fragment>
              ) : (
                <div className={'coupon-form-group'}>
                  <input
                    type="text"
                    name="code"
                    value={this.state.couponCode}
                    className={'form-control coupon-code'}
                    autoComplete={'no'}
                    placeholder={this.props.translate.i18n.COMPONENTS.CART_ITEMS.INPUT_COUPON_CODE}
                    onChange={event => this.setState({couponCode: event.target.value})}
                  />
                  <button type={'button'} onClick={() => this.applyCoupon()} className={'btnBlueBackground'}>
                    <i className={`fa ${this.state.loading ? 'fa-spinner fa-spin' : 'fa-check'}`}></i>
                  </button>
                </div>
              )}
            </div>
          </div>)}

          <div className='cart-block cart-block-footer clearfix totalPriceWithoutVat'>
            <div>
              <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.TOTAL_WITHOUT_VAT}</strong>
            </div>
            <div>
              {shoppingCartData.hasOwnProperty('products_total') && shoppingCartData.products_total !== null ?
                <span>{formatMoney(shoppingCartData.products_total)}</span>
                :
                null
              }
            </div>
          </div>
        </div>

        <div className='clearfix'>
          <div className='cart-block cart-block-footer cart-block-footer-price clearfix'>
            <div>
              <strong>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.TOTAL_WITH_VAT}</strong>
            </div>
            <div>
              <div className='h4 title'>{formatMoney(this.props.total_with_vat)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('internal', 'translate')(observer(CartBlockFooter))
