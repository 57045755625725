//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './Receipt.scss'

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions'
import $ from 'jquery'
import scrollTo from 'jquery.scrollto'

class Receipt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadDetailOrder: false,
      agreedToTAC: false,
      agreedToNewsletter: false,
      sendingOrder: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      $(window).scrollTo('.step-wrapper', 500)
    }, 250)
  }

  handleBack = step => {
    this.props.changeStep(step)
  }

  getTermsAndConditionsAlias = () => {

    const { topMenu, bottomMenu } = toJS(this.props.internal)
    let alias = null
    for (let i in topMenu) {
      if (topMenu[i].frontend_menu_type_id === 5) {
        alias = topMenu[i].alias
      }
    }
    for (let i in bottomMenu) {
      if (bottomMenu[i].frontend_menu_type_id === 5) {
        alias = bottomMenu[i].alias
      }
    }

    return alias
  }

  handleOrder = () => {
    if (false === this.state.agreedToTAC) {
      this.props.internal.setSnackbarErrorData('Je potrebný súhlas s obchodnými podmienkami!')
      return
    }

    const { shoppingCartData } = toJS(this.props.internal)
    if (shoppingCartData.payment_type.is_gopay === true) {
      this.goPayMethod()
    } else if (shoppingCartData.payment_type.is_homecredit === true) {

    } else if (shoppingCartData.payment_type.is_gpwebpay === true) {
      this.GPWebPayMethod()
    } else {
      this.otherPayMethod()
    }
  }

  otherPayMethod = async () => {
    this.setState({ loadDetailOrder: true, sendingOrder: true })
    let order_data = await api({
      method: 'POST',
      url: 'shopping-cart/to-order',
      scns_token: localStorage.getItem('scns_token'),
      params: {
        newsletter: this.state.agreedToNewsletter
      }
    })

    // console.log("other pay method", order_data)
    if (order_data.success === true) {
      this.props.internal.setGlobalLoader(true)
      this.setState({ loadDetailOrder: false })
      // console.log("this.props", toJS(this.props))

      let slug = 'order-success'
      localStorage.setItem('order_detail', JSON.stringify(order_data.data.data))
      this.props.routerData.history.push(`/${slug}`)
    }
    this.setState({ loadDetailOrder: false, sendingOrder: false })
  }

  GPWebPayMethod = async () => {
    this.setState({ loadDetailOrder: true, sendingOrder: true })
    let order_data = await api({
      method: 'POST',
      url: 'shopping-cart/to-order',
      scns_token: localStorage.getItem('scns_token'),
      params: {
        newsletter: this.state.agreedToNewsletter
      }
    })

    // console.log("other pay method", order_data)
    if (order_data.success === true) {
      window.location.href=order_data.data.data.gp_wp_url;
    }
    this.setState({ loadDetailOrder: false, sendingOrder: false })
  }

  goPayMethod = async () => {

    this.setState({ loadDetailOrder: true, sendingOrder: true })
    let order = await api({
      method: 'POST',
      // postman: true,
      scns_token: localStorage.getItem('scns_token'),
      //shopping-cart/to-order/:order_hash
      url: `shopping-cart/to-order`,
      params: {
        newsletter: this.state.agreedToNewsletter
      }
    })

    // console.log("order", order)
    this.setState({ loadDetailOrder: false, sendingOrder: false })

    window._gopay.checkout({ gatewayUrl: order.data.data.gw_url, inline: false }, (checkoutResult) => {
      // console.log("tu", checkoutResult)
      if (checkoutResult.id) {

      }
    })
  }

  render() {
    const { shoppingCartData } = toJS(this.props.internal)
    return (
      // this.state.loadDetailOrder ? null :
      <div className='checkout'>
        <div className={'container'}>

          <div className='clearfix'>
          </div>

          <div className='cart-wrapper'>
            <div className='note-block' style={{ position: 'relative' }}>
              {this.state.loadDetailOrder ? <div className='lds-dual-ring loader'/> : null}

              <div className='row' style={{ opacity: this.state.loadDetailOrder ? 0 : 1 }}>
                <div className='col-md-6'>
                  <div className='white-block'>
                    <div className='h4'>
                      {
                        this.props.translate.i18n.COMPONENTS.PAYMENTS
                          .BILLING_INFO
                      }
                    </div>

                    <hr/>

                    <div className='row'>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .FIRST_NAME
                            }
                          </strong>{' '}
                          <br/>
                          <span>
                                                        {shoppingCartData.billing_first_name}{' '}
                                                    </span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .LAST_NAME
                            }
                          </strong>{' '}
                          <br/>
                          <span>
                                                        {shoppingCartData.billing_last_name}
                                                    </span>
                        </div>
                      </div>

                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.EMAIL
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_email}</span>
                        </div>
                      </div>

                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.PHONE
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_phone}</span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.STREET_NAME
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_street}</span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.ZIP
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_zip}</span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.CITY
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_city}</span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .COUNTRY
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.billing_country}</span>
                        </div>
                      </div>
                      {/* @rs business info */}
                      {
                        shoppingCartData.billing_company_name ?
                          <div className='col-xs-6'>
                            <div className='form-group'>
                              <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.COMPANY_NAME}</strong>
                              <br/>
                              <span>{shoppingCartData.billing_company_name}</span>
                            </div>
                          </div>
                          : null
                      }
                      {
                        shoppingCartData.billing_id_no ?
                          <div className='col-xs-6'>
                            <div className='form-group'>
                              <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.ICO}</strong>
                              <br/>
                              <span>{shoppingCartData.billing_id_no}</span>
                            </div>
                          </div>
                          : null
                      }
                      {
                        shoppingCartData.billing_id_tax ?
                          <div className='col-xs-6'>
                            <div className='form-group'>
                              <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DIC}</strong>
                              <br/>
                              <span>{shoppingCartData.billing_id_tax}</span>
                            </div>
                          </div>
                          : null
                      }
                      {
                        shoppingCartData.billing_id_vat ?
                          <div className='col-xs-6'>
                            <div className='form-group'>
                              <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DPH}</strong>
                              <br/>
                              <span>{shoppingCartData.billing_id_vat}</span>
                            </div>
                          </div>
                          : null
                      }
                    </div>

                    <div className='h4'>
                      {
                        this.props.translate.i18n.COMPONENTS.PAYMENTS
                          .SHIPPING_INFO
                      }
                    </div>

                    <hr/>

                    <div className='row'>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .FIRST_NAME
                            }
                          </strong>{' '}
                          <br/>
                          <span>
                                                        {shoppingCartData.delivery_first_name}{' '}
                                                    </span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .LAST_NAME
                            }
                          </strong>{' '}
                          <br/>
                          <span>
                                                        {shoppingCartData.delivery_last_name}
                                                    </span>
                        </div>
                      </div>

                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.EMAIL
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_email}</span>
                        </div>
                      </div>

                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.PHONE
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_phone}</span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.STREET_NAME
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_street}</span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.ZIP
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_zip}</span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.COMPONENTS
                                .DELIVERY_FORM.CITY
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_city}</span>
                        </div>
                      </div>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>
                            {
                              this.props.translate.i18n.GLOBAL.CONTACT
                                .COUNTRY
                            }
                          </strong>
                          <br/>
                          <span>{shoppingCartData.delivery_country}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='white-block'>
                    <div className='h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.LIST_ITEMS}</div>

                    <hr/>

                    {shoppingCartData.items.map((item, index) => {
                      return (
                        <div key={index} className='row mb-5'>
                          <div className='col-md-9'>
                            {item.product !== null ? (
                              <strong>{item.product.name}</strong>
                            ) : null}
                            {item.static_set !== null ? (
                              <strong>{item.static_set.name}</strong>
                            ) : null}
                            {item.product && item.product.gifts && item.product.gifts.length > 0 && (
                              <div className={'small'}>{this.props.translate.i18n.PAGES.SHOPPING_CART.GIFTS}: {item.product.gifts.map(gift => gift.name).join(', ')}</div>
                            )}
                            {item.static_set && item.static_set.gifts && item.static_set.gifts.length > 0 && (
                              <div className={'small'}>{this.props.translate.i18n.PAGES.SHOPPING_CART.GIFTS}: {item.static_set.gifts.map(gift => gift.name).join(', ')}</div>
                            )}
                          </div>
                          <div className='col-md-3 text-right'>
                            <strong>{formatMoney(item.total_price_with_vat)}</strong>
                            {item.discount > 0 ? (
                              <div>
                                <span className='coupon-discount'>{formatMoney(item.one_price_with_vat * item.quantity)}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )
                    })}

                    <div className='h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.PAYMENT_DETAILS}</div>

                    <hr/>

                    <div className='row'>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.PAYMENT_TYPE}</strong> <br/>
                          <span>
                                                        {shoppingCartData.payment_type ? shoppingCartData.payment_type.name : 'N/A'}
                                                    </span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.PAYMENT_TYPE_PRCIE}</strong>
                          <br/>
                          <span>
                                                        {
                                                          formatMoney(shoppingCartData.payment_type_price)
                                                        }
                                                    </span>
                        </div>
                      </div>

                    </div>

                    <div className='h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.DELIVERY_DETAILS}</div>

                    <hr/>

                    <div className='row'>
                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.DELIVERY_TYPE}</strong> <br/>
                          <span>{shoppingCartData.delivery_type.name}</span>
                        </div>
                      </div>

                      <div className='col-md-6 col-xs-6'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.DELIVERY_TYPE_PRICE}</strong>
                          <br/>
                          <span>{formatMoney(shoppingCartData.delivery_type_price)}</span>
                        </div>
                      </div>
                    </div>

                    {shoppingCartData.gift && (<>
                      <div className='h4'>{this.props.translate.i18n.COMPONENTS.CART_ITEMS.GIFTS}</div>

                      <hr/>

                      <div className='row'>
                        <div className='col-xs-12'>
                          <div className='form-group'>
                            <strong>{shoppingCartData.gift.name}</strong>
                          </div>
                        </div>
                      </div>
                    </>)}

                    {shoppingCartData.note && (
                      <>
                        <div className='h4'>{this.props.translate.i18n.GLOBAL.CONTACT.NOTE}</div>

                        <hr/>

                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='form-group note'>
                              <span>{shoppingCartData.note}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className='h4'>{this.props.translate.i18n.COMPONENTS.SUMMARY.SUMMARY}</div>

                    <hr/>

                    <div className='row sumaryPriceBlock'>
                      {shoppingCartData.coupon ? (
                        <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.COUPON}</strong>
                          <span className={'pull-right'}>
                            {shoppingCartData.coupon.code} - {shoppingCartData.coupon.discount_type === 'percent' ? formatMoney(shoppingCartData.coupon.discount_value / 100, 'percent') : formatMoney(shoppingCartData.coupon.discount_value)}
                          </span>
                        </div>
                      </div>
                      ) : null}
                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.TOTAL_PRICE}</strong>
                          <span className={'pull-right'}>{formatMoney(shoppingCartData.total)}</span>
                        </div>
                      </div>
                      <div className='col-xs-12'>
                        <div className='form-group totalPriceWithVat'>
                          <strong>{this.props.translate.i18n.COMPONENTS.SUMMARY.TOTAL_PRICE_WITH_VAT}</strong>
                          <span className={'pull-right'}>{formatMoney(shoppingCartData.total_with_vat)}</span>
                        </div>
                      </div>
                      <div className='col-xs-12'>
                        <div className='form-group'>
                          <span className={'checkbox'} style={{ color: this.state.agreedToTAC ? '' : '#e71d36' }}>
                            <input type={'checkbox'} id={'agree-to-tac'} checked={this.state.agreedToTAC} onChange={event => this.setState({ agreedToTAC: event.currentTarget.checked })}/>
                            <label htmlFor={'agree-to-tac'}>
                          {this.props.translate.i18n.COMPONENTS.SUMMARY.TERMS_AND_CONDITIONS_PREFIX}
                              <a href={`/${this.getTermsAndConditionsAlias()}`} target={'_blank'}>{this.props.translate.i18n.COMPONENTS.SUMMARY.TERMS_AND_CONDITIONS}</a>
                            </label>
                          </span>
                        </div>
                        <div className='form-group'>
                          <span className={'checkbox'}>
                            <input type={'checkbox'} id={'agree-to-newsletter'} checked={this.state.agreedToNewsletter} onChange={event => this.setState({ agreedToNewsletter: event.currentTarget.checked })}/>
                            <label htmlFor={'agree-to-newsletter'}>{this.props.translate.i18n.COMPONENTS.SUMMARY.SIGN_UP_NEWSLETTER}</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix">
            <div className="row">
              <div className="col-xs-12 text-right text-sm leading-sm">
                <p>Obratom spracujeme Vašu objednávku a o niekoľko minút dostanete správu s potvrdením Vášho nákupu na Vami uloženú e-mailovú adresu.</p>
                <p className="text-red text-bold">Ak túto správu nenájdete vo svojej schránke prijatej pošty, skontrolujte aj priečinok s nevyžiadanou poštou (Spam).</p>
                <p>Ďakujeme za objednávku a dúfame, že Vás budeme môcť čoskoro znova privítať!</p>
              </div>
            </div>
          </div>
          <div className='clearfix'>
            <div className='row' style={{ opacity: this.state.loadDetailOrder ? 0 : 1 }}>
              <div className='col-xs-6'>
                <a onClick={() => this.handleBack(2)} className='btn btnWhiteBackground'>
                  <span className='icon icon-chevron-left'/> {this.props.translate.i18n.COMPONENTS.PAYMENTS.BACK_TO_PAYMENTS}
                </a>
              </div>
              <div className='col-xs-6 text-right'>
                {/* onClick={() => this.handleNext(3)} */}
                <a onClick={this.handleOrder} className={`btn btnBlueBackground${this.state.agreedToTAC ? '' : ' disabled'}${this.state.sendingOrder ? ' disabled' : ''}`}>
                  {this.props.translate.i18n.COMPONENTS.PAYMENTS.ORDER_WITH_REQUIRED_PAYMENT}
                  <span className='icon icon-chevron-right'/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('internal', 'translate')(observer(Receipt))
