//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './MyOrders.scss';

//api
import { api } from '../../../utils/api';

//custom components
import DetailOrderDialog from '../../../components/global/DetailOrderDialog/DetailOrderDialog';

//material-ui

//other libraries
import moment from 'moment'
import { formatMoney } from '../../../utils/fuctions'


class MyOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showDetailOrderDialog: false,
        }
    }

    componentDidMount() {
        Promise.all([
            this.loadMyOrders(),
        ]).then(() => {
            this.setState({loading: false,})
        })
    }

    loadMyOrders = async() => {
        this.setState({loading: true})
        let load_data = await api({
            url: "account/orders",
            method: 'GET'
        })

        if(load_data.success === true) {
            this.props.internal.setTabMyOrders(load_data.data.data)
            
        }

        this.setState({loading: false})
    }

    handleShowDetailOrder = (data) => {
        this.setState({showDetailOrderDialog: !this.state.showDetailOrderDialog}, () => {
        //     document.body.classList.add('modal-open')
        // let elm = document.createElement('div')
        // elm.classList.add('modal-backdrop','fade', 'in')
        // document.body.appendChild(elm)
        })

        this.props.internal.setOrderDetailId(data.id)

        let loc = this.props.internal.routerData.location.pathname
        this.props.internal.routerData.history.push(loc + '?id=' + data.id)
    }

    handleCloseDialogDetailOrder = () => {
        this.setState({showDetailOrderDialog: false})
    }
    

    render() {
        let tabMyOrders = toJS(this.props.internal.tabMyOrders)
        return (
            <div>
                <DetailOrderDialog open={this.state.showDetailOrderDialog} onClose={this.handleCloseDialogDetailOrder}/>
                {/* <DetailOrderDialog /> */}
           
            {this.state.loading ?
            <div className=' myOrders'>
                <div className='lds-dual-ring setDogWatch' />
            </div>
            :
            tabMyOrders.length > 0 ?
            <div className=' myOrders'>
                <div className='row header'>
                    <div className='col-xs-2 first-child'>
                        <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.VARIABLE_SYMBOL}</span>
                    </div>
                    <div className='col-xs-3'>
                        <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.CREATED_AT}</span>
                    </div>
                    <div className='col-xs-2'>
                        <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.ORDER_STATUS}</span>
                    </div>
                    <div className='col-xs-3'>
                        <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.LAST_UPDATE}</span>
                    </div>
                    <div className='col-xs-2 last-child'>
                        <span>{this.props.translate.i18n.PAGES.MY_ACCOUNT.TOTAL_WITH_VAT_WITH_DISCOUNT}</span>
                    </div>
                </div>
                {/* <hr/> */}
                {tabMyOrders.map((item, index) => {
                    return (
                        // onClick={() => this.handleShowDetailOrder(item)} data-id={item.id} data-toggle="modal" data-target="#detailOrderDialog"
                        <div key={index} className='row orderDetailRow' data-toggle="modal" data-target="#detailOrderDialog" onClick={() => this.handleShowDetailOrder(item)}>
                            <div className='col-xs-2'>
                                <span>{item.variable_symbol}</span>
                            </div>
                            <div className='col-xs-3'>
                                <span>{moment(item.created_at).format('HH:mm DD.MM.YYYY')}</span>
                            </div>
                            <div className='col-xs-2'>
                                <span>{item.customer_order_status.name}</span>
                            </div>
                            <div className='col-xs-3'>
                                <span>{moment(item.updated_at).format('HH:mm DD.MM.YYYY')}</span>
                            </div>
                            <div className='col-xs-2  text-right'>
                                <span>{formatMoney(item.total_with_vat_with_discount)}</span>
                            </div>
                        </div>
                    )
                })}

            </div>
            :
            // <div className='myOrders'>
                <p className='text-center' style={{marginBottom: 0}}>{this.props.translate.i18n.PAGES.MY_ACCOUNT.NOT_HAVE_ORDERS}</p>
            // </div>
            }
            </div>
        )
    }
}

export default inject('internal', 'translate')(MyOrders)
