//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './Payment.scss';

//api
import { api } from '../../../utils/api'

//custom components

//material-ui

//other libraries
import { formatMoney } from '../../../utils/fuctions';
import $ from 'jquery'
import scrollTo from 'jquery.scrollto'
import ReactGA from 'react-ga4'

class Payment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            payment_type_id: null,
            loadingPaymentType: false,
            savingPaymentType: false
        }
    }

    componentDidMount() {
        const { shoppingCartData } = toJS(this.props.internal)

        this.setState({
            payment_type_id: shoppingCartData.payment_type_id,
            loading: false,
        })

        setTimeout(() => {
            $(window).scrollTo('.step-wrapper', 500)
        }, 250);
    }

    handleBack = step => {
        this.props.changeStep(step)
    }

    handleNext = step => {

        const { shoppingCartData } = toJS(this.props.internal)
        // console.log("shoppingCartData", shoppingCartData)

        // if(shoppingCartData.payment_type !== null && shoppingCartData.payment_type_id !== null) {
        //     if(shoppingCartData.payment_type.is_gopay === true) {
        //         this.goPay()
        //     }
        //     else if(shoppingCartData.payment_type.is_homecredit === true) {

        //     }
        // }
        if(null !== shoppingCartData.payment_type && this.state.payment_type_id !== null) {
            this.props.changeStep(step)
            ReactGA.event('add_payment_info', {
                currency: 'EUR',
                value: this.props.internal.shoppingCartData.total,
                coupon: this.props.internal.shoppingCartData.coupon ? this.props.internal.shoppingCartData.coupon.code : null,
                payment_type: this.props.internal.shoppingCartData.payment_type.name,
                items: toJS(this.props.internal.shoppingCartData.items).map(item => ({
                    item_id: item.product ? item.product.code : item.static_set.name,
                    item_name: item.product ? item.product.name : item.static_set.name,
                    affiliation: 'Bekoshop.sk',
                    coupon: item.product && item.product.coupons && item.product.coupons.length > 0 ? item.product.coupons[0].code : null,
                    item_category: item.product ? item.product.category_path_alt[0] : null,
                    item_category2: item.product && item.product.category_path_alt[1] ? item.product.category_path_alt[1] : null,
                    item_category3: item.product && item.product.category_path_alt[2] ? item.product.category_path_alt[2] : null,
                    price: item.total_price,
                }))
            })
        }
        else {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.CHOOSE_PAYMENT_TYPE)
        }
    }

    goPay = async() => {
        this.setState({loadingPaymentType: true})
        let order = await api({
            method: 'POST',
            // postman: true,
            scns_token: localStorage.getItem('scns_token'),
            //shopping-cart/to-order/:order_hash
            url: `shopping-cart/to-order`
        })

        // console.log("order", order)

        window._gopay.checkout({gatewayUrl: order.data.data.gw_url, inline: true}, (checkoutResult) => {
            // console.log("tu", checkoutResult)
        })
    }

    timeout = null

    handleChangePaymentType = id => {
        this.setState({payment_type_id: id})
        clearTimeout(this.timeout)
        // this.timeout = setTimeout(() => {
            this.updateShoppingCart(id)
        // }, 500)
    }

    updateShoppingCart = async(id) => {
        this.setState({savingPaymentType: true})
        let update_data = await api ({
            url: 'shopping-cart',
            method: "PATCH",
            scns_token: localStorage.getItem('scns_token'),
            params: {
                action: "attributes",
                payment_type_id: Number(id)
            }
        })

        if(update_data.success === true) {
            // this.setState({loading: false})
            this.props.internal.setShoppingCartData(update_data.data.data);
            this.props.internal.setSnackbarData(update_data.data.message, true)
            this.setState({savingPaymentType: false})
        }
    }

    render() {
        const { shoppingCartData } = toJS(this.props.internal)
        const {payment_type} = shoppingCartData;
        return (
            this.state.loading ?
            null
            :
            <div className='checkout' id={'cart-payment-box'}>
                <div className={'container'}>

                <div className='clearfix'>
                        <div className='row upperControls'>
                            <div className='col-xs-6 text-right'>
                                <a href={'#step3'} onClick={() => payment_type && this.handleNext(3)} className={`btn btnBlueBackground${payment_type ? '' : ' disabled'}`}>
                                    {this.props.translate.i18n.COMPONENTS.PAYMENTS.SUMARY} <span className='icon icon-chevron-right' />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='cart-wrapper'>
                        <div className='note-block' style={{position: 'relative'}}>
                        {this.state.loadingPaymentType ? <div className='lds-dual-ring loader' /> : null}
                            <div className='row' style={{opacity: this.state.loadingPaymentType ? 0 : 1}}>
                            {/* <div className='row'> */}
                                <div className='col-sm-6'>
                                    <div className='white-block'>
                                        <div className='h4'>{this.props.translate.i18n.COMPONENTS.PAYMENTS.BILLING_INFO}</div>

                                        <hr />

                                        <div className='row'>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.FIRST_NAME}</strong> <br />
                                                    <span>{shoppingCartData.billing_first_name} </span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.LAST_NAME}</strong> <br />
                                                    <span>{shoppingCartData.billing_last_name}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-12'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.EMAIL}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_email}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-12'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.PHONE}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_phone}</span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.STREET_NAME}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_street}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.ZIP}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_zip}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.CITY}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_city}</span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.COUNTRY}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.billing_country}</span>
                                                </div>
                                            </div>
                                            {/* @rs business info */}
                                            {
                                                shoppingCartData.billing_company_name ?
                                                    <div className='col-xs-6'>
                                                        <div className='form-group'>
                                                            <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.COMPANY_NAME}</strong>
                                                            <br />
                                                            <span>{shoppingCartData.billing_company_name}</span>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                            {
                                                shoppingCartData.billing_id_no ?
                                                    <div className='col-xs-6'>
                                                        <div className='form-group'>
                                                            <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.ICO}</strong>
                                                            <br />
                                                            <span>{shoppingCartData.billing_id_no}</span>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                            {
                                                shoppingCartData.billing_id_tax ?
                                                    <div className='col-xs-6'>
                                                        <div className='form-group'>
                                                            <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DIC}</strong>
                                                            <br />
                                                            <span>{shoppingCartData.billing_id_tax}</span>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                            {
                                                shoppingCartData.billing_id_vat ?
                                                    <div className='col-xs-6'>
                                                        <div className='form-group'>
                                                            <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.DPH}</strong>
                                                            <br />
                                                            <span>{shoppingCartData.billing_id_vat}</span>
                                                        </div>
                                                    </div>
                                                : null
                                            }

                                        </div>


                                        <div className='h4'>{this.props.translate.i18n.COMPONENTS.PAYMENTS.SHIPPING_INFO}</div>

                                        <hr />

                                        <div className='row'>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.FIRST_NAME}</strong> <br />
                                                    <span>{shoppingCartData.delivery_first_name} </span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.LAST_NAME}</strong> <br />
                                                    <span>{shoppingCartData.delivery_last_name}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-12'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.EMAIL}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_email}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-12'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.PHONE}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_phone}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.STREET_NAME}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_street}</span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.ZIP}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_zip}</span>
                                                </div>
                                            </div>

                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.COMPONENTS.DELIVERY_FORM.CITY}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_city}</span>
                                                </div>
                                            </div>
                                            <div className='col-xs-6'>
                                                <div className='form-group'>
                                                    <strong>{this.props.translate.i18n.GLOBAL.CONTACT.COUNTRY}</strong>
                                                    <br />
                                                    <span>{shoppingCartData.delivery_country}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className='white-block'>
                                        <div className='h4'>{this.props.translate.i18n.COMPONENTS.PAYMENTS.CHOOSE_PAYMENT}</div>

                                        <hr />

                                        {   shoppingCartData &&
                                            shoppingCartData.available &&
                                            shoppingCartData.available &&
                                            shoppingCartData.available.payment_types &&
                                            shoppingCartData.available.payment_types.map((payment, index) => {
                                                return (
                                                    <span key={index} className='checkbox'>
                                                        <input
                                                            type='radio'
                                                            id={`paymentTypeOption-${payment.id}`}
                                                            name='paymentOption'
                                                            // checked={true}
                                                            checked={payment.id === this.state.payment_type_id ? true : false}
                                                            onChange={() => this.handleChangePaymentType(payment.id)}
                                                            />
                                                        <label htmlFor={`paymentTypeOption-${payment.id}`} style={{width: "100%"}}>
                                                            {payment.name}{' '}
                                                                <strong className={'pull-right'}>
                                                                    {shoppingCartData.products_total_with_vat > payment.free_from
                                                                        ?
                                                                        formatMoney(0)
                                                                        :
                                                                        formatMoney(payment.price)
                                                                    }
                                                                </strong>
                                                            {/* <strong>{payment.name} {formatMoney(payment.price)}</strong> <br /> */}
                                                            <p className={'paymentTypeDescription'}>
                                                                {payment.description}
                                                            </p>
                                                        </label>
                                                    </span>
                                                )

                                        })}

                                        {/* <span className='checkbox'>
                                            <input
                                                type='radio'
                                                id='paymentID1'
                                                name='paymentOption'
                                                // checked={true}
                                                defaultChecked={true}
                                                onChange={this.handleChangePaymentType}
                                                />
                                            <label htmlFor='paymentID1'>
                                                <strong>Pay via credit cart</strong> <br />
                                                <small>
                                                    (MasterCard, Maestro, Visa, Visa Electron, JCB
                                                    and American Express)
                                                </small>
                                            </label>
                                        </span> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='clearfix'>
                        <div className='row'>
                            <div className='col-xs-6'>
                                <a onClick={() => this.handleBack(1)} className='btn btnWhiteBackground'>
                                    <span className='icon icon-chevron-left' /> {this.props.translate.i18n.COMPONENTS.PAYMENTS.BACK_TO_DELIVERY}
                                </a>
                            </div>
                            <div className='col-xs-6 text-right'>
                                <a href={'#step3'} onClick={() => payment_type && this.handleNext(3)} className={`btn btnBlueBackground${payment_type ? '' : ' disabled'}`}>
                                    {this.props.translate.i18n.COMPONENTS.PAYMENTS.SUMARY} <span className='icon icon-chevron-right' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(Payment))
