// core
import React from 'react'
import { observer } from 'mobx-react'

// components
import ProductsGridHandleCustom from './ProductsGridHandleCustom'
import Slider from 'rc-slider'

// i18n
import t from '../../stories/i18n'

// libraries
import uuid from 'uuid'

const Range = Slider.Range

const ProductsGridPriceFilter = observer(props => {
    const { filter, maxPrice, priceFrom, priceTo, onAfterChange } = props

    const defaultValue = [priceFrom, priceTo]

    const renderTooltip = value => {
        return `${value}%`
    }

    const renderHandle = props => {
        const key = uuid()

        return <ProductsGridHandleCustom key={key} {...props} filter={filter} />
    }

    const getMarks = () => {
        return {
            0: {
                style: {},
                label: <span className={'minLabel'}>0&euro;</span>
            },
            [maxPrice]: {
                style: {},
                label: (
                    <span className={'maxLabel'}>
                        {maxPrice}
                        &euro;
                    </span>
                )
            }
        }
    }

    return (
        <div className='filter-box active'>
            <div className='title'>{t.i18n.COMPONENTS.FILTERS.PRICE}</div>

            <div className='filter-content'>
                <div className='price-filter' style={{ position: 'relative' }}>
                    <Range
                        className={'customPriceSlider'}
                        allowCross={false}
                        defaultValue={defaultValue}
                        min={0}
                        max={maxPrice}
                        onAfterChange={onAfterChange}
                        tipFormatter={renderTooltip}
                        handle={renderHandle}
                        marks={getMarks()}
                    />
                </div>
            </div>
        </div>
    )
})

export default ProductsGridPriceFilter
